import { Table, TableBody, TableCell, TableRow, TextField } from '@material-ui/core';
import React from 'react';
import { Container, Col, Row } from 'react-bootstrap';
import Loading from '../../helper/screens/Loading';
import Notification from '../../helper/screens/Notification';
import { validation } from '../../helper/validation';
import { CopyToClipboard } from 'react-copy-to-clipboard';

import TextInput from '../../helper/screens/TextInput';

import ProjectUnit from '../../communication/http/project'
import { Link } from 'react-router-dom';
import ProjectCache from '../../communication/locally/project';
import Switch from '../../helper/screens/Switch';
import PasswordUnit from '../../communication/http/passwords';
import CustomerUnit from '../../communication/http/customer';
import UserUnit from '../../communication/http/user';
import UserCache from '../../communication/locally/user';
import CustomerCache from '../../communication/locally/customer';
import Button from '../../helper/screens/Button';
import Popup from '../../helper/screens/Popup';
import { t } from '../../helper/multilingual/translate';


export default class Passwords extends React.Component {

    constructor() {

        super();

        this.state = {
            show_errors: false,
            passwords: [],
            currentState: 'active',
            loading: true,
            password: '',
            form: {
                search: {
                    valid: true,
                    value: '',
                    rules: {
                    }
                },
            }
        }

        this.passwordUnit = new PasswordUnit();

        this.projectUnit = new ProjectUnit();
        this.projectCache = new ProjectCache();

        this.customerUnit = new CustomerUnit();
        this.customerCache = new CustomerCache();

    }

    componentDidMount() {

        this.props.set_current_page('/passwords');

        this.passwordUnit.list().then(res => {

            if (res.success) {
                this.setState({
                    passwords: res.data,
                    loading: false,
                })
            }else{
                
                this.setState({
                    loading: false,
                });

                this.notification.open('error', res)

            }


        }).catch(err => {
            console.log(err)
        })



        if (this.projectCache.list() !== null) {
            this.setState({
                projects: this.projectCache.list()
            })
        }

        this.projectUnit.list().then(res => {
            console.log(res)

            this.projectCache.set(res.data);

            this.setState({
                projects: res.data
            })

        }).catch(err => {
            console.log(err)
        })


        if (this.customerCache.list() !== null) {
            this.setState({
                customers: this.projectCache.list()
            })
        }

        this.customerUnit.list().then(res => {
            console.log(res)

            this.customerCache.set(res.data);

            this.setState({
                customers: res.data
            })

        }).catch(err => {
            console.log(err)
        })


    }

    generatePassword = (len) => {
        var length = len;
        var string = "abcdefghijklmnopqrstuvwxyz"; //to upper 
        var numeric = '0123456789';
        var punctuation = '!@#$%^&*()_+~`|}{[]\:;?><,./-=';
        var password = "";
        var character = "";
        while (password.length < length) {
            let entity1 = Math.ceil(string.length * Math.random() * Math.random());
            let entity2 = Math.ceil(numeric.length * Math.random() * Math.random());
            let entity3 = Math.ceil(punctuation.length * Math.random() * Math.random());
            let hold = string.charAt(entity1);
            hold = (password.length % 2 == 0) ? (hold.toUpperCase()) : (hold);
            character += hold;
            character += numeric.charAt(entity2);
            character += punctuation.charAt(entity3);
            password = character;
        }
        password = password.split('').sort(function () { return 0.5 - Math.random() }).join('');
        this.setState({
            password: password.substr(0, length)
        })

    }

    changeValue = (key, value) => {

        let formCopy = this.state.form;
        if (typeof value === 'object' && value.target !== undefined) {
            value = value.target.value;
        }

        formCopy[key].value = value;
        formCopy[key].valid = validation(value, formCopy[key].rules);

        this.setState({ form: formCopy })

    }


    render() {
        return (
            <>
                <Container fluid>
                    <Row>
                        <Col md={12}>
                            <h1>{t('passwords')}</h1>
                        </Col>
                    </Row>
                    <Row>
                        <Col md={5}>
                            <TextInput
                                onChangeValue={(val) => this.changeValue('search', val)}
                                value={this.state.form.search.value}
                                placeholder={t('search')}
                            />

                        </Col>
                        <Col md={7} style={{ textAlign: 'right', paddingTop: 15 }}>
                            <Button
                                label={t('generate_password')}
                                onClick={() => {
                                    this.generatePassword(26);
                                    this.popupPassword.open();
                                }}
                            />

                        </Col>
                    </Row>
                    <Row>
                        <Col md={12}>
                            <Table>
                                <TableBody>
                                    {
                                        this.state.passwords.map((password, key) => {


                                            let project = '';
                                            if (this.projectCache.get(password.project_id) !== null) {
                                                project = this.projectCache.get(password.project_id).title;
                                            }

                                            let customer = '';
                                            if (this.customerCache.get(password.customer_id) !== null) {
                                                customer = this.customerCache.get(password.customer_id).company;
                                            }

                                            if (
                                                this.state.form.search.value !== ''
                                                && (
                                                    !password.site.toLowerCase().includes(this.state.form.search.value.toLowerCase()) &&
                                                    !password.title.toLowerCase().includes(this.state.form.search.value.toLowerCase()) &&
                                                    !customer.toLowerCase().includes(this.state.form.search.value.toLowerCase()) &&
                                                    !project.toLowerCase().includes(this.state.form.search.value.toLowerCase())
                                                )

                                            ) return;


                                            return <TableRow key={key}>
                                                <TableCell>{password.title !== '' ? password.title : password.site}</TableCell>
                                                <TableCell>{customer}</TableCell>
                                                <TableCell>{project}</TableCell>
                                                <TableCell style={{ width: 100 }}>
                                                    <Link className="link" to={'/passwords/' + password.id}>
                                                        {t('edit')}
                                                    </Link>
                                                </TableCell>
                                            </TableRow>

                                        })
                                    }
                                </TableBody>
                            </Table>
                        </Col>
                    </Row>
                </Container>
                <Link
                    className="button"
                    style={{ position: 'fixed', bottom: 20, right: 20 }}
                    to={'/passwords/new'}
                >
                    {t('new')}
                </Link>
                <Loading visible={this.state.loading} />
                <Popup ref={ref => this.popupPassword = ref}>
                    <div>{this.state.password}{'\n'}</div>
                    <CopyToClipboard
                        text={this.state.password}
                        style={{ marginTop: 30 }}
                        onCopy={() => {
                            this.popupPassword.close();
                            this.notification.open('success', 'Kopiert!');
                        }}
                    >
                        <Button
                            ref={ref => this.saveButton = ref}
                            label={'Kopieren'}
                        />
                    </CopyToClipboard>
                </Popup>
                <Notification ref={ref => this.notification = ref} text="Invalid email or password" type="error" />
            </>
        );
    }
}
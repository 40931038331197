/*
    Established connection to API
*/

import Communicator from ".";
import { getUserToken } from "../../helper/functions/auth";
import { formatDateForDbSimple } from "../../helper/misc";

export default class InvoiceUnit extends Communicator {

    /**
     * 
     * @param {String} endpoint
     */
    constructor() {

        super('');

    }

    list = async (email, password) => {

        return await this.post('list_invoices', {
            token: getUserToken(),
        });

    }

    getNewInvoiceNumber = async () => {

        return await this.post('invoice_get_new_number', {
            token: getUserToken(),
        });

    }

    pdf = async (invoiceId) => {

        return await this.post('invoice_get_pdf', {
            token: getUserToken(),
            invoice_id: invoiceId
        });

    }

    create = async (
        invoiceNumber,
        title,
        state,
        customerId,
        projectId,
        date,
        paymentGoalDays,
        paymentType,
        category,
        textBefore,
        textAfter,
        isReverseCharge,
        positions
    ) => {

        return await this.post('invoice_add', {
            token: getUserToken(),
            invoice_number: invoiceNumber,
            title: title,
            state: state,
            customer_id: customerId,
            project_id: projectId,
            date: formatDateForDbSimple(date),
            payment_goal_days: paymentGoalDays,
            payment_type: paymentType,
            category: category,
            text_before: textBefore,
            text_after: textAfter,
            reverse_charge: isReverseCharge ? 1 : 0,
            positions: positions
        });

    }

    update = async (
        invoiceId,
        invoiceNumber,
        title,
        state,
        customerId,
        projectId,
        date,
        paymentGoalDays,
        paymentType,
        category,
        textBefore,
        textAfter,
        isReverseCharge,
        positions
    ) => {

        return await this.post('invoice_update', {
            token: getUserToken(),
            invoice_id: invoiceId,
            invoice_number: invoiceNumber,
            title: title,
            state: state,
            customer_id: customerId,
            project_id: projectId,
            date: formatDateForDbSimple(date),
            payment_goal_days: paymentGoalDays,
            payment_type: paymentType,
            category: category,
            text_before: textBefore,
            text_after: textAfter,
            reverse_charge: isReverseCharge ? 1 : 0,
            positions: positions
        });

    }

}
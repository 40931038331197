import React from 'react'

export default class Sidebar extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            opened: props.opened || false
        }

        this.textarea = React.createRef();

    }


    componentDidMount() {

    }

    open = () => {

        this.setState({
            opened: true
        });

    }
    close = () => {

        this.setState({
            opened: false
        });

    }

    render() {

        return (
            <>
                <div
                    className={"sidebar_overlay" + (this.state.opened ? ' show' : '')}
                    onClick={
                        () => this.close()
                    }></div>
                <div
                    className={"sidebar_right" + (this.state.opened ? ' show' : '')}

                >
                    <div
                        className="remove_button"
                        style={{
                            top: 30,
                            left: 30
                        }}
                        onClick={
                            () => this.close()
                        }
                    ></div>
                    {this.props.children}
                </div>
            </>
        )

    }

}
import React from 'react';
import { t } from '../../helper/multilingual/translate';


export default class NotFound extends React.Component {

    render() {
        return (
            <h1>404 - Not Found</h1>
        )
    }
}
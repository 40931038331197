import { FormControlLabel, MenuItem, Select, Table, TableBody, TableCell, TableRow, TextField } from '@material-ui/core';
import React from 'react';
import { Container, Col, Row } from 'react-bootstrap';
import Loading from '../../../helper/screens/Loading';
import Notification from '../../../helper/screens/Notification';
import { validation } from '../../../helper/validation';

import TextInput from '../../../helper/screens/TextInput';

import userUnit from '../../../communication/http/user'
import { Link } from 'react-router-dom';
import { formatTimeFromDb, wrlog, clone, removeA } from '../../../helper/misc';
import Button from '../../../helper/screens/Button';
import PredictionInput from '../../../helper/screens/PredictionInput';
import UserCache from '../../../communication/locally/user';
import UserUnit from '../../../communication/http/user';

import CustomCheckbox from '../../../helper/screens/CustomCheckbox';
import { t } from '../../../helper/multilingual/translate';


export default class UserDetail extends React.Component {

    constructor() {

        super();

        this.state = {
            show_errors: false,
            user: {
                tasks: []
            },
            customers: [],
            selectedEmploymentKey: null,
            userId: null,
            buttonStatus: null,
            employments: [],
            selectedEmployment: {},
            permissions: [],
            form: {
                state: {
                    valid: true,
                    value: true,
                    rules: {
                    }
                },
                firstname: {
                    valid: true,
                    value: '',
                    rules: {
                    }
                },
                lastname: {
                    valid: true,
                    value: '',
                    rules: {
                    }
                },
                address: {
                    valid: true,
                    value: '',
                    rules: {
                    }
                },
                addressNr: {
                    valid: true,
                    value: '',
                    rules: {
                    }
                },
                zip: {
                    valid: true,
                    value: '',
                    rules: {
                    }
                },
                city: {
                    valid: true,
                    value: '',
                    rules: {
                    }
                },
                country: {
                    valid: true,
                    value: '',
                    rules: {
                    }
                },
                citizenship: {
                    valid: true,
                    value: '',
                    rules: {
                    }
                },
                svaNr: {
                    valid: true,
                    value: '',
                    rules: {
                    }
                },
                state: {
                    valid: true,
                    value: '',
                    rules: {
                    }
                },
                email: {
                    valid: true,
                    value: '',
                    rules: {
                    }
                },
                password: {
                    valid: true,
                    value: '',
                    rules: {
                    }
                },
                permissions: {
                    valid: true,
                    value: [],
                    rules: {
                    }
                },

            },
            currentParentState: 'active',
        }

        this.userUnit = new UserUnit();
        this.userCache = new UserCache();

    }

    componentDidMount() {
        if (this.props.location.state !== undefined && this.props.location.state.currentState !== undefined) {
            this.setState({
                currentParentState: this.props.location.state.currentState
            })
        }
        this.props.set_current_page('/users');

        this.userUnit.listPermissions().then(res => {

            this.setState({
                permissions: res.data
            })

        });



        this.setState({
            userId: this.props.match.params.id
        })

        if (this.props.match.params.id === 'new') {

            this.setState({
                loading: false
            });

        } else {

            let user = this.userCache.get(this.props.match.params.id)

            if (user !== null) {

                this.setUser(user);

            } else {

                this.setState({
                    loading: true
                })
                this.loadUsers();

            }

        }

    }

    loadUsers = () => {
        this.userUnit.list().then(res => {

            this.userCache.set(res.data);

            let user = this.userCache.get(this.props.match.params.id)
            this.setState({
                loading: false
            })
            this.setUser(user)

        }).catch(err => {
            console.log(err)
        })
    }

    setUser = (user) => {
        wrlog("user", user);
        let formCopy = this.state.form;

        formCopy.state.value = user.state === 'active';
        formCopy.firstname.value = user.firstname;
        formCopy.lastname.value = user.lastname;

        formCopy.address.value = user.address;
        formCopy.addressNr.value = user.address_nr;
        formCopy.zip.value = user.zip;
        formCopy.city.value = user.city;
        formCopy.country.value = user.country;
        formCopy.citizenship.value = user.citizenship;
        formCopy.svaNr.value = user.sva_number;

        formCopy.email.value = user.email;
        formCopy.password.value = '';

        formCopy.permissions.value = user.permissions || [];

        wrlog("permissions", user.permissions);

        this.setState({
            user: user,
            form: formCopy,
            employments: user.employments
        })
    }

    changeValue = (key, value) => {

        let formCopy = this.state.form;
        if (typeof value === 'object' && value.target !== undefined) {
            value = value.target.value;
        }

        formCopy[key].value = value;
        formCopy[key].valid = validation(value, formCopy[key].rules);

        this.setState({ form: formCopy })

    }

    changeEmploymentValue = (key, value) => {

        let employmentsCopy = this.state.selectedEmployment;

        employmentsCopy[key] = value;

        this.setState({
            selectedEmployment: employmentsCopy,
        });

    }

    save = () => {

        this.setState({
            loading: true
        })

        this.saveButton.statusLoading();

        if (this.state.userId === 'new') {

            this.userUnit.create(
                this.state.form.state.value ? 'active' : 'disabled',
                this.state.form.firstname.value,
                this.state.form.lastname.value,
                this.state.form.address.value,
                this.state.form.addressNr.value,
                this.state.form.zip.value,
                this.state.form.city.value,
                this.state.form.country.value,
                this.state.form.citizenship.value,
                this.state.form.svaNr.value,
                this.state.form.email.value,
                this.state.form.password.value,
                this.state.form.permissions.value,
                this.state.employments
            ).then(res => {



                if (res.success) {
                    this.loadUsers();

                    const newId = res.user_id;

                    this.setState({
                        loading: false,
                        userId: newId
                    })

                    this.saveButton.statusSuccess();
                } else {
                    this.saveButton.statusReset();

                }


            }).catch(err => {

                this.setState({
                    loading: false
                })

            })

        } else {

            this.userUnit.update(
                this.state.userId,
                this.state.form.state.value ? 'active' : 'disabled',
                this.state.form.firstname.value,
                this.state.form.lastname.value,
                this.state.form.address.value,
                this.state.form.addressNr.value,
                this.state.form.zip.value,
                this.state.form.city.value,
                this.state.form.country.value,
                this.state.form.citizenship.value,
                this.state.form.svaNr.value,
                this.state.form.email.value,
                this.state.form.password.value,
                this.state.form.permissions.value,
                this.state.employments
            ).then(res => {

                if (res.success) {

                    this.loadUsers();

                    this.setState({
                        loading: false
                    })

                    this.saveButton.statusSuccess();

                } else {
                    this.saveButton.statusReset();

                }

            }).catch(err => {

                this.setState({
                    loading: false
                })

            })

        }

    }

    render_task_sidebar = () => {

        if (this.state.selectedEmploymentKey !== null) {

            let employment = this.state.selectedEmployment;

            wrlog(employment);

            return <Col md={6} className="task_sidebar" >
                <TextInput
                    onChangeValue={(val) => this.changeEmploymentValue('start_employment', val)}
                    value={employment.start_employment}
                    placeholder={t('from')}
                    type={'datetime'}
                />
                <TextInput
                    onChangeValue={(val) => this.changeEmploymentValue('end_employment', val)}
                    value={employment.end_employment}
                    placeholder={t('to')}
                    type={'datetime'}
                />
                <TextInput
                    onChangeValue={(val) => this.changeEmploymentValue('gross_wage', val)}
                    value={employment.gross_wage}
                    placeholder={t('gross_wage_monthly')}
                />
                <TextInput
                    onChangeValue={(val) => this.changeEmploymentValue('weekly_days', val)}
                    value={employment.weekly_days}
                    placeholder={t('days_per_week')}
                />
                <TextInput
                    onChangeValue={(val) => this.changeEmploymentValue('weekly_hours', val)}
                    value={employment.weekly_hours}
                    placeholder={t('hours_per_week')}
                />
                <TextInput
                    onChangeValue={(val) => this.changeEmploymentValue('vacation_days', val)}
                    value={employment.vacation_days}
                    placeholder={t('vacation_days')}
                />
                <TextInput
                    onChangeValue={(val) => this.changeEmploymentValue('overtime_18', val)}
                    value={employment.overtime_18}
                    placeholder={t('overtime_18')}
                />
                <TextInput
                    onChangeValue={(val) => this.changeEmploymentValue('overtime_saturday', val)}
                    value={employment.overtime_saturday}
                    placeholder={t('overtime_saturday')}
                />
                <TextInput
                    onChangeValue={(val) => this.changeEmploymentValue('overtime_weekend_holiday', val)}
                    value={employment.overtime_weekend_holiday}
                    placeholder={t('overtime_sunday_holiday')}
                />
                <Select
                    onChange={(val) => this.changeEmploymentValue('type', val.target.value)}
                    value={employment.overtime_type}
                    label={t('type_of_compensation')}
                >
                    <MenuItem value="time">{t('time_compensation')}</MenuItem>
                    <MenuItem value="payout">{t('payout')}</MenuItem>
                </Select>
                <div
                    className="button"
                    style={{ position: 'fixed', bottom: 20, right: 20 }}
                    onClick={
                        () => {

                            let selectedEmploymentCopy = clone(this.state.selectedEmployment)
                            let selectedEmploymentKeyCopy = clone(this.state.selectedEmploymentKey)
                            let employmentsCopy = this.state.employments;
                            employmentsCopy[selectedEmploymentKeyCopy] = selectedEmploymentCopy;

                            this.setState({
                                employments: employmentsCopy,
                                selectedEmployment: {},
                                selectedEmploymentKey: null,
                            })

                        }
                    }
                >{t('apply')}</div>

            </Col>

        }

    }

    render() {
        return (
            <>
                <Container fluid>
                    <Row>
                        <Col md={12}>
                            <Link className="go_back_button"
                                to={{
                                    pathname: '/users',
                                    state: {
                                        currentState: this.state.currentParentState
                                    }
                                }}
                            >{t('back')}</Link>
                        </Col>
                    </Row>
                    <Row>
                        <Col md={12}>
                            <h1>{(this.state.form.firstname.value === '' && this.state.userId === 'new' ? t('new_teammember') : this.state.form.firstname.value) + (this.state.userId === 'new' ? ' ' + t('create') : ' ' + t('edit'))}</h1>
                        </Col>
                    </Row>
                    <Row>
                        <Col md={12}>
                            <FormControlLabel
                                value={this.state.form.state.value}
                                control={<CustomCheckbox style={{ fontWeight: 200 }} checked={this.state.form.state.value} />}
                                label={t('active')}
                                labelPlacement="end"
                                onChange={(event) => this.changeValue('state', !this.state.form.state.value)}
                                style={{marginBottom: 10}}
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col md={6}>
                            <TextInput
                                onChangeValue={(val) => this.changeValue('firstname', val)}
                                value={this.state.form.firstname.value}
                                placeholder={t('firstname')}
                            />
                        </Col>
                        <Col md={6}>
                            <TextInput
                                onChangeValue={(val) => this.changeValue('lastname', val)}
                                value={this.state.form.lastname.value}
                                placeholder={t('lastname')}
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col md={6}>
                            <TextInput
                                onChangeValue={(val) => this.changeValue('address', val)}
                                value={this.state.form.address.value}
                                placeholder={t('address')}
                            />
                        </Col>
                        <Col md={6}>
                            <TextInput
                                onChangeValue={(val) => this.changeValue('addressNr', val)}
                                value={this.state.form.addressNr.value}
                                placeholder={t('address_number')}
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col md={6}>
                            <TextInput
                                onChangeValue={(val) => this.changeValue('zip', val)}
                                value={this.state.form.zip.value}
                                placeholder={t('zip')}
                            />
                        </Col>
                        <Col md={6}>
                            <TextInput
                                onChangeValue={(val) => this.changeValue('city', val)}
                                value={this.state.form.city.value}
                                placeholder={t('city')}
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col md={6}>
                            <TextInput
                                onChangeValue={(val) => this.changeValue('country', val)}
                                value={this.state.form.country.value}
                                placeholder={t('country')}
                            />
                        </Col>
                        <Col md={6}>
                            <TextInput
                                onChangeValue={(val) => this.changeValue('citizenship', val)}
                                value={this.state.form.citizenship.value}
                                placeholder={t('citizenship')}
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col md={6}>
                            <TextInput
                                onChangeValue={(val) => this.changeValue('svaNr', val)}
                                value={this.state.form.svaNr.value}
                                placeholder={t('social_security_number')}
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col md={6}>
                            <TextInput
                                onChangeValue={(val) => this.changeValue('email', val)}
                                value={this.state.form.email.value}
                                placeholder={t('email')}
                            />
                        </Col>
                        <Col md={6}>
                            <TextInput
                                onChangeValue={(val) => this.changeValue('password', val)}
                                value={this.state.form.password.value}
                                placeholder={t('password')}
                            />
                        </Col>
                    </Row>

                    <Row>
                        <Col>
                            {
                                this.state.permissions.map((permission, key) => {
                                    
                                    return <><FormControlLabel
                                        value={permission}
                                        control={<CustomCheckbox style={{ fontWeight: 200 }} checked={this.state.form.permissions.value.includes(permission)} />}
                                        label={permission}
                                        labelPlacement="end"
                                        onChange={(event) => {

                                            wrlog(this.state.form.permissions.value);

                                            if (this.state.form.permissions.value.includes(permission)) {
                                                let array = removeA(this.state.form.permissions.value, permission);
                                                this.changeValue('permissions', array);
                                            } else {
                                                let array = this.state.form.permissions.value;

                                                array.push(permission);
                                                this.changeValue('permissions', array);
                                            }

                                            wrlog(this.state.form.permissions);

                                        }}
                                        style={{marginBottom: 10}}
                                    /><br/></>
                                })
                            }


                        </Col>
                    </Row>
                    <Row>
                        <Col md={6}>
                            <div style={{ marginBottom: 50 }}>
                                <strong>{t('contracts_employments')}</strong>
                                <div className="add_button"
                                    onClick={() => {

                                        let employments = this.state.employments;

                                        employments.push(
                                            {
                                                end_employment: null,
                                                gross_wage: "",
                                                overtime_18: "",
                                                overtime_saturday: "",
                                                overtime_type: "payout",
                                                overtime_weekend_holiday: "",
                                                start_employment: new Date(),
                                                state: "active",
                                                weekly_days: 5,
                                                weekly_hours: "38.50",
                                                vacation_days: 0,
                                            }
                                        )

                                        this.setState({
                                            employments: employments,
                                        })


                                    }}
                                ></div>
                            </div>
                            <Table>
                                <TableBody>
                                    {
                                        this.state.employments !== undefined ? this.state.employments.map((employment, employmentkey) => {

                                            let start = employment.start_employment;

                                            if (typeof start === 'String') {

                                                start = start + 'T00:00:00Z';

                                            }
                                            start = formatTimeFromDb(start).date;

                                            return <TableRow key={employmentkey}>
                                                <TableCell>{start}</TableCell>
                                                <TableCell style={{ width: 100 }}>
                                                    <div className="small_button"
                                                        onClick={() => this.setState({
                                                            selectedEmployment: clone(employment),
                                                            selectedEmploymentKey: employmentkey,
                                                        })}>
                                                        {t('edit')}
                                                    </div>
                                                </TableCell>
                                            </TableRow>

                                        })
                                            : null
                                    }
                                </TableBody>
                            </Table>
                        </Col>
                    </Row>
                </Container>
                {this.render_task_sidebar()}
                <Button
                    ref={ref => this.saveButton = ref}
                    onClick={this.save}
                    label={t('save')}
                    fixed
                />
                <Loading visible={this.state.loading} />
            </>
        );
    }
}
/*
    Established connection to API
*/

import Communicator from ".";
import { getUserToken } from "../../helper/functions/auth";
import { formatDateForDbSimple, formatDatetimeForDbSimple } from "../../helper/misc";

export default class ResourcePlannerUnit extends Communicator {

    /**
     * 
     * @param {String} endpoint
     */
    constructor() {

        super('');

    }

    list = async () => {

        return await this.post('list_resourceplanner', {
            token: getUserToken(),
        });

    }

    create = async (user_id, project_id, date_from, date_to, note, uid) => {

        return await this.post('resourceplanner_create', {
            token: getUserToken(),
            user_id: user_id,
            project_id: project_id,
            date_from: date_from,
            date_to: date_to,
            uid: uid,
            note: note
        });

    }

    update = async (user_id, project_id, date_from, date_to, note, uid) => {

        return await this.post('resourceplanner_update', {
            token: getUserToken(),
            user_id: user_id,
            project_id: project_id,
            date_from: date_from,
            date_to: date_to,
            uid: uid,
            note: note
        });

    }
    
    delete = async (uid) => {

        return await this.post('resourceplanner_remove', {
            token: getUserToken(),
            uid: uid,
        });

    }

}
/*
    Established connection to API
*/

import Communicator from ".";
import { getUserToken } from "../../helper/functions/auth";
import { formatDateForDbSimple, formatTimeToUnix, getCurrentUnix } from "../../helper/misc";

export default class UserUnit extends Communicator {

    /**
     * 
     * @param {String} endpoint
     */
    constructor() {

        super('');

    }

    login = async (email, password) => {

        return await this.post('user_login', {
            email: email,
            password: password,
        });

    }

    create = async (state, firstname, lastname, address, addressNr, zip, city, country, citizenship, svaNr, email, password, permissions, employments) => {

        return await this.post('user_add', {
            token: getUserToken(),
            state: state,
            firstname: firstname,
            lastname: lastname,
            address: address,
            address_nr: addressNr,
            zip: zip,
            city: city,
            country: country,
            citizenship: citizenship,
            sva_number: svaNr,
            email: email,
            password: password,
            permissions: permissions,
            employments: employments
        });

    }

    update = async (userId, state, firstname, lastname, address, addressNr, zip, city, country, citizenship, svaNr, email, password, permissions, employments) => {

        employments.forEach(employment => {

            employment.state = 'active';

            if (formatTimeToUnix(employment.end_employment + 'T:00:00:00Z') < getCurrentUnix()) {
                employment.state = 'disabled';
            }

            if (employment.start_employment !== null && employment.start_employment !== '') {
                employment.start_employment = formatDateForDbSimple(employment.start_employment);
            }else{
                employment.start_employment = null;
            }
            if (employment.end_employment !== null && employment.end_employment !== '') {
                employment.end_employment = formatDateForDbSimple(employment.end_employment);
            }else{
                employment.end_employment = null;
            }

        });

        return await this.post('user_update', {
            token: getUserToken(),
            user_id: userId,
            state: state,
            firstname: firstname,
            lastname: lastname,
            address: address,
            address_nr: addressNr,
            zip: zip,
            city: city,
            country: country,
            citizenship: citizenship,
            sva_number: svaNr,
            email: email,
            password: password,
            permissions: permissions,
            employments: employments
        });

    }

    list = async () => {

        return await this.post('list_users', {
            token: getUserToken()
        });

    }

    listActive = async () => {

        return await this.post('list_users', {
            token: getUserToken(),
            filter: ['state = "aasdctive"']
        });

    }

    listPermissions = async () => {

        return await this.post('list_all_permissions', {
            token: getUserToken()
        });

    }

    getTime = async () => {

        return await this.post('user_get_time_detail', {
            token: getUserToken()
        });

    }

}
import { wrlog } from "../misc";

/**
 * Check if user is logged in
 */
export const isLoggedIn = () => {

    return getUserToken() !== null

}

/**
 * Get the user's token
 */
export const getUserToken = () => sessionStorage.getItem('token');




/**
 * Check if user has certain permission
 * @param {String} permission_needed 
 */
export const hasPermission = (permission_needed) => {

    let permissions = sessionStorage.getItem('permissions');

    if (permissions == null) return false;


    permissions = JSON.parse(permissions);
    
    wrlog("permissions", permissions);
    wrlog("permission_needed", permission_needed);
    wrlog(permissions.includes(permission_needed));
    if (!permissions.includes(permission_needed)) return false;

    return true;

}
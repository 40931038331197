import { FormControlLabel, Table, TableBody, TableCell, TableRow, TextField, Select, MenuItem } from '@material-ui/core';
import React from 'react';
import { Container, Col, Row } from 'react-bootstrap';
import Loading from '../../../helper/screens/Loading';
import Notification from '../../../helper/screens/Notification';
import { validation } from '../../../helper/validation';

import TextInput from '../../../helper/screens/TextInput';

import ProjectUnit from '../../../communication/http/project'
import ProjectCache from '../../../communication/locally/project';
import CustomerUnit from '../../../communication/http/customer';
import CustomerCache from '../../../communication/locally/customer';
import InvoiceCache from '../../../communication/locally/invoice';
import InvoiceUnit from '../../../communication/http/invoice';


import { Link } from 'react-router-dom';
import { clone, formatMoney, formatMoneyForDB, formatTimeFromDb, removeA, wrlog } from '../../../helper/misc';
import Button from '../../../helper/screens/Button';
import PredictionInput from '../../../helper/screens/PredictionInput';
import CustomCheckbox from '../../../helper/screens/CustomCheckbox';

import draft from '../../../assets/icons/draft.svg';
import sent from '../../../assets/icons/sent.svg';
import paid from '../../../assets/icons/paid.svg';
import archived from '../../../assets/icons/archive.svg';
import Sidebar from '../../../helper/screens/Sidebar';
import PdfPopup from '../../../helper/screens/PdfPopup';
import { t } from '../../../helper/multilingual/translate';


export default class Invoice extends React.Component {

    constructor() {

        super();

        this.state = {
            show_errors: false,
            invoice: {
                positions: []
            },
            customers: [],
            projects: [],
            positionCopy: {},
            invoiceId: null,
            pdfNumPages: 0,
            buttonStatus: null,
            selectedPosition: null,
            form: {
                customerId: {
                    valid: true,
                    value: null,
                    rules: {
                    }
                },
                projectId: {
                    valid: true,
                    value: null,
                    rules: {
                    }
                },
                invoiceNumber: {
                    valid: true,
                    value: '',
                    rules: {
                    }
                },
                title: {
                    valid: true,
                    value: '',
                    rules: {
                    }
                },
                sumNet: {
                    valid: true,
                    value: '',
                    rules: {
                    }
                },
                sumGross: {
                    valid: true,
                    value: '',
                    rules: {
                    }
                },
                state: {
                    valid: true,
                    value: 'open',
                    rules: {
                    }
                },
                date: {
                    valid: true,
                    value: '',
                    rules: {
                    }
                },
                paymentGoalDays: {
                    valid: true,
                    value: '',
                    rules: {
                    }
                },
                paymentType: {
                    valid: true,
                    value: '',
                    rules: {
                    }
                },
                category: {
                    valid: true,
                    value: '',
                    rules: {
                    }
                },
                textBefore: {
                    valid: true,
                    value: '',
                    rules: {
                    }
                },
                textAfter: {
                    valid: true,
                    value: '',
                    rules: {
                    }
                },
                isReverseCharge: {
                    valid: true,
                    value: false,
                    rules: {
                    }
                },
            }
        }

        this.projectUnit = new ProjectUnit();
        this.projectCache = new ProjectCache();

        this.customerUnit = new CustomerUnit();
        this.customerCache = new CustomerCache();

        this.invoiceUnit = new InvoiceUnit();
        this.invoiceCache = new InvoiceCache();

    }

    componentDidMount() {

        this.props.set_current_page('/invoices');

        this.setState({
            invoiceId: this.props.match.params.id
        })

        this.customerUnit.list().then(res => {

            let customers = [];

            res.data.map((customer) => {
                customers.push({
                    id: customer.id,
                    label: customer.company,
                })
            })

            this.setState({ customers: customers })

            if (this.projectCache.list() !== null) {
                this.setState({
                    projects: this.projectCache.list()
                })
            }

            this.projectUnit.list().then(res => {
                console.log(res)

                this.projectCache.set(res.data);

                let projects = [];

                res.data.map((project) => {
                    projects.push({
                        id: project.id,
                        label: project.title,
                    })
                })

                this.setState({ projects: projects })

                if (this.props.match.params.id === 'new') {

                    this.setState({
                        loading: false
                    })

                } else {

                    let invoice = this.invoiceCache.get(this.props.match.params.id)
                    wrlog('invoice 1', invoice)
                    if (invoice !== null) {

                        this.setInvoice(invoice)

                    }

                    this.loadInvoice();

                }

            }).catch(err => {
                console.log(err)
            })

        }).catch(err => {
            console.log(err)
        })

    }

    loadInvoice = () => {
        this.invoiceUnit.list().then(res => {

            this.invoiceCache.set(res.data);

            let invoice = this.invoiceCache.get(this.props.match.params.id)
            this.setState({
                loading: false
            })
            this.setInvoice(invoice)

        }).catch(err => {
            console.log(err)
        })
    }

    setInvoice = (invoice) => {

        wrlog("invoice", invoice);
        let formCopy = this.state.form;

        formCopy.invoiceNumber.value = invoice.invoice_number;
        formCopy.title.value = invoice.title;
        formCopy.sumNet.value = invoice.sum_net;
        formCopy.sumGross.value = invoice.sum_gross;
        formCopy.state.value = invoice.state;
        formCopy.customerId.value = parseInt(invoice.customer_id);
        formCopy.projectId.value = parseInt(invoice.project_id);
        formCopy.date.value = invoice.date;
        formCopy.paymentGoalDays.value = invoice.payment_goal_days;
        formCopy.paymentType.value = invoice.payment_type;
        formCopy.category.value = invoice.category;
        formCopy.textBefore.value = invoice.text_before;
        formCopy.textAfter.value = invoice.text_after;
        formCopy.isReverseCharge.value = parseInt(invoice.reverse_charge) === 1;

        this.setState({
            invoice: invoice,
            form: formCopy
        });

    }

    changeValue = (key, value) => {

        let formCopy = this.state.form;
        if (typeof value === 'object' && value.target !== undefined) {
            value = value.target.value;
        }

        formCopy[key].value = value;
        formCopy[key].valid = validation(value, formCopy[key].rules);

        this.setState({ form: formCopy })

    }

    save = () => {

        this.setState({
            loading: true
        })

        this.saveButton.statusLoading();

        if (this.state.invoiceId === 'new') {

            // customerId, projectId, site, password, user, comment

            this.invoiceUnit.create(
                this.state.form.invoiceNumber.value,
                this.state.form.title.value,
                this.state.form.state.value,
                this.state.form.customerId.value,
                this.state.form.projectId.value,
                this.state.form.date.value,
                this.state.form.paymentGoalDays.value,
                this.state.form.paymentType.value,
                this.state.form.category.value,
                this.state.form.textBefore.value,
                this.state.form.textAfter.value,
                this.state.form.isReverseCharge.value,
                this.state.invoice.positions
            ).then(res => {

                if (res.success) {
                    this.loadInvoice();
                    const newId = res.invoice_id;

                    this.setState({
                        loading: false,
                        invoiceId: newId
                    })

                    this.saveButton.statusSuccess();
                } else {
                    this.saveButton.statusReset();

                }

            }).catch(err => {

                this.setState({
                    loading: false
                })

            })

        } else {

            this.invoiceUnit.update(
                this.state.invoiceId,
                this.state.form.invoiceNumber.value,
                this.state.form.title.value,
                this.state.form.state.value,
                this.state.form.customerId.value,
                this.state.form.projectId.value,
                this.state.form.date.value,
                this.state.form.paymentGoalDays.value,
                this.state.form.paymentType.value,
                this.state.form.category.value,
                this.state.form.textBefore.value,
                this.state.form.textAfter.value,
                this.state.form.isReverseCharge.value,
                this.state.invoice.positions
            ).then(res => {

                if (res.success) {

                    this.setState({
                        loading: false
                    })
                    this.loadInvoice();

                    this.saveButton.statusSuccess();

                } else {
                    this.saveButton.statusReset();

                }

            }).catch(err => {

                this.setState({
                    loading: false
                })

            })

        }

    }

    changePositionValue = (key, value) => {

        let positionCopy = this.state.positionCopy;

        wrlog(this.state.selectedPosition);

        positionCopy[key] = value;

        this.setState({ positionCopy: positionCopy })

    }

    openSidebar = (key) => {

        if (key !== 'new') {
            this.setState({
                selectedPosition: key,
                positionCopy: clone(this.state.invoice.positions[key])
            })
        }
        this.sidebar.open()
    }

    closeSidebar = () => {

        const invoicesCopy = this.state.invoice;

        if (this.state.selectedPosition === 'new') {
            invoicesCopy.positions.push(
                this.state.positionCopy
            )
        } else {
            invoicesCopy.positions[this.state.selectedPosition] = this.state.positionCopy
        }

        this.setState({
            invoice: invoicesCopy,
            selectedPosition: null,
            positionCopy: {}
        })

        this.sidebar.close()

    }

    loadPdf = () => {

        this.invoiceUnit.pdf(this.state.invoiceId).then(res => {
            this.setState({
                pdfBase64: res.data
            })

            this.pdfPopup.open();

        })

    }


    renderPositionsSidebar = () => {

        let position = this.state.positionCopy

        return <>

            <TextInput
                onChangeValue={(val) => this.changePositionValue('value', val)}
                value={position.value}
                placeholder={t('title')}
            />
            <TextInput
                onChangeValue={(val) => this.changePositionValue('description', val)}
                value={position.description}
                placeholder={t('description')}
            />
            <TextInput
                onChangeValue={(val) => this.changePositionValue('price_net', val)}
                value={position.price_net}
                placeholder={t('net_price_per_unit')}
            />
            <TextInput
                onChangeValue={(val) => {
                    this.changePositionValue('tax', val)
                }}
                value={position.tax}
                placeholder={t('tax')}
            />
            <TextInput
                onChangeValue={(val) => this.changePositionValue('amount', val)}
                value={position.amount}
                placeholder={t('amount')}
            />
            <Select
                onChange={(val) => this.changePositionValue('unit', val.target.value)}
                value={position.unit || 'hour'}
                placeholder={t('unit')}
            >
                <MenuItem value="hour">{t('hour')}</MenuItem>
                <MenuItem value="flatrate">{t('flatrate')}</MenuItem>
                <MenuItem value="piece">{t('piece')}</MenuItem>
            </Select>
            <div
                className="button"
                style={{ position: 'absolute', bottom: 20, right: 20 }}
                onClick={
                    () => {

                        let invoiceCopy = this.state.invoice;

                        invoiceCopy.positions[this.state.selectedPosition] = clone(this.state.positionCopy);

                        this.setState({
                            invoice: invoiceCopy
                        })

                        this.closeSidebar();

                    }
                }
            >{t('apply')}</div>

        </>

    }


    render() {
        return (
            <>
                <Container fluid>
                    <Row>
                        <Col md={12}>
                            <Link className="go_back_button" to="/invoices">{t('back')}</Link>
                        </Col>
                    </Row>
                    <Row>
                        <Col md={12}>
                            <h1>{(this.state.invoiceId === 'new' ? t('create_invoice') : t('edit_invoice'))}</h1>
                        </Col>
                    </Row>
                    <Row>
                        <Col md={12} style={{ paddingTop: 10, paddingBottom: 50 }}>
                            {
                                this.state.invoiceId !== 'new' ?
                                    <div
                                        className="link"
                                        onClick={() => this.loadPdf()}
                                    >{t('generate_pdf')}</div>
                                    :
                                    null
                            }
                        </Col>
                    </Row>
                    <Row>
                        <Col md={12} style={{ paddingTop: 10, paddingBottom: 50 }}>
                            <div
                                onClick={() => {
                                    this.changeValue('state', 'open');
                                }}
                                className={"big_radio" + (this.state.form.state.value === 'open' ? ' checked' : '')}
                                style={{
                                    maxWidth: 100,
                                    backgroundImage: `url(${draft})`
                                }}
                            >
                                <strong>{t('draft')}</strong>
                            </div>
                            <div
                                onClick={() => {
                                    this.changeValue('state', 'sent');
                                }}
                                className={"big_radio" + (this.state.form.state.value === 'sent' ? ' checked' : '')}
                                style={{
                                    maxWidth: 100,
                                    backgroundImage: `url(${sent})`
                                }}
                            >
                                <strong>{t('sent')}</strong>
                            </div>
                            <div
                                onClick={() => {
                                    this.changeValue('state', 'paid');
                                }}
                                className={"big_radio" + (this.state.form.state.value === 'paid' ? ' checked' : '')}
                                style={{
                                    maxWidth: 100,
                                    backgroundImage: `url(${paid})`
                                }}
                            >
                                <strong>{t('paid')}</strong>
                            </div>
                            <div
                                onClick={() => {
                                    this.changeValue('state', 'archived');
                                }}
                                className={"big_radio" + (this.state.form.state.value === 'archived' ? ' checked' : '')}
                                style={{
                                    maxWidth: 100,
                                    backgroundImage: `url(${archived})`
                                }}
                            >
                                <strong>{t('archived')}</strong>
                            </div>
                        </Col>
                    </Row>

                    <Row>
                        <Col md={6}>
                            <PredictionInput
                                options={this.state.projects}
                                onChangeValue={(val) => {
                                    this.changeValue('projectId', val)

                                    let project = this.projectCache.get(val);

                                    if (this.state.form.title.value === '') {

                                        this.changeValue('title', project.title)

                                    }

                                    if (this.state.form.customerId.value === null) {

                                        this.changeValue('customerId', project.customerId)

                                    }

                                }}
                                value={this.state.form.projectId.value}
                                placeholder={t('project')}
                            />
                        </Col>
                        <Col md={6}>
                            <PredictionInput
                                options={this.state.customers}
                                onChangeValue={(val) => this.changeValue('customerId', val)}
                                value={this.state.form.customerId.value}
                                placeholder={t('customer')}
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col md={6}>
                            <TextInput
                                onChangeValue={(val) => this.changeValue('invoiceNumber', val)}
                                value={this.state.form.invoiceNumber.value}
                                placeholder={t('invoice_number')}
                                disabled
                                style={{
                                    width: 'calc(100% - 160px)',
                                    marginRight: 10
                                }}
                            />
                            {this.state.form.invoiceNumber.value === '' ?
                                <div
                                    className="button"
                                    style={{
                                        display: 'inline-block',
                                        paddingTop: 10,
                                        paddingBottom: 10,
                                    }}
                                    onClick={() => {
                                        this.invoiceUnit.getNewInvoiceNumber().then(res => {

                                            let formCopy = this.state.form
                                            formCopy.invoiceNumber.value = res.data.invoice_number
                                            this.setState({
                                                form: formCopy
                                            })
                                        })
                                    }}>{t('generate')}</div>

                                : null
                            }
                        </Col>
                        <Col md={6}>
                            <TextInput
                                onChangeValue={(val) => this.changeValue('title', val)}
                                value={this.state.form.title.value}
                                placeholder={t('title')}
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col md={6}>
                            <TextInput
                                type="datetime"
                                onChangeValue={(val) => this.changeValue('date', val)}
                                value={this.state.form.date.value}
                                placeholder={t('invoice_date')}
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col md={6}>
                            <TextInput
                                onChangeValue={(val) => this.changeValue('paymentGoalDays', val)}
                                value={this.state.form.paymentGoalDays.value}
                                placeholder={t('payment_goal')}
                            />
                        </Col>
                        <Col md={6}>

                        </Col>
                        {/* <Col md={6}>
                            <Select
                                onChange={(val) => this.changeEmploymentValue('paymentType', val.target.value)}
                                value={this.state.form.paymentType.value}
                                placeholder={"Zahlungstyp"}
                            >
                                <MenuItem value="onetime">Einmal</MenuItem>
                                <MenuItem value="payout">Monatlich</MenuItem>
                                <MenuItem value="payout">Jährlich</MenuItem>
                            </Select>
                        </Col> */}
                    </Row>
                    <Row>
                        <Col md={12}>
                            <TextInput
                                onChangeValue={(val) => this.changeValue('textBefore', val)}
                                value={this.state.form.textBefore.value}
                                placeholder={t('salutation_text')}
                                multiline
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col md={12}>
                            <TextInput
                                onChangeValue={(val) => this.changeValue('textAfter', val)}
                                value={this.state.form.textAfter.value}
                                placeholder={t('conclusion_text')}
                                multiline
                            />
                        </Col>
                    </Row><Row>
                        <Col md={12}>
                            <FormControlLabel
                                value={this.state.form.isReverseCharge.value}
                                control={<CustomCheckbox style={{ fontWeight: 200 }} checked={this.state.form.isReverseCharge.value} />}
                                label={t('reverse_charge') + '?'}
                                labelPlacement="end"
                                onChange={(event) => this.changeValue('isReverseCharge', !this.state.form.isReverseCharge.value)}
                                style={{ marginBottom: 10 }}
                            />
                        </Col>
                    </Row>



                    <Row style={{ paddingBottom: 20, paddingTop: 30 }}>
                        <Col md={6}><div className="add_button"
                            style={{ top: -8 }}
                            onClick={() => {

                                let invoice = this.state.invoice;

                                const newId = invoice.positions.length;

                                const newPosition = {
                                    amount: "1",
                                    description: "",
                                    price_net: "100.00",
                                    tax: "20.00",
                                    unit: "hour",
                                    value: "",
                                }

                                this.setState({
                                    positionCopy: clone(newPosition),
                                    selectedPosition: 'new',
                                })

                                this.openSidebar('new');

                            }}
                        ></div>
                            <h3>{t('positions')}</h3>

                        </Col>
                    </Row>
                    <Row>
                        <Col md={8}>

                            {
                                this.state.invoice.positions !== undefined ? this.state.invoice.positions.map((position, positionkey) => {

                                    return <div className="task_wrapper" key={positionkey}>
                                        <div
                                            className="task_container"
                                        >
                                            <div className="edit_button"
                                                onClick={() => this.openSidebar(positionkey)}
                                            ></div>
                                            <div className="remove_button"
                                                onClick={() => {
                                                    let invoiceCopy = this.state.invoice;
                                                    //delete invoiceCopy.positions[positionkey];
                                                    invoiceCopy.positions.splice(positionkey, 1)
                                                    invoiceCopy = JSON.parse(JSON.stringify(invoiceCopy))
                                                    this.setState({
                                                        invoice: invoiceCopy
                                                    })
                                                }}
                                            ></div>
                                            <Container>
                                                <Row>
                                                    <Col>
                                                        <strong>{position.value}</strong>
                                                    </Col>
                                                    <Col>
                                                        {formatMoney(position.price_net)}
                                                    </Col>
                                                    <Col>
                                                        {formatMoney(position.price_net * position.amount)}
                                                    </Col>
                                                </Row>
                                            </Container>
                                        </div>
                                    </div>
                                })
                                    : null
                            }
                        </Col>
                    </Row>

                </Container>
                <Button
                    ref={ref => this.saveButton = ref}
                    onClick={this.save}
                    label={t('save')}
                    fixed
                />

                <Sidebar
                    ref={ref => this.sidebar = ref}
                >
                    {this.renderPositionsSidebar()}
                </Sidebar>
                <PdfPopup
                    ref={ref => this.pdfPopup = ref}
                    style={{
                        maxWidth: 900,
                        position: 'absolute',
                        top: 50
                    }}
                    pdf={this.state.pdfBase64}
                    filename={'spectory_rechnung_' + this.state.form.invoiceNumber.value}
                >

                </PdfPopup>
                <Loading visible={this.state.loading} />
            </>
        );
    }
}
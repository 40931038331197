import { Table, TableBody, TableCell, TableHead, TableRow, TextField } from '@material-ui/core';
import React from 'react';
import { Container, Col, Row } from 'react-bootstrap';
import Loading from '../../helper/screens/Loading';
import Notification from '../../helper/screens/Notification';
import { validation } from '../../helper/validation';
import { Link } from 'react-router-dom';

import timetrackingTypes, { getType } from '../../helper/data/timetrackingTypes'
import TextInput from '../../helper/screens/TextInput';
import ProjectUnit from '../../communication/http/project';
import ProjectCache from '../../communication/locally/project';
import TimeslotUnit from '../../communication/http/timeslot';
import { formatTimeslotTimeRange, formatTimeFromDb, wrlog, getCurrentTime, formatDateForDbSimple, formatTimeToUnix, calculateHoursMinutes, reverseSearchProject, getProjectData } from '../../helper/misc';
import Button from '../../helper/screens/Button';

import { animateScroll as scroll } from 'react-scroll';
import UserUnit from '../../communication/http/user';
import { t } from '../../helper/multilingual/translate';

export default class Timemachine extends React.Component {

    constructor() {

        super();

        this.state = {
            show_errors: false,
            projects: [],
            timeslots: [],
            selectedProject: null,
            selectedTask: null,
            selectedSubtask: null,
            timeslotId: null,
            userData: {
                daily: 0,
                overtime: 0,
            },
            form: {
                from: {
                    valid: false,
                    value: new Date(),
                    rules: {
                        required: true
                    }
                },
                to: {
                    valid: false,
                    value: new Date(),
                    rules: {
                        required: true
                    }
                },
                fromTime: {
                    valid: false,
                    value: getCurrentTime(),
                    rules: {
                        required: true
                    }
                },
                toTime: {
                    valid: false,
                    value: getCurrentTime(),
                    rules: {
                        required: true
                    }
                },
                type: {
                    valid: true,
                    value: 'work',
                    rules: {
                        required: true
                    }
                },
                comment: {
                    valid: true,
                    value: '',
                    rules: {
                    }
                },
            }
        }

        this.projectUnit = new ProjectUnit();
        this.projectCache = new ProjectCache();

        this.userUnit = new UserUnit();

        this.timeslotUnit = new TimeslotUnit();

        this.notification = React.createRef();



    }

    componentDidMount() {

        this.props.set_current_page('/');

        // Get Projects for tasks

        if (this.projectCache.list() !== null) {
            this.setState({
                projects: this.projectCache.list()
            })
        }

        this.projectUnit.list().then(res => {

            this.projectCache.set(res.data);

            this.setState({
                projects: res.data
            })

        }).catch(err => {
        })

        this.resetTimelotList(new Date());

        this.userUnit.getTime()
            .then(res => {

                let userData = {
                    overtime: res.data.overtime * 60 * 1000,
                    daily: res.data.daily_hours * 60 * 60 * 1000
                }

                this.setState({
                    userData: userData
                })

            })

        if (this.props.match.params !== undefined && this.props.match.params.id !== undefined) {

            this.setTimeslot(this.props.match.params.id);

        }

    }

    setTimeslot = (timeslotId) => {

        this.setState({
            loading: true
        })

        // let selectedTimeslot = this.state.timeslots[timeslotKey];

        this.timeslotUnit.get(timeslotId).then(res => {

            console.log("timeslotId", res);

            let selectedTimeslot = res.data;

            let formCopy = this.state.form;

            let from = formatTimeFromDb(selectedTimeslot.datetime_from);
            let to = formatTimeFromDb(selectedTimeslot.datetime_to);

            formCopy.from.value = new Date(selectedTimeslot.datetime_from.replace(' ', 'T') + 'Z');
            formCopy.fromTime.value = from.time;

            formCopy.to.value = new Date(selectedTimeslot.datetime_to.replace(' ', 'T') + 'Z');
            formCopy.toTime.value = to.time;

            formCopy.comment.value = selectedTimeslot.comment;
            formCopy.type.value = selectedTimeslot.type;

            scroll.scrollToTop({
                duration: 200
            });

            this.setState({
                timeslotId: selectedTimeslot.id,
                form: formCopy,
                selectedProject: this.getProjectKey(selectedTimeslot.project_id),
                selectedTask: this.getTaskKeys(selectedTimeslot.task_id)[0],
                selectedSubtask: this.getTaskKeys(selectedTimeslot.task_id)[1],
                loading: false
            })


        })


    }

    getProjectKey = (id) => {
        let key = null;
        this.state.projects.forEach((project, index) => {
            if (id == project.id) key = index;
        })

        return key;
    }

    getTaskKeys = (id) => {

        let returnTaskKey = null;
        let returnSubTaskKey = null;

        wrlog(this.state.projects[this.state.selectedProject]);

        this.state.projects.forEach((project, projectKey) => {

            project.tasks.forEach((task, taskKey) => {

                if (id == task.task_id) {
                    returnTaskKey = taskKey;
                }

                task.tasks.forEach((subTask, subTaskKey) => {
                    if (id == subTask.task_id) {
                        returnTaskKey = taskKey;
                        returnSubTaskKey = subTaskKey;
                    }

                })

            })

        })

        return [
            returnTaskKey,
            returnSubTaskKey
        ];

    }

    resetTimelotList = (date) => {
        this.timeslotUnit.listUsersTodaysTimeslots(date).then(res => {

            res.data.sort((a, b) => {
                return formatTimeToUnix(a.datetime_from) - formatTimeToUnix(b.datetime_from);
            })

            this.setState({
                timeslots: res.data,
                loading: false
            });

            this.resetStartTime();

        }).catch(err => {
            console.log(err)
        })
    }

    resetStartTime = () => {
        let timeslots = this.state.timeslots;

        if (timeslots.length > 0) {
            let lastDateTime = timeslots[timeslots.length - 1].datetime_to;

            lastDateTime = formatTimeFromDb(formatTimeToUnix(lastDateTime)).time

            this.changeValue('fromTime', lastDateTime);
        }

    }

    resetForm = () => {

        let formCopy = this.state.form;

        formCopy.from.value = new Date();

        formCopy.to.value = new Date();
        formCopy.toTime.value = getCurrentTime();

        formCopy.comment.value = '';
        formCopy.type.value = 'work';

        scroll.scrollToTop({
            duration: 200
        });

        this.setState({
            timeslotId: null,
            selectedProject: null,
            selectedTask: null,
            selectedSubtask: null,
            form: formCopy
        })

        this.resetStartTime();

    }

    save = () => {

        let projectId = null;
        let taskId = null;
        let valid = true;

        this.saveButton.statusLoading();

        let type = getType(this.state.form.type.value)

        if (type.hasProject) {

            if (this.state.selectedProject === null) {
                valid = false;
                this.notification.current.open('error', t('choose_project_or_task'));
            } else {
                projectId = this.state.projects[this.state.selectedProject].id;
            }

            if (this.state.selectedSubtask === null && this.state.selectedTask !== null && this.state.selectedProject !== null) {
                taskId = this.state.projects[this.state.selectedProject].tasks[this.state.selectedTask].task_id;
            } else if (this.state.selectedSubtask !== null && this.state.selectedTask !== null && this.state.selectedProject !== null) {
                taskId = this.state.projects[this.state.selectedProject].tasks[this.state.selectedTask].tasks[this.state.selectedSubtask].task_id;
            }

        }

        let hasFromDate = getType(this.state.form.type.value).fields.includes('fromTime');
        let hasFromTime = getType(this.state.form.type.value).fields.includes('fromDate');
        let hasToDate = getType(this.state.form.type.value).fields.includes('toDate');
        let hasToTime = getType(this.state.form.type.value).fields.includes('toTime');

        let from = null;
        let to = null;


        if (hasFromDate && hasFromTime) {
            from = formatDateForDbSimple(this.state.form.from.value) + ' ' + this.state.form.fromTime.value + ':00';
        } else if (hasFromDate && !hasFromTime) {
            from = formatDateForDbSimple(this.state.form.from.value) + ' 00:00:00';
        }

        if (hasToDate && hasToTime) {
            to = formatDateForDbSimple(this.state.form.to.value) + ' ' + this.state.form.toTime.value + ':00';
        } else if (hasToDate && !hasToTime) {
            to = formatDateForDbSimple(this.state.form.to.value) + ' 23:59:59';
        } else if (hasFromDate && !hasToDate) {
            to = formatDateForDbSimple(this.state.form.from.value) + ' ' + this.state.form.toTime.value + ':00';
        }

        if (formatTimeToUnix(to) <= formatTimeToUnix(from)) {
            this.notification.current.open('error', t('starttime_must_before_endtime'));
            valid = false;
        }

        if (!valid) {
            this.saveButton.statusReset();
            return;
        }

        if (this.state.timeslotId === null) {

            this.timeslotUnit.create(
                from,
                to,
                this.state.form.type.value,
                this.state.form.comment.value,
                projectId,
                taskId,
            ).then(res => {

                wrlog(res);
                this.resetForm();
                if (res.success) {
                    this.saveButton.statusSuccess();
                    this.resetTimelotList();
                } else {
                    this.saveButton.statusReset();
                    this.notification.current.open('error', res);
                }

            }).catch(err => {

            });

        } else {

            this.timeslotUnit.update(
                this.state.timeslotId,
                from,
                to,
                this.state.form.type.value,
                this.state.form.comment.value,
                projectId,
                taskId
            ).then(res => {


                if (res.success) {

                    this.saveButton.statusSuccess();
                    this.resetForm();

                    this.resetTimelotList(from);

                } else {

                    this.saveButton.statusReset();
                    this.notification.current.open('error', res);

                }

            }).catch(err => {

            });

        }

    }

    changeValue = (key, value) => {

        let formCopy = this.state.form;

        if (typeof value === 'object' && value.target !== undefined) {
            value = value.target.value;
        }

        formCopy[key].value = value;
        formCopy[key].valid = validation(value, formCopy[key].rules);

        this.setState({ form: formCopy });

    }

    renderTasks = () => {

        return <>
            <div className="timetracking_task_wrapper">
                {
                    this.state.projects.map((project, projectKey) => {

                        if (project.state !== 'active') return;

                        return <div
                            className={"timetracking_task_container" + (this.state.selectedProject === projectKey ? ' selected' : '')}
                            onClick={() => {

                                this.setState({
                                    selectedProject: projectKey,
                                    selectedTask: null,
                                    selectedSubtask: null,
                                })
                            }}
                        >{project.title}</div>

                    })
                }
            </div>
            <div className="timetracking_task_wrapper">
                {
                    this.state.selectedProject !== null ? this.state.projects[this.state.selectedProject].tasks.map((task, taskKey) => {

                        return <div
                            className={"timetracking_task_container" + (this.state.selectedTask === taskKey ? ' selected' : '')}
                            onClick={() => {

                                this.setState({
                                    selectedTask: taskKey,
                                    selectedSubtask: null,
                                })

                            }}
                        >{task.title}</div>

                    }) : null
                }
            </div>
            <div className="timetracking_task_wrapper">
                {
                    this.state.selectedProject !== null && this.state.selectedTask !== null ? this.state.projects[this.state.selectedProject].tasks[this.state.selectedTask].tasks.map((subTask, subTaskkey) => {

                        return <div
                            className={"timetracking_task_container" + (this.state.selectedSubtask === subTaskkey ? ' selected' : '')}
                            onClick={() => {

                                this.setState({
                                    selectedSubtask: subTaskkey,
                                })

                            }}
                        >{subTask.title}</div>

                    }) : null
                }
            </div>

        </>

    }

    delete = (id) => {

        this.setState({
            loading: true
        })

        this.timeslotUnit.delete(id).then(res => {

            if (res.success) {

                this.resetTimelotList();

            } else {

                this.notification.current.open('error', res);

            }
        }).catch(err => {

            this.setState({
                loading: false
            })

        })
    }

    renderTimeslots = () => {

        let workedSum = 0;

        return <Table>
            <TableHead>
                <TableRow>
                    <TableCell>
                        {t('type')}
                    </TableCell>
                    <TableCell>
                        {t('project_task')}
                    </TableCell>

                    <TableCell style={{ width: 100 }}>
                        {t('duration')}
                    </TableCell>
                    <TableCell style={{ width: 400 }}>
                        {t('time')}
                    </TableCell>
                    <TableCell style={{ width: 100 }}>

                    </TableCell>
                </TableRow>
            </TableHead>
            <TableBody>
                {
                    this.state.timeslots.map((timeslot, key) => {

                        let time = formatTimeToUnix(timeslot.datetime_to) - formatTimeToUnix(timeslot.datetime_from);

                        if (getType(timeslot.type).isWorktime) {
                            workedSum = workedSum + time;
                        }

                        let timeFormatted = calculateHoursMinutes(time);

                        return <TableRow key={key}>
                            <TableCell>
                                {t(timeslot.type)}
                            </TableCell>
                            <TableCell>
                                {timeslot.task_id > 0 ? reverseSearchProject(this.state.projects, timeslot.task_id).join(' > ') : getProjectData(this.state.projects, timeslot.project_id).title}
                            </TableCell>

                            <TableCell style={{ width: 100 }}>
                                {timeFormatted.hours}h {timeFormatted.minutes}min
                            </TableCell>
                            <TableCell style={{ width: 400 }}>
                                {formatTimeslotTimeRange(timeslot)}
                            </TableCell>
                            <TableCell style={{ width: 400 }}>
                                {timeslot.user_id}
                            </TableCell>
                            <TableCell style={{ width: 100 }}>
                                <div className="link" onClick={() => this.setTimeslot(timeslot.id)}>
                                    {t('edit')}
                                </div>
                            </TableCell>
                            <TableCell style={{ width: 100 }}>
                                <div className="link" onClick={() => this.delete(timeslot.id)}>
                                    {t('delete')}
                                </div>
                            </TableCell>
                        </TableRow>

                    })
                }

                <TableRow>
                    <TableCell colSpan={5}>
                        <strong style={{ marginRight: 30 }}>{t('day_workingtime')}: {calculateHoursMinutes(workedSum).hours}h {calculateHoursMinutes(workedSum).minutes}min</strong>
                        {isNaN(calculateHoursMinutes(this.state.userData.daily).hours) ? <strong style={{color: 'red'}}>SOLL-Arbeitszeit nicht eingestellt.</strong> : <>
                            <strong>{t('workingtime_should')}: {calculateHoursMinutes(this.state.userData.daily).hours}h {calculateHoursMinutes(this.state.userData.daily).minutes}min</strong>
                            <strong>{t('difference')}: {calculateHoursMinutes(this.state.userData.overtime).hours}h {calculateHoursMinutes(this.state.userData.overtime).minutes}min</strong>
                        </>}
                    </TableCell>
                </TableRow>


            </TableBody>
        </Table>
    }


    render() {
        return (
            <>
                <Container fluid>
                    <Row>
                        <Col md={5}>
                            <div>{t('type_of_timetracking')}</div>
                            <div style={{ display: 'flex' }}>
                                {
                                    timetrackingTypes.map(type => {

                                        const image = require('../../assets/icons/' + type.icon);

                                        return <div
                                            onClick={() => {
                                                this.changeValue('type', type.id);

                                                if (!type.hasProject) {
                                                    this.setState({
                                                        selectedProject: null,
                                                        selectedTask: null,
                                                        selectedSubtask: null,
                                                    })
                                                }
                                            }}
                                            className={"big_radio" + (this.state.form.type.value === type.id ? ' checked' : '')}
                                            style={{ backgroundImage: `url(${image.default})` }}
                                        >
                                            <strong>{t(type.id)}</strong>
                                        </div>
                                    })
                                }
                            </div>

                        </Col>
                        <Col md={7}>
                            <Container fluid>
                                <Row>
                                    {
                                        getType(this.state.form.type.value).fields.includes('fromDate') ?
                                            <Col md={6}>
                                                <TextInput
                                                    onChangeValue={(val) => {
                                                        this.changeValue('from', val)
                                                        this.resetTimelotList(val);
                                                    }}
                                                    value={this.state.form.from.value}
                                                    placeholder={getType(this.state.form.type.value).fields.includes('fromDate') && getType(this.state.form.type.value).fields.includes('toDate') ? t('from') : t('date')}
                                                    type="datetime"
                                                />
                                            </Col>
                                            :
                                            null
                                    }
                                    {
                                        getType(this.state.form.type.value).fields.includes('toDate') ?
                                            <Col md={6}>
                                                <TextInput
                                                    onChangeValue={(val) => this.changeValue('to', val)}
                                                    value={this.state.form.to.value}
                                                    placeholder={t('to')}
                                                    type="datetime"
                                                />
                                            </Col>
                                            :
                                            null
                                    }

                                    {
                                        getType(this.state.form.type.value).fields.includes('fromTime') ?
                                            <Col md={3}>
                                                <TextInput
                                                    onChangeValue={(val) => this.changeValue('fromTime', val)}
                                                    value={this.state.form.fromTime.value}
                                                    placeholder={t('from')}
                                                    type="time"
                                                />
                                            </Col>
                                            :
                                            null
                                    }
                                    {
                                        getType(this.state.form.type.value).fields.includes('toTime') ?
                                            <Col md={3}>
                                                <TextInput
                                                    onChangeValue={(val) => this.changeValue('toTime', val)}
                                                    value={this.state.form.toTime.value}
                                                    placeholder={t('to')}
                                                    type="time"
                                                />
                                            </Col>
                                            :
                                            null
                                    }
                                </Row>
                                <Row>
                                    <Col>
                                        <TextInput
                                            onChangeValue={(val) => this.changeValue('comment', val)}
                                            value={this.state.form.comment.value}
                                            placeholder={t('comment')}
                                            multiline
                                        />
                                    </Col>
                                </Row>
                            </Container>
                        </Col>
                    </Row>
                    <Row style={{ marginTop: 50 }}>
                        <Col>
                            {getType(this.state.form.type.value).hasProject ? <>
                                <div>{t('project_task')}</div>
                                {this.renderTasks()}

                            </>
                                : null}
                        </Col>
                    </Row>
                    <Row style={{ marginTop: 100 }}>
                        <Col>
                            {this.renderTimeslots()}
                        </Col>
                    </Row>
                </Container>
                <Button
                    label={this.state.timeslotId !== null ? t('save') : t('book')}
                    ref={ref => this.saveButton = ref}
                    onClick={this.save}
                    fixed
                />
                {
                    this.state.timeslotId !== null ?

                        <Button
                            label={t('cancel')}
                            ref={ref => this.cancelButton = ref}
                            onClick={() => {
                                this.resetForm()
                            }}
                            fixed
                            className=" nobackground"
                            style={{
                                right: 170,
                            }}
                        />

                        : null
                }
                <Loading visible={this.state.loading} />
                <Notification ref={this.notification} text="Invalid email or password" type="error" />
            </>
        );
    }
}
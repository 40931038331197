import { FormControlLabel, Table, TableBody, TableCell, TableRow, TextField } from '@material-ui/core';
import React from 'react';
import { Container, Col, Row } from 'react-bootstrap';
import Loading from '../../../helper/screens/Loading';
import Notification from '../../../helper/screens/Notification';
import { validation } from '../../../helper/validation';

import TextInput from '../../../helper/screens/TextInput';

import ProjectUnit from '../../../communication/http/project'
import { Link } from 'react-router-dom';
import ProjectCache from '../../../communication/locally/project';
import { removeA, wrlog } from '../../../helper/misc';
import Button from '../../../helper/screens/Button';
import PredictionInput from '../../../helper/screens/PredictionInput';
import CustomerUnit from '../../../communication/http/customer';
import CustomerCache from '../../../communication/locally/customer';
import CustomCheckbox from '../../../helper/screens/CustomCheckbox';
import PasswordUnit from '../../../communication/http/passwords';
import Popup from '../../../helper/screens/Popup';
import { t } from '../../../helper/multilingual/translate';


export default class Password extends React.Component {

    constructor() {

        super();

        this.state = {
            show_errors: false,
            project: {
                tasks: []
            },
            customers: [],
            projects: [],
            selected_task: null,
            selected_subtask: null,
            passwordId: null,
            buttonStatus: null,
            newLink: null,
            form: {
                credentials: {
                    valid: true,
                    value: [
                        'password'
                    ],
                    rules: {
                    }
                },
                password: {
                    valid: true,
                    value: '',
                    rules: {
                    }
                },
                customerId: {
                    valid: true,
                    value: null,
                    rules: {
                    }
                },
                projectId: {
                    valid: true,
                    value: null,
                    rules: {
                    }
                },
                title: {
                    valid: true,
                    value: '',
                    rules: {
                    }
                },
                site: {
                    valid: true,
                    value: '',
                    rules: {
                    }
                },
                user: {
                    valid: true,
                    value: '',
                    rules: {
                    }
                },
                comment: {
                    valid: true,
                    value: '',
                    rules: {
                    }
                },
            }
        }

        this.passwordUnit = new PasswordUnit();

        this.projectUnit = new ProjectUnit();
        this.projectCache = new ProjectCache();

        this.customerUnit = new CustomerUnit();
        this.customerCache = new CustomerCache();

    }

    componentDidMount() {

        this.props.set_current_page('/passwords');

        this.setState({
            passwordId: this.props.match.params.id
        })

        if (this.props.match.params.id === 'new') {

            this.setState({
                loading: false
            })

        } else {

            this.passwordUnit.get(this.props.match.params.id).then(password => {

                if (password !== null) {

                    let formCopy = this.state.form;

                    formCopy.site.value = password.data.site;
                    formCopy.title.value = password.data.title;
                    formCopy.customerId.value = parseInt(password.data.customer_id);
                    formCopy.projectId.value = parseInt(password.data.project_id);
                    formCopy.user.value = password.data.user;
                    formCopy.password.value = password.data.password;
                    formCopy.comment.value = password.data.comment;
                    formCopy.title.value = password.data.title;

                    this.setState({
                        form: formCopy
                    })

                } else {

                    this.setState({
                        loading: true
                    })

                }

            })

        }

        this.customerUnit.list().then(res => {

            let customers = [];

            res.data.map((customer) => {
                customers.push({
                    id: customer.id,
                    label: customer.company,
                })
            })

            this.setState({ customers: customers })

        }).catch(err => {
            console.log(err)
        })

        if (this.projectCache.list() !== null) {
            this.setState({
                projects: this.projectCache.list()
            })
        }

        this.projectUnit.list().then(res => {
            console.log(res)

            this.projectCache.set(res.data);

            let projects = [];

            res.data.map((project) => {
                projects.push({
                    id: project.id,
                    label: project.title,
                })
            })

            this.setState({ projects: projects })

        }).catch(err => {
            console.log(err)
        })




    }

    changeValue = (key, value) => {

        let formCopy = this.state.form;
        if (typeof value === 'object' && value.target !== undefined) {
            value = value.target.value;
        }

        formCopy[key].value = value;
        formCopy[key].valid = validation(value, formCopy[key].rules);

        this.setState({ form: formCopy })

    }

    setCredential = (credential) => {

        let formCopy = this.state.form;

        let credentials = formCopy.credentials.value;

        if (formCopy.credentials.value.includes(credential)) {
            formCopy.credentials.value = removeA(credentials, credential);
        } else {
            formCopy.credentials.value.push(credential);
        }


        this.setState({
            form: formCopy
        })
    }

    getLink = () => {

        this.passwordUnit.createLink(this.state.passwordId, this.state.form.credentials.value).then(res => {

            this.setState({
                newLink: res.data.link
            })

        }).catch(err => {

        })

    }

    save = () => {

        this.setState({
            loading: true
        })

        this.saveButton.statusLoading();

        if (this.state.passwordId === 'new') {

            // customerId, projectId, site, password, user, comment

            this.passwordUnit.create(
                this.state.form.customerId.value,
                this.state.form.projectId.value,
                this.state.form.title.value,
                this.state.form.site.value,
                this.state.form.password.value,
                this.state.form.user.value,
                this.state.form.comment.value
            ).then(res => {

                if (res.success) {
                    this.loadProjects();

                    const newId = res.project_id;

                    this.setState({
                        loading: false,
                        passwordId: newId
                    })

                    this.saveButton.statusSuccess();
                } else {
                    this.saveButton.statusReset();

                }

            }).catch(err => {

                this.setState({
                    loading: false
                })

            })

        } else {

            this.passwordUnit.update(
                this.state.passwordId,
                this.state.form.customerId.value,
                this.state.form.projectId.value,
                this.state.form.title.value,
                this.state.form.site.value,
                this.state.form.password.value,
                this.state.form.user.value,
                this.state.form.comment.value
            ).then(res => {

                if (res.success) {

                    this.setState({
                        loading: false
                    })

                    this.saveButton.statusSuccess();

                } else {
                    this.saveButton.statusReset();

                }

            }).catch(err => {

                this.setState({
                    loading: false
                })

            })

        }

    }


    render() {
        return (
            <>
                <Container fluid>
                    <Row>
                        <Col md={12}>
                            <Link className="go_back_button" to="/passwords">{t('back')}</Link>
                        </Col>
                    </Row>
                    <Row>
                        <Col md={12}>
                            <h1>{(this.state.passwordId === 'new' ? t('create_password') : t('edit_password'))}</h1>
                        </Col>
                    </Row>
                    <Row>
                        <Col md={12} style={{ paddingTop: 10, paddingBottom: 50 }}>
                            <div
                                className="link"
                                onClick={() => this.popup.open()}
                            >{t('generate_link')}</div>
                        </Col>
                    </Row>
                    <Row>
                        <Col md={6}>
                            <PredictionInput
                                options={this.state.customers}
                                onChangeValue={(val) => this.changeValue('customerId', val)}
                                value={this.state.form.customerId.value}
                                placeholder={t('customer')}
                            />
                        </Col>
                        <Col md={6}>
                            <PredictionInput
                                options={this.state.projects}
                                onChangeValue={(val) => this.changeValue('projectId', val)}
                                value={this.state.form.projectId.value}
                                placeholder={t('project')}
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col md={6}>
                            <TextInput
                                onChangeValue={(val) => this.changeValue('title', val)}
                                value={this.state.form.title.value}
                                placeholder={t('title')}
                            />
                        </Col>
                        <Col md={6}>
                            <TextInput
                                onChangeValue={(val) => this.changeValue('site', val)}
                                value={this.state.form.site.value}
                                placeholder={t('page')}
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col md={6}>
                            <TextInput
                                onChangeValue={(val) => this.changeValue('user', val)}
                                value={this.state.form.user.value}
                                placeholder={"Username"}
                            />
                        </Col>
                        <Col md={6}>
                            <TextInput
                                onChangeValue={(val) => this.changeValue('password', val)}
                                value={this.state.form.password.value}
                                placeholder={t('password')}
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col md={6}>
                            <TextInput
                                onChangeValue={(val) => this.changeValue('comment', val)}
                                value={this.state.form.comment.value}
                                placeholder={t('comment')}
                                multiline
                            />
                        </Col>
                    </Row>
                </Container>
                <Button
                    ref={ref => this.saveButton = ref}
                    onClick={this.save}
                    label={t('save')}
                    fixed
                />
                <Popup ref={ref => this.popup = ref}>
                    <FormControlLabel
                        value={'password'}
                        control={<CustomCheckbox style={{ fontWeight: 200 }} checked={this.state.form.credentials.value.includes('password')} />}
                        label={t('password')}
                        labelPlacement="end"
                        onChange={(event) => this.setCredential('password')}
                        style={{marginBottom: 10}}
                    /><br/>
                    <FormControlLabel
                        value={'user'}
                        control={<CustomCheckbox style={{ fontWeight: 200 }} checked={this.state.form.credentials.value.includes('user')} />}
                        label={t('username')}
                        labelPlacement="end"
                        onChange={(event) => this.setCredential('user')}
                        style={{marginBottom: 10}}
                    /><br/>
                    <FormControlLabel
                        value={'comment'}
                        control={<CustomCheckbox style={{ fontWeight: 200 }} checked={this.state.form.credentials.value.includes('comment')} />}
                        label={t('comment')}
                        labelPlacement="end"
                        onChange={(event) => this.setCredential('comment')}
                        style={{marginBottom: 10}}
                    />

                    <Button
                        style={{ marginTop: 40, marginBottom: 40 }}
                        ref={ref => this.linkButton = ref}
                        onClick={this.getLink}
                        label={t('generate_link')}
                    />

                    {
                        this.state.newLink !== null ?
                            <TextInput
                                value={this.state.newLink}
                                placeholder={t('new_link')}
                                disabled
                            />
                            : null
                    }

                </Popup>
                <Loading visible={this.state.loading} />
            </>
        );
    }
}
import { OutlinedInput } from '@material-ui/core';
import moment from 'moment';
import { SHOW_LOGS } from '../store';
import { getType } from './data/timetrackingTypes';

/**
 * format date to object of date and time
 * @param {Date} datetime 
 */
export const formatTimeFromDb = (datetime) => {

    let date = moment(datetime);

    return {
        date: date.format('DD.MM.YYYY'),
        time: date.format('HH:mm'),
    };

}

/**
 * format datetime to string
 * @param {*} datetime 
 */
export const formatDatetime = (datetime) => {

    if (typeof datetime === 'string') {

        datetime = datetime.replace(' ', 'T');
        datetime = new Date(datetime);

    }

    return moment(datetime).format('DD.MM.YYYY HH:mm')
}

/**
 * Format datetime to unix
 * @param {*} datetime 
 */
export const formatTimeToUnix = (datetime) => {

    if (typeof datetime === 'string') {

        datetime = datetime.replace(' ', 'T');
        datetime = new Date(datetime);

    }

    return datetime.getTime();
}

/**
 * get current time to unix
 */
export const getCurrentUnix = () => {
    return new Date().getTime();
}

/**
 * gets formated current time
 */
export const getCurrentTime = () => {

    const date = new Date();

    let minutes = date.getMinutes();
    let hours = date.getHours();

    if (minutes < 10) minutes = '0' + minutes;
    if (hours < 10) hours = '0' + hours;

    return hours + ':' + minutes;
}

/**
 * Format datetime for server
 * @param {Date} datetime 
 */
export const formatDatetimeForDb = (datetime) => {
    return moment(datetime).format('YYYY-MM-DDTHH:mm:00') + 'Z';
}

/**
 * Format datetime for server
 * @param {*} datetime 
 */
export const formatDatetimeForDbSimple = (datetime) => {
    return moment(datetime).format('YYYY-MM-DD HH:mm:00');
}

/**
 * Format date for server
 * @param {*} datetime 
 */
export const formatDateForDbSimple = (datetime) => {
    return moment(datetime).format('YYYY-MM-DD');
}

/**
 * Returns formatted current datetime
 */
export const currentDateTime = () => {

    return moment().format('DD.MM.YYYY HH:mm')

}

/**
 * Formats datetime and calculates timezone difference
 * @param {int} milliseconds 
 */
export const calculateHoursMinutes = (milliseconds) => {

    let minutes = Math.floor(milliseconds / 1000 / 60);
    let hours = Math.floor(minutes / 60);
    minutes = minutes - hours * 60;

    return {
        hours: hours,
        minutes: minutes
    }

}

/**
 * Formats datetime and calculates timezone difference
 * @param {*} date 
 */
export const calculateLocalTimezone = (date) => {

    if (typeof date === 'string') {

        date = date.replace(' ', 'T');
        date = new Date(date);

    }

    let offset = date.getTimezoneOffset();
    let newDate = new Date(date.getTime() - (offset * 60 * 1000));
    return moment(newDate).format('DD.MM.YYYY - HH:mm')

}

/**
 * returns utc formatted datetime and calculates timezone difference
 * @param {Date} date
 */
export const calculateUtcTimezone = (date) => {

    let offset = date.getTimezoneOffset();
    let newDate = new Date(date.getTime() + (offset * 60 * 1000));

    return moment(newDate).format('YYYY-MM-DDTHH:mm:00') + 'Z';

}

export const formatTimeslotTimeRange = (timeslot) => {

    const hasFromDate = getType(timeslot.type).fields.includes('fromTime');
    const hasFromTime = getType(timeslot.type).fields.includes('fromDate');
    const hasToDate = getType(timeslot.type).fields.includes('toDate');
    const hasToTime = getType(timeslot.type).fields.includes('toTime');

    const sameDate = formatTimeFromDb(timeslot.datetime_from).date === formatTimeFromDb(timeslot.datetime_to).date;

    if (hasFromDate && hasToDate && hasFromTime && hasToTime) {

        if (sameDate) {
            return formatDatetime(timeslot.datetime_from) + ' - ' + formatTimeFromDb(timeslot.datetime_to).time;
        }
        return formatDatetime(timeslot.datetime_from) + ' - ' + formatDatetime(timeslot.datetime_to);

    } else if (hasFromDate && !hasToDate && hasFromTime && hasToTime) { // Like normal work / pause etc.

        return formatDatetime(timeslot.datetime_from) + ' - ' + formatTimeFromDb(timeslot.datetime_to).time;

    } else if (hasFromDate && hasToDate && !hasFromTime && !hasToTime) {

        if (sameDate) {
            return formatTimeFromDb(timeslot.datetime_from).date;
        }
        return formatTimeFromDb(timeslot.datetime_from).date + ' - ' + formatTimeFromDb(timeslot.datetime_to).date;

    }




}


/**
 * Removes Object from Array by value
 * @param {Array} arr 
 * @param {*} needle value
 */
export const removeA = (arr, needle) => {
    let ax = arr.indexOf(needle)
    if (ax !== -1) {
        arr.splice(ax, 1);
    }
    return arr;
}

export const wrlog = (label, value = null) => {

    if (SHOW_LOGS) {
        if (value === null) {
            console.log(label);
        } else {
            console.log(label, value);
        }
    }

}

export const getProjectData = (projects, id) => {

    let output = {};

    projects.forEach(project => {

        if (parseInt(project.id) === parseInt(id)) {
            output = project
        }

    })

    return output;
}

export const reverseSearchProject = (projects, id, displayTitle = true) => {

    let output = [];

    projects.forEach((project) => {

        project.tasks.forEach(task => {

            if (parseInt(task.task_id) === parseInt(id)) {

                if (displayTitle) {
                    output.push(project.title);
                    output.push(task.title);
                } else {
                    output.push(task.task_id);
                }

            }

            task.tasks.forEach(subTask => {

                if (parseInt(subTask.task_id) === parseInt(id)) {

                    if (displayTitle) {
                        output.push(project.title);
                        output.push(task.title);
                        output.push(subTask.title);
                    } else {
                        output.push(task.task_id);
                        output.push(subTask.task_id);
                    }

                }

            })

        })

    });

    return output;
}

export const formatMoney = (raw) => {

    raw = raw.toString();

    let splitted = raw.split('.');

    if (raw.includes(',')) {
        splitted = raw.split(',');
    }
    let count = 0;

    let wholeNumbers = '';

    let splittedWholeNumbers = splitted[0].split("").reverse()

    splittedWholeNumbers.forEach((number, index) => {

        wholeNumbers = number + wholeNumbers;

        if (count == 2 && splittedWholeNumbers[index + 1] !== undefined) {

            wholeNumbers = '.' + wholeNumbers;

            count = -1;
        }

        count = count + 1;
    })

    if(splitted[1] !== undefined) {
        let stringed = splitted[1].toString();
        splitted[1] = stringed.length === 1 ? stringed+'0' : splitted[1];
    }

    return 'EUR ' + wholeNumbers + ',' + (splitted[1] !== undefined ? splitted[1] : '00');


}

export const formatMoneyForDB = (formatted) => {
    // 1.000,00 to 1000.00

    formatted = formatted.replaceAll('€', '');
    // formatted = formatted.replaceAll('.', '');
    // formatted = formatted.replaceAll(',', '.');

    return parseFloat(formatted);

}


export const clone = (original) => {
    return JSON.parse(JSON.stringify(original));
}
import React from 'react';


/**
 * Render Switch Element
 */
export default class Switch extends React.Component {

    render() {

        return (
            <div className={"switch" + (this.props.selected ? ' selected' : '')} onClick={this.props.onChange}>{this.props.title}</div>
        );

    }
}
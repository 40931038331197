import { FormControlLabel, MenuItem, Select, Table, TableBody, TableCell, TableRow, TextField } from '@material-ui/core';
import React from 'react';
import { Container, Col, Row } from 'react-bootstrap';
import Loading from '../../../helper/screens/Loading';
import Notification from '../../../helper/screens/Notification';
import { validation } from '../../../helper/validation';

import TextInput from '../../../helper/screens/TextInput';

import { Link } from 'react-router-dom';
import { formatTimeFromDb, wrlog, clone, removeA } from '../../../helper/misc';
import Button from '../../../helper/screens/Button';
import PredictionInput from '../../../helper/screens/PredictionInput';

import CustomCheckbox from '../../../helper/screens/CustomCheckbox';
import { t } from '../../../helper/multilingual/translate';
import WebsiteUnit from '../../../communication/http/website';
import ProjectUnit from '../../../communication/http/project';
import ProjectCache from '../../../communication/locally/project';


export default class WebsiteDetail extends React.Component {

    constructor() {

        super();

        this.state = {
            show_errors: false,
            websiteDatabaseId: null,
            buttonStatus: null,
            projects: [],
            form: {
                websiteId: {
                    valid: false,
                    value: '',
                    rules: {
                        isRequired: true
                    }
                },
                projectId: {
                    valid: false,
                    value: '',
                    rules: {
                        isRequired: true
                    }
                },
                title: {
                    valid: false,
                    value: '',
                    rules: {
                        isRequired: true
                    }
                },
                url: {
                    valid: true,
                    value: '',
                    rules: {
                    }
                },
                gitUrl: {
                    valid: true,
                    value: '',
                    rules: {
                    }
                },
                gitClient: {
                    valid: true,
                    value: '',
                    rules: {
                    }
                },
                gitKey: {
                    valid: true,
                    value: '',
                    rules: {
                    }
                },
                ftpHost: {
                    valid: true,
                    value: '',
                    rules: {
                    }
                },
                ftpPort: {
                    valid: true,
                    value: '',
                    rules: {
                    }
                },
                ftpUser: {
                    valid: true,
                    value: '',
                    rules: {
                    }
                },
                ftpPass: {
                    valid: true,
                    value: '',
                    rules: {
                    }
                },
                ftpPath: {
                    valid: true,
                    value: '',
                    rules: {
                    }
                },
                state: {
                    valid: true,
                    value: '',
                    rules: {
                    }
                }
            },
            currentParentState: 'active',
        }

        this.websiteUnit = new WebsiteUnit();
        this.projectUnit = new ProjectUnit();
        this.projectCache = new ProjectCache();

    }

    componentDidMount() {
        if (this.props.location.state !== undefined && this.props.location.state.currentState !== undefined) {
            this.setState({
                currentParentState: this.props.location.state.currentState
            })
        }
        this.props.set_current_page('/websites');

        this.projectUnit.list().then(res => {
            console.log(res)

            this.projectCache.set(res.data);

            let projects = [];

            res.data.map((project) => {
                projects.push({
                    id: project.id,
                    label: project.title,
                })
            })

            this.setState({ projects: projects })
        })



        this.setState({
            websiteDatabaseId: this.props.match.params.id
        })

        if (this.props.match.params.id === 'new') {

            this.setState({
                loading: false
            });

        } else {

            this.getWebsite(this.props.match.params.id);

        }

    }

    setWebsite = (website) => {
        wrlog("website", website);
        let formCopy = this.state.form;

        // formCopy.state.value = website.state === 'active';
        formCopy.title.value = website.title;
        formCopy.title.valid = true;
        formCopy.url.value = website.url;
        formCopy.websiteId.value = website.website_id;
        formCopy.websiteId.valid = true;
        formCopy.projectId.value = website.project_id;
        formCopy.projectId.valid = true;


        formCopy.gitUrl.value = website.git_url;
        formCopy.gitClient.value = website.git_client_id;
        formCopy.gitKey.value = website.git_key;
        formCopy.ftpHost.value = website.ftp_host;
        formCopy.ftpPort.value = website.ftp_port;
        formCopy.ftpUser.value = website.ftp_user;
        formCopy.ftpPass.value = website.ftp_pass;
        formCopy.ftpPath.value = website.ftp_path;

        this.setState({
            form: formCopy,
        })
    }

    changeValue = (key, value) => {

        let formCopy = this.state.form;
        if (typeof value === 'object' && value.target !== undefined) {
            value = value.target.value;
        }

        wrlog(key, value)

        formCopy[key].value = value;
        formCopy[key].valid = validation(value, formCopy[key].rules);

        this.setState({ form: formCopy })

    }

    changeEmploymentValue = (key, value) => {

        let employmentsCopy = this.state.selectedEmployment;

        employmentsCopy[key] = value;

        this.setState({
            selectedEmployment: employmentsCopy,
        });

    }

    getWebsite = (websiteDatabaseId) => {
        this.websiteUnit.get(websiteDatabaseId).then(website => {
            if (website !== null) {

                this.setWebsite(website.data);

            } else {

                this.setState({
                    loading: true
                })

            }
        }).catch(err => {
            console.log(err);
        })
    }

    save = () => {

        this.setState({
            show_errors: false
        })

        let isValid = true;
        Object.keys(this.state.form).forEach(key => {
            let element = this.state.form[key];

            if (!element.valid) {
                isValid = false;
            }
        });

        if (!isValid) {
            // this.saveButton.statusError();
            this.setState({
                show_errors: true
            })
            this.notification.open('error', 'Please fill all inputs');
            return;
        }

        this.setState({
            loading: true
        });


        this.saveButton.statusLoading();

        if (this.state.websiteDatabaseId === 'new') {

            this.websiteUnit.create(
                this.state.form.websiteId.value,
                this.state.form.projectId.value,
                this.state.form.title.value,
                this.state.form.url.value,
                this.state.form.gitUrl.value,
                this.state.form.gitKey.value,
                this.state.form.gitClient.value,
                this.state.form.ftpHost.value,
                this.state.form.ftpPort.value,
                this.state.form.ftpUser.value,
                this.state.form.ftpPass.value,
                this.state.form.ftpPath.value
            ).then(res => {



                if (res.success) {

                    const newId = res.data.id;

                    this.setState({
                        loading: false,
                        websiteDatabaseId: newId
                    })

                    this.getWebsite(newId);

                    this.saveButton.statusSuccess();
                } else {
                    this.saveButton.statusReset();
                    this.notification.open('error', res.error);
                    this.setState({
                        loading: false
                    })
                }


            }).catch(err => {

                this.notification.open('error', err.message);
                this.setState({
                    loading: false
                })

            })

        } else {

            this.websiteUnit.update(
                this.state.websiteDatabaseId,
                this.state.form.title.value,
                this.state.form.url.value,
                this.state.form.gitUrl.value,
                this.state.form.gitClient.value,
                this.state.form.gitKey.value,
                this.state.form.ftpHost.value,
                this.state.form.ftpPort.value,
                this.state.form.ftpUser.value,
                this.state.form.ftpPass.value,
                this.state.form.ftpPath.value,
            ).then(res => {

                if (res.success) {

                    this.setState({
                        loading: false
                    })

                    this.saveButton.statusSuccess();
                    this.getWebsite(this.state.websiteDatabaseId);
                } else {
                    this.saveButton.statusReset();

                }

            }).catch(err => {

                this.setState({
                    loading: false
                })

            })

        }

    }

    callBuilder = (env, type, button) => {

        button.statusLoading();

        this.websiteUnit.build(
            this.state.form.websiteId.value,
            env, //'development' or production,
            type // 'full_build' or db_change
        ).then(res => {
            button.statusSuccess();
            wrlog('BUILDER', res)
            this.notification.open('success', 'Website successfully built');
        }).catch(err => {
            button.statusReset();
            wrlog('BUILDER ERROR', err)
            this.notification.open('error', 'Something went wrong building this website');
        })
    }

    render() {
        return (
            <>
                <Container fluid>
                    <Row>
                        <Col md={12}>
                            <Link className="go_back_button"
                                to={{
                                    pathname: '/websites',
                                    state: {
                                        currentState: this.state.currentParentState
                                    }
                                }}
                            >{t('back')}</Link>
                        </Col>
                    </Row>
                    <Row>
                        <Col md={12}>
                            <h1>{(this.state.form.title.value === '' && this.state.websiteDatabaseId === 'new' ? t('new_website') : this.state.form.title.value) + (this.state.websiteDatabaseId === 'new' ? ' ' + t('create') : ' ' + t('edit'))}</h1>
                            <a target="_blank" href={`https://testit.siegfriedframework.com/${this.state.form.websiteId.value}`}>{this.state.websiteDatabaseId !== 'new' ? `https://testit.siegfriedframework.com/${this.state.form.websiteId.value}` : ''}</a>
                        </Col>
                    </Row>
                    <Row>
                        <Col md={5}>
                            <Container fluid>
                                <Row>
                                    <Col md={12}>
                                        <FormControlLabel
                                            value={this.state.form.state.value}
                                            control={<CustomCheckbox style={{ fontWeight: 200 }} checked={this.state.form.state.value} />}
                                            label={t('active')}
                                            labelPlacement="end"
                                            onChange={(event) => this.changeValue('state', !this.state.form.state.value)}
                                            style={{ marginBottom: 10 }}
                                        />
                                    </Col>
                                </Row>
                                <Row>
                                    <Col md={12}>
                                        <TextInput
                                            onChangeValue={(val) => this.changeValue('title', val)}
                                            value={this.state.form.title.value}
                                            error={!this.state.form.title.valid && this.state.show_errors}
                                            placeholder={t('title')}
                                        />
                                        <PredictionInput
                                            options={this.state.projects}
                                            error={!this.state.form.projectId.valid && this.state.show_errors}
                                            onChangeValue={(val) => {

                                                wrlog(val)

                                                this.changeValue('projectId', val)

                                                let project = this.projectCache.get(val);

                                                if (this.state.form.title.value === '') {

                                                    this.changeValue('title', project.title)

                                                }

                                            }}
                                            value={this.state.form.projectId.value}
                                            placeholder={t('project')}
                                        />
                                        <TextInput
                                            onChangeValue={(val) => this.changeValue('websiteId', val)}
                                            error={!this.state.form.websiteId.valid && this.state.show_errors}
                                            value={this.state.form.websiteId.value}
                                            disabled={this.state.websiteDatabaseId !== 'new'}
                                            placeholder={t('website_id')}
                                        />
                                        <TextInput
                                            onChangeValue={(val) => this.changeValue('url', val)}
                                            error={!this.state.form.url.valid && this.state.show_errors}
                                            value={this.state.form.url.value}
                                            placeholder={t('url')}
                                        />
                                    </Col>
                                </Row>
                                <Row>
                                    <Col md={12}>
                                        <h3>Repository</h3>
                                        <TextInput
                                            onChangeValue={(val) => this.changeValue('gitUrl', val)}
                                            error={!this.state.form.gitUrl.valid && this.state.show_errors}
                                            value={this.state.form.gitUrl.value}
                                            placeholder={t('url')}
                                        />
                                        <TextInput
                                            onChangeValue={(val) => this.changeValue('gitClient', val)}
                                            error={!this.state.form.gitClient.valid && this.state.show_errors}
                                            value={this.state.form.gitClient.value}
                                            placeholder={t('consumer_client')}
                                        />
                                        <TextInput
                                            onChangeValue={(val) => this.changeValue('gitKey', val)}
                                            error={!this.state.form.gitKey.valid && this.state.show_errors}
                                            value={this.state.form.gitKey.value}
                                            placeholder={t('access_key')}
                                        />
                                    </Col>
                                </Row>

                                <Row>
                                    <Col md={12}>
                                        <h3>FTP</h3>
                                        <TextInput
                                            onChangeValue={(val) => this.changeValue('ftpHost', val)}
                                            error={!this.state.form.ftpHost.valid && this.state.show_errors}
                                            value={this.state.form.ftpHost.value}
                                            placeholder={t('ftp_host')}
                                        />
                                        <TextInput
                                            onChangeValue={(val) => this.changeValue('ftpPort', val)}
                                            error={!this.state.form.ftpPort.valid && this.state.show_errors}
                                            value={this.state.form.ftpPort.value}
                                            placeholder={t('ftp_port')}
                                        />
                                        <TextInput
                                            onChangeValue={(val) => this.changeValue('ftpUser', val)}
                                            error={!this.state.form.ftpUser.valid && this.state.show_errors}
                                            value={this.state.form.ftpUser.value}
                                            placeholder={t('ftp_user')}
                                        />
                                        <TextInput
                                            onChangeValue={(val) => this.changeValue('ftpPass', val)}
                                            error={!this.state.form.ftpPass.valid && this.state.show_errors}
                                            value={this.state.form.ftpPass.value}
                                            placeholder={t('ftp_pass')}
                                        />
                                        <TextInput
                                            onChangeValue={(val) => this.changeValue('ftpPath', val)}
                                            error={!this.state.form.ftpPath.valid && this.state.show_errors}
                                            value={this.state.form.ftpPath.value}
                                            placeholder={t('ftp_path')}
                                        />
                                    </Col>
                                </Row>
                            </Container>
                        </Col>
                        <Col md={2}></Col>
                        <Col md={5} style={{ backgroundColor: '#EFF7FA' }}>
                            <Button
                                ref={ref => this.dbBuildButton = ref}
                                onClick={() => this.callBuilder('development', 'db_change', this.dbBuildButton)}
                                label={"Refresh Content"}
                                style={{
                                    position: 'absolute',
                                    top: 0,
                                    right: 140
                                }}
                            />
                            <Button
                                ref={ref => this.buildButton = ref}
                                onClick={() => this.callBuilder('development', 'full_build', this.buildButton)}
                                label={"Build"}
                                style={{
                                    position: 'absolute',
                                    top: 0,
                                    right: 0
                                }}
                            />

                        </Col>
                    </Row>
                </Container>
                <Button
                    ref={ref => this.saveButton = ref}
                    onClick={this.save}
                    label={t('save')}
                    fixed
                />
                <Loading visible={this.state.loading} />
                <Notification ref={ref => this.notification = ref} text="Please fill all fields" type="error" />
            </>
        );
    }
}
const timetrackingTypes = [
    {
        id: 'work',
        label: 'Arbeitszeit',
        icon: 'suitcase.svg',
        fields: [
            'fromDate',
            'fromTime',
            'toTime',
        ],
        isWorktime : true,
        hasProject : true
    },
    {
        id: 'pause',
        label: 'Pause',
        icon: 'coffee-cup.svg',
        fields: [
            'fromDate',
            'fromTime',
            'toTime',
        ],
        isWorktime : false,
        hasProject : false
    },
    {
        id: 'business_trip', 
        label: 'Dienstreise',
        icon: 'luggage.svg',
        fields: [
            'fromDate',
            'fromTime',
            'toTime',
        ],
        isWorktime : true,
        hasProject : true
    },
    {
        id: 'sickleave',
        label: 'Krankenstand',
        icon: 'sick.svg',
        fields: [
            'fromDate',
            'toDate'
        ],
        isWorktime : false,
        hasProject : false
    },
    {
        id: 'time_compensation',
        label: 'Zeitausgleich',
        icon: 'stopwatch.svg',
        fields: [
            'fromDate',
            'fromTime',
            'toTime',
        ],
        isWorktime : true,
        hasProject : false
    },
    {
        id: 'vacation',
        label: 'Urlaub',
        icon: 'sunbed.svg',
        fields: [
            'fromDate',
            'toDate',
        ],
        isWorktime : false,
        hasProject : false
    },
];

export default timetrackingTypes;


export const getType = (id) => {

    let output = null;

    timetrackingTypes.map(type => {
        if (type.id === id) output = type;
    });

    return output;

}
/*
    Established connection to API
*/
import Cache from ".";

export default class CustomerCache extends Cache {

    /**
     * 
     * @param {String} endpoint
     */
    constructor() {

        super('customers', 'id');

    }

}
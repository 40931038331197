import { Table, TableBody, TableCell, TableRow, TextField } from '@material-ui/core';
import React from 'react';
import { Container, Col, Row } from 'react-bootstrap';
import Loading from '../../helper/screens/Loading';
import Notification from '../../helper/screens/Notification';
import { validation } from '../../helper/validation';

import TextInput from '../../helper/screens/TextInput';

import { Link } from 'react-router-dom';
import UserCache from '../../communication/locally/user';
import UserUnit from '../../communication/http/user';
import Switch from '../../helper/screens/Switch';
import { t } from '../../helper/multilingual/translate';


export default class Users extends React.Component {

    constructor() {

        super();

        this.state = {
            show_errors: false,
            users: [],
            loading: true,
            currentState: 'active'
        }

        this.userUnit = new UserUnit();
        this.userCache = new UserCache()
    }

    componentDidMount() {

        if (this.props.location.state !== undefined && this.props.location.state.currentState !== undefined) {
            this.setState({
                currentState: this.props.location.state.currentState
            })
        }

        this.props.set_current_page('/users');

        this.userUnit.list().then(res => {

            this.userCache.set(res.data);

            this.setState({
                users: res.data,
                loading: false
            })

        }).catch(err => {
            console.log(err)
        })

    }

    changeValue = (key, value) => {

        let formCopy = this.state.form;
        if (typeof value === 'object' && value.target !== undefined) {
            value = value.target.value;
        }

        formCopy[key].value = value;
        formCopy[key].valid = validation(value, formCopy[key].rules);

        this.setState({ form: formCopy })

    }


    render() {
        return (
            <>
                <Container fluid>
                    <Row>
                        <Col md={12}>
                            <h1>{t('team')}</h1>
                        </Col>
                    </Row>
                    <Row>
                        <Col md={12} style={{ paddingBottom: 50 }}>
                            <Switch
                                selected={this.state.currentState === 'all'}
                                title={t('all')}
                                onChange={() => this.setState({ currentState: 'all' })}
                            />
                            <Switch
                                selected={this.state.currentState === 'active'}
                                title={t('active')}
                                onChange={() => this.setState({ currentState: 'active' })}
                            />
                            <Switch
                                selected={this.state.currentState === 'inactive'}
                                title={t('inactive')}
                                onChange={() => this.setState({ currentState: 'inactive' })}
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col md={12}>
                            <Table>
                                <TableBody>
                                    {
                                        this.state.users.map((user, key) => {

                                            if (this.state.currentState === 'inactive' && user.state === 'active') return;
                                            if (this.state.currentState === 'active' && user.state !== 'active') return;

                                            return <TableRow key={key}>
                                                <TableCell>{user.firstname} {user.lastname}</TableCell>
                                                <TableCell style={{ width: 100 }}>
                                                    <Link
                                                        className="link"
                                                        to={{
                                                            pathname: '/users/' + user.id,
                                                            state: {
                                                                currentState: this.state.currentState
                                                            }
                                                        }}
                                                    >
                                                        {t('edit')}
                                                    </Link>
                                                </TableCell>
                                            </TableRow>

                                        })
                                    }
                                </TableBody>
                            </Table>
                        </Col>
                    </Row>
                </Container>
                <Link
                    className="button"
                    style={{ position: 'fixed', bottom: 20, right: 20 }}
                    to={'/users/new'}
                >
                    {t('new')}
                </Link>
                <Loading visible={this.state.loading} />
            </>
        );
    }
}
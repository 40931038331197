import { CircularProgress } from '@material-ui/core';
import Skeleton from '@material-ui/lab/Skeleton';
import React from 'react';
import Loading from './Loading';

/**
 * Render Loading Element
 */
export default class Button extends React.Component {

    constructor() {

        super();

        this.state = {
            status: '',
        }
    }

    statusSuccess = () => {
        this.setState({
            status: 'success'
        })

        setTimeout(() => this.setState({ status: '' }), 1000);

    }

    statusLoading = () => {
        this.setState({
            status: 'loading'
        })
    }

    statusReset = () => {
        this.setState({
            status: ''
        })
    }



    render() {

        let style = this.props.style || {};

        if (this.props.fixed !== undefined) {
            style = { position: 'fixed', bottom: 20, right: 20, ...style }
        }
        if (this.state.status === 'loading') {
            style = { paddingLeft: 50, ...style }
        }

        return <div
            className={"button " + this.state.status + ' ' + this.props.className}
            style={
                style
            }
            onClick={this.props.onClick}
        >
            {this.state.status === 'loading' ? <Loading
                relative
                visible={true}
                containerStyle={{
                    display: 'inline-block',
                    height: 20,
                    width: 20,
                    position: 'absolute',
                    left: 10,
                    verticalAlign: 'top',
                    marginRight: 10
                }}
                style={{
                    color: 'white !important',
                    width: 20,
                    height: 20
                }}
            /> : null}
            {this.props.label}</div>
    }
}
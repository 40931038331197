import { Table, TableBody, TableCell, TableRow, TextField } from '@material-ui/core';
import React from 'react';
import { Container, Col, Row } from 'react-bootstrap';
import Loading from '../../helper/screens/Loading';
import Notification from '../../helper/screens/Notification';
import { validation } from '../../helper/validation';

import TextInput from '../../helper/screens/TextInput';

import { Link } from 'react-router-dom';
import UserUnit from '../../communication/http/user';
import VacationUnit from '../../communication/http/vacation';
import VacationCache from '../../communication/locally/vacation';
import Switch from '../../helper/screens/Switch';
import { t } from '../../helper/multilingual/translate';
import Sidebar from '../../helper/screens/Sidebar';
import Button from '../../helper/screens/Button';
import { hasPermission } from '../../helper/functions/auth';
import { clone, formatDatetime, formatTimeFromDb, formatTimeToUnix, getCurrentUnix, wrlog } from '../../helper/misc';


export default class Vacations extends React.Component {

    constructor() {

        super();

        this.state = {
            show_errors: false,
            vacations: [],
            vacationCopy: {},
            userData: {},
            selectedVacation: null,
            loading: true,
            currentState: 'future',
        }

        this.vacationUnit = new VacationUnit();
        this.vacationCache = new VacationCache();

        this.userUnit = new UserUnit();
    }

    componentDidMount() {

        if (this.props.location.state !== undefined && this.props.location.state.currentState !== undefined) {

            this.setState({
                currentState: this.props.location.state.currentState
            })

        }

        this.props.set_current_page('/vacations');

        this.loadVacations();

    }

    loadVacations = () => {
        this.vacationUnit.list().then(res => {

            let vacations = res.data

            vacations.forEach((vacation, key) => {
                vacations[key].from = new Date(formatTimeToUnix(vacation.from));
                vacations[key].to = new Date(formatTimeToUnix(vacation.to));
            });

            this.vacationCache.set(vacations);

            this.setState({
                vacations: vacations,
                loading: false
            })

            if (this.saveButton !== undefined) {
                this.saveButton.statusSuccess();
            }

        }).catch(err => {
            console.log(err)
        });


        this.userUnit.getTime()
            .then(res => {

                this.setState({
                    userData: res.data
                })

            })

    }

    changeValue = (key, value) => {

        let formCopy = this.state.form;
        if (typeof value === 'object' && value.target !== undefined) {
            value = value.target.value;
        }

        formCopy[key].value = value;
        formCopy[key].valid = validation(value, formCopy[key].rules);

        this.setState({ form: formCopy })

    }

    openSidebar = (key) => {
        let vacationCopy = this.state.vacations[key];

        if (key === 'new') {

            key = this.state.vacations.length;

            vacationCopy = {
                from: null,
                to: null,
            }
        }

        this.setState({
            vacationCopy: vacationCopy,
            selectedVacation: key
        })

        this.sidebar.open();

    }

    changeVacationValue = (key, value) => {

        let vacationCopy = this.state.vacationCopy;
        vacationCopy[key] = value;

        this.setState({
            vacationCopy: vacationCopy
        })

    }

    save = () => {
        let vacationCopy = this.state.vacationCopy;

        this.saveButton.statusLoading();

        if (vacationCopy.id === undefined) {

            this.vacationUnit.create(vacationCopy.from, vacationCopy.to).then(res => {
                this.loadVacations()
            }).catch(err => wrlog("err", err))
        } else {
            this.vacationUnit.update(vacationCopy.id, vacationCopy.from, vacationCopy.to).then(res => {

                this.loadVacations()

            });
        }

    }

    delete = (key) => {

        let vacationId = this.state.vacations[key].id;

        this.vacationUnit.delete(vacationId).then(res => {

            this.loadVacations()

        });
    }


    renderSidebarContent = () => {

        let vacation = this.state.vacationCopy

        return <>
            <TextInput
                onChangeValue={(val) => this.changeVacationValue('from', val)}
                value={vacation.from}
                placeholder={t('from')}
                type="datetime"
            />
            <TextInput
                onChangeValue={(val) => this.changeVacationValue('to', val)}
                value={vacation.to}
                placeholder={t('to')}
                type="datetime"
            />
            <Button
                ref={ref => this.saveButton = ref}
                onClick={() => this.save()}
                label={t('save')}
                style={{
                    position: 'absolute',
                    right: 40,
                    bottom: 40
                }}
            />

        </>

    }


    render() {
        return (
            <>
                <Container fluid>
                    <Row>
                        <Col md={12}>
                            <h1>{t('vacations')}</h1>
                        </Col>
                    </Row>
                    <Row>
                        <Col md={12} style={{ paddingBottom: 50 }}>
                            <Switch
                                selected={this.state.currentState === 'all'}
                                title={t('all')}
                                onChange={() => this.setState({ currentState: 'all' })}
                            />
                            <Switch
                                selected={this.state.currentState === 'future'}
                                title={t('future')}
                                onChange={() => this.setState({ currentState: 'future' })}
                            />
                            <Switch
                                selected={this.state.currentState === 'history'}
                                title={t('history')}
                                onChange={() => this.setState({ currentState: 'history' })}
                            />
                            {
                                hasPermission('vacations.admin') ?
                                    <Link
                                        className="button"
                                        style={{}}
                                        to={'/vacations/admin'}
                                    >
                                        {t('admin')}
                                    </Link>
                                    :
                                    null
                            }
                        </Col>
                    </Row>
                    <Row>
                        <Col md={12}>
                            <Table>
                                <TableBody>
                                    {
                                        this.state.vacations.map((vacation, key) => {

                                            let time = formatTimeToUnix(vacation.to) >= getCurrentUnix() ? 'future' : 'history';

                                            if (this.state.currentState !== 'all' && this.state.currentState !== time) return;

                                            return <TableRow key={key}>
                                                <TableCell>{formatTimeFromDb(vacation.from).date} - {formatTimeFromDb(vacation.to).date}</TableCell>
                                                <TableCell>{t(vacation.state)}</TableCell>
                                                <TableCell style={{ width: 100 }}>
                                                    {vacation.state === 'open' ? <div
                                                        className="link"
                                                        onClick={() => {
                                                            this.openSidebar(key)
                                                        }}
                                                    >
                                                        {t('edit')}
                                                    </div> : null}
                                                </TableCell>
                                                <TableCell style={{ width: 100 }}>
                                                    {vacation.state === 'open' ? <div
                                                        className="link"
                                                        onClick={() => {
                                                            this.delete(key)
                                                        }}
                                                    >
                                                        {t('delete')}
                                                    </div> : null}
                                                </TableCell>
                                            </TableRow>

                                        })
                                    }
                                </TableBody>
                            </Table>
                        </Col>
                    </Row>
                    <Row>
                        <Col md={12}>
                            <strong>{t('used_vacation')}: {this.state.userData.used_vacation}</strong>
                            <strong style={{ marginLeft: 30 }}>{t('planned_vacation')}: {this.state.userData.planned_vacation}</strong>
                            <strong style={{ marginLeft: 30 }}>{t('open_vacation')}: {this.state.userData.open_vacation}</strong>
                        </Col>
                    </Row>
                </Container>



                <Sidebar ref={ref => this.sidebar = ref}>
                    {this.renderSidebarContent()}
                </Sidebar>
                <Button
                    ref={ref => this.openButton = ref}
                    onClick={() => this.openSidebar('new')}
                    label={t('new')}
                    fixed
                />

                <Loading visible={this.state.loading} />
            </>
        );
    }
}
import { MenuItem, Table, TableBody, TableCell, TableRow, TextField } from '@material-ui/core';
import React from 'react';
import { Container, Col, Row } from 'react-bootstrap';
import Loading from '../../helper/screens/Loading';
import Notification from '../../helper/screens/Notification';
import { validation } from '../../helper/validation';

import TextInput from '../../helper/screens/TextInput';

import { Link } from 'react-router-dom';
import UserUnit from '../../communication/http/user';
import Switch from '../../helper/screens/Switch';
import { t } from '../../helper/multilingual/translate';
import Sidebar from '../../helper/screens/Sidebar';
import Button from '../../helper/screens/Button';
import { hasPermission } from '../../helper/functions/auth';
import { clone, formatDatetime, formatTimeFromDb, formatTimeToUnix, getCurrentUnix, wrlog } from '../../helper/misc';
import ProjectUnit from '../../communication/http/project';
import ProjectCache from '../../communication/locally/project';

import Timeline from 'react-calendar-timeline'
import 'react-calendar-timeline/lib/Timeline.css'
import moment from 'moment-timezone'
import ResourcePlannerUnit from '../../communication/http/resourceplanner';

import { ReactComponent as ArrowLeft } from '../../assets/icons/arrow_left.svg';
import { ReactComponent as ArrowRight } from '../../assets/icons/arrow_right.svg';

import { v4 as uuidv4 } from 'uuid';

import PredictionInput from '../../helper/screens/PredictionInput';

const start = '';
const end = '';


export default class ResourcePlanner extends React.Component {



    constructor() {

        super();

        this.state = {
            show_errors: false,
            userData: {},
            selectedVacation: null,
            loading: true,
            currentState: 'future',
            team: [],
            showTimeline: true,
            timeslots: [],
            selectedItemId: null,
            visibleTimeStart: moment().add(-1, 'day'),
            visibleTimeEnd: moment().add(12, 'day'),
            form: {
                projectId: {
                    value: '',
                    valid: true
                },
                note: {
                    value: '',
                    valid: true
                }
            }
        }

        this.projectUnit = new ProjectUnit();
        this.projectCache = new ProjectCache();

        this.userUnit = new UserUnit();
        this.resourcePlannerUnit = new ResourcePlannerUnit();
    }

    componentDidMount() {

        if (this.props.location.state !== undefined && this.props.location.state.currentState !== undefined) {

            this.setState({
                currentState: this.props.location.state.currentState
            })

        }

        this.props.set_current_page('/resource-planner');

        this.loadResources();
        // this.loadTeam();

    }

    loadProjects = () => {
        this.projectUnit.list().then(res => {

            let projects = res.data

            wrlog("projects", projects)

            this.setState({
                projects: projects,
                loading: false
            })

        }).catch(err => {
            console.log(err)
        });

    }

    loadTeam = () => {
        this.userUnit.listActive().then(res => {

            let team = [];
            res.data.forEach(user => {
                team.push({
                    id: user.id,
                    title: `${user.firstname} ${user.lastname}`
                })
            })

            this.setState({
                team: team,
                loading: false
            })

        }).catch(err => {
            console.log(err)
        });

    }

    loadResources = async () => {

        let users_res = [];
        try {
            users_res = await this.userUnit.list();
        } catch (err) {
            console.log(err)
        }

        let project_res = [];
        try {
            project_res = await this.projectUnit.list();
        } catch (err) {
            console.log(err)
        }

        let team = [];
        users_res.data.forEach(user => {
            team.push({
                id: user.id,
                title: `${user.firstname} ${user.lastname}`
            })
        });

        let projects = {};
        project_res.data.forEach(project => {
            projects[project.id] = project.title;
        })

        this.setState({
            team: team,
            projects: projects,
            loading: false
        })

        let resources_res = await this.resourcePlannerUnit.list()
        let timeslots = [];

        resources_res.data.forEach(timeslot => {
            timeslots.push({
                id: timeslot.uid,
                group: timeslot.user_id,
                title: projects[timeslot.project_id] + (timeslot.note !== null && timeslot.note !== '' ? ' - ' + timeslot.note : ''),
                start_time: moment(timeslot.date_from),
                end_time: moment(timeslot.date_to),
                project_id: timeslot.project_id,
                note: timeslot.note
            });
        });

        if (timeslots.length < 1) {

            timeslots = [{
                id: 1,
                group: 3,
                title: 'TIME',
                start_time: moment().hours(0).minutes(0).seconds(0),
                end_time: moment().add(1, 'day').hours(0).minutes(0).seconds(0),
                project_id: null,
            }];

        }

        this.setState({
            timeslots: timeslots,
            team: team,
            loading: false
        })

    }

    changeProject = () => {

        this.saveButton.statusLoading();

        let currentTimeslot = {};
        let timeSlotCopy = this.state.timeslots;
        this.state.timeslots.forEach((timeslot, key) => {
            if (timeslot.id === this.state.selectedItemId) {

                currentTimeslot = timeslot;

                timeSlotCopy[key].project_id = this.state.form.projectId.value;
                timeSlotCopy[key].note = this.state.form.note.value;
                timeSlotCopy[key].title = this.state.projects[this.state.form.projectId.value] + (this.state.form.note.value !== '' ? ' - ' + this.state.form.note.value : '');

            }
        });

        this.setState({
            timeslots: timeSlotCopy
        });

        console.log(timeSlotCopy)


        this.resourcePlannerUnit.update(parseInt(currentTimeslot.group), this.state.form.projectId.value, currentTimeslot.start_time.format('YYYY-MM-DD'), currentTimeslot.end_time.format('YYYY-MM-DD'), this.state.form.note.value, currentTimeslot.id).then(res => {
            this.sidebar.close();
            this.saveButton.statusSuccess();
            this.reRender();
        }).catch(err => {

        });

    }

    handleMove = (itemId, dragTime, newGroupOrder) => {
        const { timeslots, team } = this.state;

        const teamMember = team[newGroupOrder];

        this.setState({
            timeslots: timeslots.map(timeslot =>
                timeslot.id === itemId
                    ? Object.assign({}, timeslot, {
                        start_time: moment(dragTime),
                        end_time: moment(dragTime + (timeslot.end_time - timeslot.start_time)),
                        group: teamMember.id
                    })
                    : timeslot
            )
        });

        let currentTimeslot = {};

        this.state.timeslots.forEach(timeslot => {
            if (timeslot.id === itemId) currentTimeslot = timeslot;
        });

        this.resourcePlannerUnit.update(parseInt(teamMember.id), currentTimeslot.project_id, currentTimeslot.start_time.format('YYYY-MM-DD'), currentTimeslot.end_time.format('YYYY-MM-DD'), currentTimeslot.note, itemId).then(res => {

        }).catch(err => {

        });

    }

    removeTimeslot = () => {

        this.resourcePlannerUnit.delete(this.state.selectedItemId).then(res => {

            let timeslotCopy = this.state.timeslots;

            this.state.timeslots.forEach((timeslot, key) => {
                if (timeslot.id === this.state.selectedItemId) timeslotCopy.splice(key, 1);
            });

            this.setState({
                timeslots: timeslotCopy,
                selectedItemId: null
            })

            this.sidebar.close();

            this.reRender();


        }).catch(err => {



        });

    }

    addTimeSlot = (groupId, time, e) => {

        const timeslotCopy = this.state.timeslots;
        const uuid = uuidv4();

        const start = moment(time).hours(0).minutes(0).seconds(0);
        const end = moment(time).add(1, 'day').hours(0).minutes(0).seconds(0);

        timeslotCopy.push({
            id: uuid,
            group: groupId,
            title: '',
            project_id: null,
            start_time: start,
            end_time: end,
            note: null
        });

        this.resourcePlannerUnit.create(groupId, null, start.format('YYYY-MM-DD'), end.format('YYYY-MM-DD'), null, uuid).then(res => {

            this.setState({
                selectedItemId: uuid
            })

            this.sidebar.open();

        }).catch(err => {

            console.log("RES", err)

        });

        this.setState({
            timeslots: timeslotCopy,
        })

        this.reRender()

    }

    handleResize = (itemId, time, edge) => {
        const { timeslots, team } = this.state;

        if (edge === 'left') {

            this.setState({
                timeslots: timeslots.map(timeslot =>
                    timeslot.id === itemId
                        ? Object.assign({}, timeslot, {
                            start_time: moment(time),
                        })
                        : timeslot
                )
            });

        } else {

            this.setState({
                timeslots: timeslots.map(timeslot =>
                    timeslot.id === itemId
                        ? Object.assign({}, timeslot, {
                            end_time: moment(time),
                        })
                        : timeslot
                )
            });

        }

        let currentTimeslot = {};

        this.state.timeslots.forEach(timeslot => {
            if (timeslot.id === itemId) currentTimeslot = timeslot;
        });

        console.log("CUR TIME", currentTimeslot)

        this.resourcePlannerUnit.update(parseInt(currentTimeslot.group), currentTimeslot.project_id, currentTimeslot.start_time.format('YYYY-MM-DD'), currentTimeslot.end_time.format('YYYY-MM-DD'), currentTimeslot.note, itemId).then(res => {

            console.log("RES", res)

        }).catch(err => {

            console.log("RES", err)

        });

    }

    timelineItem = ({
        item,
        itemContext,
        getItemProps,
        getResizeProps
    }) => {

        const { left: leftResizeProps, right: rightResizeProps } = getResizeProps()
        return (
            <div {...getItemProps(item.itemProps)} className={"timeline_item" + (this.state.selectedItemId === item.id ? ' selected' : '')}>
                {itemContext.useResizeHandle ? <ArrowLeft {...leftResizeProps} className="timeline_resize_handler" /> : ''}

                <div
                    className="timeline_item_content"
                    style={{ maxHeight: `${itemContext.dimensions.height}` }}
                >
                    {itemContext.title}
                </div>

                {itemContext.useResizeHandle ? <ArrowRight {...rightResizeProps} className="timeline_resize_handler" /> : ''}
            </div>
        )
    }

    groupRenderer = ({ group }) => {
        return (
            <div className="custom-group">
                <span className="title">{group.title}</span>
                {/* <p className="tip">{group.tip}</p> */}
            </div>
        )
    }

    selectItem = (itemId) => {

        if (this.state.selectedItemId == itemId) return;

        this.setState({
            selectedItemId: itemId
        })

        this.reRender()
    }

    deselectItem = () => {

        this.setState({
            selectedItemId: null
        })

        this.reRender()
    }

    reRender = () => {
        this.setState({
            showTimeline: false
        });

        setTimeout(() => {
            this.setState({
                showTimeline: true
            });
        }, 1)
    }

    changeValue = (key, val) => {

        const formCopy = this.state.form;
        formCopy[key].value = val;
        this.setState({
            form: formCopy
        })
    }

    renderSidebar = () => {

        if (this.state.projects === undefined || this.state.selectedItemId == null) return;

        let selectedTimeslot = {};

        this.state.timeslots.forEach(timeslot => {
            if (timeslot.id === this.state.selectedItemId) selectedTimeslot = timeslot;
        })

        let projectOptions = [];

        Object.keys(this.state.projects).forEach(projectKey => {
            projectOptions.push({
                id: projectKey,
                label: this.state.projects[projectKey]
            })
        });

        return <>
            <PredictionInput
                options={projectOptions}
                onChangeValue={(val) => {
                    this.changeValue('projectId', val)
                }}
                value={this.state.form.projectId.value}
                placeholder={t('project')}
            />

            <TextInput
                value={this.state.form.note.value}
                onChangeValue={(val) => this.changeValue('note', val)}
                placeholder={'note'}
                multiline
            />


            <Button
                ref={ref => this.deleteButton = ref}
                onClick={this.removeTimeslot}
                label={t('delete')}

                style={{
                    position: 'absolute',
                    left: 340,
                    bottom: 40,

                }}
            />

            <Button
                ref={ref => this.saveButton = ref}
                onClick={this.changeProject}
                label={t('save')}
                style={{
                    position: 'absolute',
                    right: 40,
                    bottom: 40
                }}
            />

        </>

    }



    onTimeChange = (visibleTimeStart, visibleTimeEnd, updateScrollCanvas) => {

        this.setState({
            visibleTimeStart: visibleTimeStart,
            visibleTimeEnd: visibleTimeEnd,
        })

        // updateScrollCanvas();

    }

    render() {
        return (
            <>
                <Container fluid>
                    <Row>
                        <Col md={12}>
                            <h1>{t('resource_planner')}</h1>
                        </Col>
                    </Row>
                    <Row>
                        <Col md={12} style={{ paddingBottom: 50 }}>
                            {/* <Switch
                                selected={this.state.currentState === 'all'}
                                title={t('all')}
                                onChange={() => this.setState({ currentState: 'all' })}
                            />
                            <Switch
                                selected={this.state.currentState === 'future'}
                                title={t('future')}
                                onChange={() => this.setState({ currentState: 'future' })}
                            />
                            <Switch
                                selected={this.state.currentState === 'history'}
                                title={t('history')}
                                onChange={() => this.setState({ currentState: 'history' })}
                            /> */}

                        </Col>
                    </Row>
                    <Row>
                        <Col md={12}>
                            {this.state.timeslots.length > 0 && this.state.team.length > 0 && this.state.showTimeline ?
                                <Timeline
                                    ref={ref => this.timeline = ref}
                                    groups={this.state.team}
                                    items={this.state.timeslots}
                                    defaultTimeStart={this.state.visibleTimeStart}
                                    defaultTimeEnd={this.state.visibleTimeEnd}
                                    visibleTimeStart={this.state.visibleTimeStart}
                                    visibleTimeEnd={this.state.visibleTimeEnd}
                                    canMove={true}
                                    canResize={"both"}
                                    fullUpdate
                                    itemTouchSendsClick={true}
                                    onCanvasDoubleClick={this.addTimeSlot}
                                    onItemDoubleClick={(itemId) => {

                                        console.log(this.sidebar);
                                        let currentTimeslot = {};
                                        let formCopy = this.state.form;

                                        this.state.timeslots.forEach(timeslot => {
                                            if (timeslot.id == itemId) {
                                                currentTimeslot = timeslot;

                                                formCopy.projectId.value = timeslot.project_id;
                                                formCopy.note.value = timeslot.note || '';

                                            }
                                        })

                                        currentTimeslot =



                                            this.setState({
                                                form: formCopy,
                                                selectedItemId: itemId
                                            });

                                        this.sidebar.open();

                                    }}
                                    stackItems
                                    useResizeHandle={true}
                                    onItemMove={this.handleMove}
                                    onItemResize={this.handleResize}
                                    dragSnap={moment.duration(1, 'days').asMilliseconds()}
                                    itemRenderer={this.timelineItem}
                                    groupRenderer={this.groupRenderer}
                                    sidebarWidth={200}
                                    onItemSelect={this.selectItem}
                                    onItemDeselect={this.deselectItem}
                                    onTimeChange={this.onTimeChange}
                                />
                                : null
                            }
                        </Col>
                    </Row>
                </Container>
                <Sidebar ref={ref => this.sidebar = ref}>
                    {
                        this.renderSidebar()
                    }
                </Sidebar>

                <Loading visible={this.state.loading} />
            </>
        );
    }
}
/*
    Established connection to API
*/
import Communicator from ".";
import { getUserToken } from "../../helper/functions/auth";
import { wrlog } from "../../helper/misc";

export default class ProjectUnit extends Communicator {

    /**
     * 
     * @param {String} endpoint
     */
    constructor() {

        super('');

    }

    list = async () => {
        
        return await this.post('list_projects', {
            token: getUserToken()
        });

    }

    get = async (id) => {

        return await this.post('project_get_detail', {
            project_id: id,
            token: getUserToken()
        });

    }

    create = async (title, customerId, state, sortOrder, estimatedHours, tasks, projectNumber) => {

        return await this.post('project_add', {
            token: getUserToken(),
            title: title,
            customer_id: customerId,
            state: state,
            sort_order: sortOrder,
            hours_estimated: estimatedHours,
            tasks: tasks,
            project_number: projectNumber
        });

    }

    update = async (projectId, title, customerId, state, sortOrder, estimatedHours, tasks, projectNumber) => {

        wrlog(tasks)

        return await this.post('project_update', {
            token: getUserToken(),
            project_id: projectId,
            title: title,
            customer_id: customerId,
            state: state,
            sort_order: sortOrder,
            hours_estimated: estimatedHours,
            tasks: tasks,
            project_number: projectNumber
        });

    }

    removeTask = async (taskId) => {

        return await this.post('task_remove', {
            token: getUserToken(),
            task_id: taskId
        });

    }

}
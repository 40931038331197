import React from 'react'
import "react-datetime/css/react-datetime.css";
import { formatDatetime, formatTimeFromDb, getCurrentTime, wrlog } from '../misc';

import Datetime from 'react-datetime';
import InputMask from 'react-input-mask';

export default class PredictionInput extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            focused: false,
            showForm: false,
            search: '',
            value: '',
            uid: '2'
        }

    }


    componentDidMount() {

        this.hideOnClickOutside()

        if (this.props.value !== '') {

            this.props.options.forEach(option => {

                if (option.id === this.props.value) {
                    this.setState({
                        search: option.label
                    })
                }

            })

        }

    }

    componentDidUpdate(prevProps) {

        if (this.props.options !== prevProps.options || this.props.value !== this.state.value) {

            this.props.options.forEach(option => {

                if (option.id === this.props.value) {

                    this.setState({
                        search: option.label,
                        value :  this.props.value
                    })

                }

            })

        }
    }

    onFocus = () => {

        this.setState({
            focused: true
        });

    }

    onBlur = () => {
        if (this.props.value !== '') {
            this.setState({
                focused: false
            });
        }
    }

    hideOnClickOutside = () => {

        const outsideClickListener = (event) => {

            let elem = event.target;

            let same = false
            for (; elem && elem !== document; elem = elem.parentNode) {

                if (elem == this.container) {
                    same = true
                }

            }


            if (!same) {
                this.setState({
                    focused: false
                });
            }

        }

        const removeClickListener = () => {
            document.removeEventListener('click', outsideClickListener)
        }

        document.addEventListener('click', outsideClickListener)

    }


    render() {

        let filtered = [];

        if (this.props.options !== undefined && this.props.options !== null && this.props.options.length > 0) {
            filtered = this.props.options.filter(value => {

                if (this.state.search === '') {
                    return true;
                }

                return value.label.toLowerCase().includes(this.state.search.toLowerCase());
            })
        }


        return <div
            ref={ref => this.container = ref}
            className={"input_container" + (this.state.focused ? ' focused' : '')}
            id={this.props.id | this.state.uid}
        >
            <label>
                <div className="input_container_label">{this.props.placeholder}</div>

                <input
                    className={"textfield"}
                    type="text"
                    onFocus={this.onFocus}
                    // onBlur={this.onBlur}
                    ref={ref => this.input = ref}
                    value={this.state.search}
                    onChange={(value) => {

                        this.setState({
                            search: value.target.value
                        });

                    }}
                />
            </label>
            {this.state.focused === true ?
                <div className="predictions_popup" style={{zIndex:99999999999999}}>
                    {
                        filtered.map(prediction => {

                            return <div
                                className="predictions_container"
                                onClick={() => {

                                    wrlog(prediction)

                                    this.props.onChangeValue(prediction.id);

                                    this.setState({
                                        search: prediction.label,
                                        focused: false
                                    })

                                }}
                            >
                                {prediction.label}
                            </div>

                        })
                    }
                </div>
                :
                null
            }

        </div>

    }

}
/*
    Established connection to API
*/

import Communicator from ".";
import { getUserToken } from "../../helper/functions/auth";
import { formatDateForDbSimple, formatDatetimeForDbSimple } from "../../helper/misc";

export default class TimeslotUnit extends Communicator {

    /**
     * 
     * @param {String} endpoint
     */
    constructor() {

        super('');

    }

    list = async () => {

        return await this.post('list_timeslots', {
            token: getUserToken(),
        });

    }

    get = async (timeslotId) => {

        return await this.post('timeslot_get', {
            token: getUserToken(),
            timeslot_id: timeslotId
        });

    }

    listUsersTodaysTimeslots = async (date) => {

        return await this.post('list_timeslots', {
            token: getUserToken(),
            filter: [
                'datetime_from >= "' + formatDateForDbSimple(date) + ' 00:00:00"',
                'datetime_to <= "' + formatDateForDbSimple(date) + ' 23:59:00"',
            ],
            only_user: true
        });

    }

    create = async (from, to, type, comment, projectId, taskId) => {

        return await this.post('timeslot_add', {
            token: getUserToken(),
            from: from,
            to: to,
            created_at: formatDatetimeForDbSimple(new Date()),
            type: type,
            comment: comment,
            project_id: projectId,
            task_id: taskId,
        });

    }

    update = async (timeslotId, from, to, type, comment, projectId, taskId) => {

        return await this.post('timeslot_update', {
            token: getUserToken(),
            timeslot_id: timeslotId,
            from: from,
            to: to,
            type: type,
            comment: comment,
            project_id: projectId,
            task_id: taskId,
        });

    }

    delete = async (timeslotId) => {

        return await this.post('timeslot_remove', {
            token: getUserToken(),
            timeslot_id: timeslotId,
        });

    }

}
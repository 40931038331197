import React from 'react';
import { Document, Page } from 'react-pdf';
import { pdfjs } from 'react-pdf';
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`;

/**
 * Render Popup Element
 */
export default class PdfPopup extends React.Component {

    constructor() {

        super();

        this.state = {
            visible: false,
        }
    }

    /**
     * Closes the popup
     */
    close = () => {
        this.setState({
            visible: false
        })
    }

    /**
     * opens the popup
     */
    open = () => {
        this.setState({
            visible: true
        })
    }


    onDocumentLoadSuccess = (pdf) => {
        this.setState({
            pdfNumPages: pdf.numPages
        })
    }

    renderPdfPages = () => {
        for (let i = 1; i <= this.state.pdfNumPages; i++) {
            return <Page
                width={840}
                pageNumber={i} />
        }
    }

    download = () => {

        let myURL = window.URL || window.webkitURL //window.webkitURL works in Chrome and window.URL works in Firefox
        let blob = this.b64toBlob(this.props.pdf);
        let downloadUrl = myURL.createObjectURL(blob);

        var fileLink = document.createElement('a');
        fileLink.href = downloadUrl;
        fileLink.target = '_blank';

        // it forces the name of the downloaded file
        fileLink.download = this.props.filename;

        // triggers the click event
        fileLink.click();
    }

    b64toBlob = (b64Data, contentType = 'application/pdf', sliceSize = 512) => {
        const byteCharacters = atob(b64Data);
        const byteArrays = [];

        for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
            const slice = byteCharacters.slice(offset, offset + sliceSize);

            const byteNumbers = new Array(slice.length);
            for (let i = 0; i < slice.length; i++) {
                byteNumbers[i] = slice.charCodeAt(i);
            }

            const byteArray = new Uint8Array(byteNumbers);
            byteArrays.push(byteArray);
        }

        const blob = new Blob(byteArrays, { type: contentType });
        return blob;
    }

    render() {
        if (this.state.visible) {

            return (
                <>
                    <div className="popup_close_button_overlay">
                        <div
                            className={"popup_container"}
                            style={this.props.style || {}}
                        >
                            <div
                                className="download_button icon_button"
                                style={{ top: 20, right: 70 }}
                                onClick={this.download}
                            >

                            </div>
                            {this.props.pdf !== null ?
                                <Document
                                    file={'data:application/pdf;base64,' + this.props.pdf}
                                    onLoadSuccess={this.onDocumentLoadSuccess}
                                >

                                    {
                                        this.renderPdfPages()
                                    }

                                </Document>

                                : null
                            }
                            <div className="popup_close_button icon_button" onClick={this.close}></div>
                        </div>
                    </div>
                </>
            );


        } else {
            return null;
        }

    }
}
import React from 'react';

/**
 * Render Popup Element
 */
export default class Popup extends React.Component {

    constructor(){

        super();

        this.state = {
            visible : false,
        }
    }

    /**
     * Closes the popup
     */
    close = () => {
        this.setState({
            visible : false
        })
    }

    /**
     * opens the popup
     */
    open = () => {
        this.setState({
            visible : true
        })
    }

    render() {
        if(this.state.visible){

            return (
                <>
                    <div className="popup_close_button_overlay">
                        <div
                        className={"popup_container"}
                        style={this.props.style || {}}
                        >
                            {this.props.children}
                            <div className="popup_close_button" onClick={this.close}></div>
                        </div>
                    </div>
                </>
            );

            
        }else{
            return null;
        }
        
    }
}
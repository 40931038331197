/*
    Established connection to API
*/

import Communicator from ".";
import { getUserToken } from "../../helper/functions/auth";
import { formatDatetimeForDbSimple } from "../../helper/misc";

export default class WebsiteUnit extends Communicator {

    /**
     * 
     * @param {String} endpoint
     */
    constructor() {

        super('');

    }

    list = async () => {

        return await this.post('list_websites', {
            token: getUserToken()
        });

    }

    // listAll = async () => {

    //     return await this.post('websites_', {
    //         token: getUserToken()
    //     });

    // }

    build = async (website_id, env, build_type) => {

        return await this.post('website_build', {
            token: getUserToken(),
            website_uid: website_id,
            env: env,
            build_type: build_type
        });
    }

    create = async (website_id, project_id, title, url, git_url, git_client_id, git_key, ftp_host, ftp_port, ftp_user, ftp_pass, ftp_path) => {

        return await this.post('website_create', {
            token: getUserToken(),
            website_id,
            project_id,
            title,
            url,
            git_url,
            git_client_id,
            git_key,
            ftp_host,
            ftp_port,
            ftp_user,
            ftp_pass,
            ftp_path
        });

    }

    update = async (id, title, url, git_url, git_client_id, git_key, ftp_host, ftp_port, ftp_user, ftp_pass, ftp_path) => {

        return await this.post('website_update', {
            token: getUserToken(),
            id,
            title,
            url,
            git_url,
            git_client_id,
            git_key,
            ftp_host,
            ftp_port,
            ftp_user,
            ftp_pass,
            ftp_path
        });

    }

    get = async (websiteId) => {
        return await this.post('website_get', {
            token: getUserToken(),
            id: websiteId
        });
    }

    // delete = async (vacationId) => {

    //     return await this.post('vacation_remove', {
    //         token: getUserToken(),
    //         vacation_id: vacationId
    //     });

    // }

}
/*
    Established connection to API
*/

import Communicator from ".";
import { getUserToken } from "../../helper/functions/auth";

export default class CustomerUnit extends Communicator {

    /**
     * 
     * @param {String} endpoint
     */
    constructor() {

        super('');

    }

    list = async (email, password) => {

        return await this.post('list_customers', {
            token: getUserToken()
        });

    }

    get = async (email, password) => {

        return await this.post('customer_get', {
            token: getUserToken()
        });

    }

    create = async (company, address, addressAdditional, zip, city, country, uid, gender, firstname, lastname, email, password) => {

        return await this.post('customer_add', {
            token: getUserToken(),
            company: company,
            address: address,
            address_additional: addressAdditional,
            zip: zip,
            city: city,
            country: country,
            uid: uid,
            gender: gender,
            firstname: firstname,
            lastname: lastname,
            email: email,
            password: password
        });

    }

    update = async (customerId, company, address, addressAdditional, zip, city, country, uid, gender, firstname, lastname, email, password) => {

        return await this.post('customer_update', {
            token: getUserToken(),
            customer_id: customerId,
            company: company,
            address: address,
            address_additional: addressAdditional,
            zip: zip,
            city: city,
            country: country,
            uid: uid,
            gender: gender,
            firstname: firstname,
            lastname: lastname,
            email: email,
            password: password
        });

    }

}
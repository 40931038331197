import { CircularProgress } from '@material-ui/core';
import Skeleton from '@material-ui/lab/Skeleton';
import React from 'react';

/**
 * Render Loading Element
 */
export default class Loading extends React.Component {

    constructor() {

        super();

        this.state = {
            visible: false,
        }
    }

    render() {
        if (this.props.visible) {

            if (this.props.skeleton !== undefined) {

                if (this.props.skeleton === 'table') {

                }

                return <Skeleton animation="wave" />
            } else {
                return (
                    <div className={"loading_container " + (this.props.relative !== undefined ? 'relative' : '')} style={this.props.containerStyle !== undefined ? this.props.containerStyle : {}}>
                        <CircularProgress className="loadingSpinner" style={this.props.style !== undefined ? this.props.style : {}} />
                    </div>
                );
            }


        } else {
            return null;
        }

    }
}
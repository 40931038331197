import { Table, TableBody, TableCell, TableRow, TextField } from '@material-ui/core';
import React from 'react';
import { Container, Col, Row } from 'react-bootstrap';
import Loading from '../../helper/screens/Loading';
import Notification from '../../helper/screens/Notification';
import { validation } from '../../helper/validation';

import TextInput from '../../helper/screens/TextInput';

import ProjectUnit from '../../communication/http/project'
import { Link } from 'react-router-dom';
import CustomerCache from '../../communication/locally/customer';
import CustomerUnit from '../../communication/http/customer';
import { t } from '../../helper/multilingual/translate';


export default class Customers extends React.Component {

    constructor() {

        super();

        this.state = {
            show_errors: false,
            loading: true,
            customers: [],
            form: {
                search: {
                    valid: true,
                    value: '',
                    rules: {
                    }
                }
            }
        }

        this.customerUnit = new CustomerUnit();
        this.customerCache = new CustomerCache();
    }

    componentDidMount() {

        this.props.set_current_page('/customers');

        let cached = this.customerCache.list();

        if (cached === null) {
            this.setState({
                customers: cached,
                loading: false
            })
        }


        this.customerUnit.list().then(res => {

            this.customerCache.set(res.data);

            this.setState({
                customers: res.data,
                loading: false
            })

        }).catch(err => {
            console.log(err)
        })

    }

    changeValue = (key, value) => {

        let formCopy = this.state.form;
        if (typeof value === 'object' && value.target !== undefined) {
            value = value.target.value;
        }

        formCopy[key].value = value;
        formCopy[key].valid = validation(value, formCopy[key].rules);

        this.setState({ form: formCopy })

    }


    render() {
        return (
            <>
                <Container fluid>
                    <Row>
                        <Col md={12}>
                            <h1>{t('customers')}</h1>
                        </Col>
                    </Row>
                    <Row>
                        <Col md={5}>
                            <TextInput
                                onChangeValue={(val) => this.changeValue('search', val)}
                                value={this.state.form.search.value}
                                placeholder={t('search')}
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col md={12}>
                            <Table>
                                <TableBody>
                                    {
                                        this.state.customers.map((customer, key) => {

                                            if (this.state.form.search.value !== '' && !customer.company.toLowerCase().includes(this.state.form.search.value.toLowerCase())) return;

                                            return <TableRow key={key}>
                                                <TableCell>{customer.company}</TableCell>
                                                <TableCell style={{ width: 100 }}>
                                                    <Link className="link" to={'/customers/' + customer.id}>
                                                        {t('edit')}
                                                    </Link>
                                                </TableCell>
                                            </TableRow>

                                        })
                                    }
                                </TableBody>
                            </Table>
                        </Col>
                    </Row>
                </Container>
                <Link
                    className="button"
                    style={{ position: 'fixed', bottom: 20, right: 20 }}
                    to={'/customers/new'}
                >
                    {t('new')}
                </Link>
                <Loading visible={this.state.loading} />
            </>
        );
    }
}
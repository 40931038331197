import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';

import './App.css';

import {
    BrowserRouter as Router,
    Switch,
    Route,
    Link
} from "react-router-dom";
import Login from './screens/Login';
import { hasPermission, isLoggedIn } from './helper/functions/auth';
import Loading from './helper/screens/Loading';
import NotFound from './screens/NotFound';

import { BASENAME } from './store';
import Timemachine from './screens/Timemachine';
import Projects from './screens/Projects';
import Project from './screens/Projects/Detail';
import Customers from './screens/Customers';
import Customer from './screens/Customers/Detail';
import Reports from './screens/Reports';
import Users from './screens/User';
import UserDetail from './screens/User/Detail';
import Passwords from './screens/Passwords';
import Password from './screens/Passwords/Detail';
import Invoices from './screens/Invoices';
import Invoice from './screens/Invoices/Detail';
import { wrlog } from './helper/misc';
import { setBrowserLanguage, setTranslationConfig, t } from './helper/multilingual/translate';
import Vacations from './screens/Vacation';
import VacationsAdmin from './screens/Vacation/Admin';
import Websites from './screens/Websites';
import WebsiteDetail from './screens/Websites/Detail.js';
import ResourcePlanner from './screens/ResourcePlanner';


const count = 0;

export default class App extends React.Component {

    constructor() {
        super();

        this.state = {
            loading: false,
            show_page: true,
            current_page: '/',
            nav_open: false,
            tokenRefreshing: false
        }

        this.navRef = React.createRef()
        this.navRefBurger = React.createRef()
        setBrowserLanguage();

    }

    componentDidMount() {
        wrlog('STATE', this.state)
    }

    toggle_mobile_nav = (state = null) => {
        if (state === null) {
            this.navRef.current.classList.toggle('open')
            this.navRefBurger.current.classList.toggle('open')
        } else {
            if (state === false) {
                this.navRefBurger.current.classList.remove('open')
                this.navRef.current.classList.remove('open')
            } else {
                this.navRefBurger.current.classList.add('open')
                this.navRef.current.classList.add('open')
            }
        }

    }

    logout = () => {

        this.setState({ loading: true });

        sessionStorage.removeItem('token');
        this.setState({ loading: false });

    }

    set_current_page = (path) => {

        window.scrollTo(0, 0)

        this.setState({
            current_page: path
        });
    }

    render() {


        if (isLoggedIn()) {

            return (
                <Router basename={BASENAME}>
                    <Loading visible={this.state.loading} />


                    {this.state.show_page ?
                        <div className="">
                            <div className="burger_left" ref={this.navRefBurger} onClick={
                                () => {
                                    this.toggle_mobile_nav()
                                }
                            }>
                                <div className="burder_line"></div>
                                <div className="burder_line"></div>
                                <div className="burder_line"></div>
                            </div>

                            <div className="sidebar" ref={this.navRef}>
                                <nav>
                                    <ul>
                                        {hasPermission('timetracking') ? <li className={this.state.current_page === '/' ? "current_page" : ''}>
                                            <Link onClick={() => this.toggle_mobile_nav(false)} to="/">{t('timetracking')}</Link>
                                        </li>
                                            : null}

                                        {hasPermission('reports.all') || hasPermission('timetracking') ? <li className={this.state.current_page === '/reports' ? "current_page" : ''}>
                                            <Link onClick={() => this.toggle_mobile_nav(false)} to="/reports">{t('reports')}</Link>
                                        </li> : null}

                                        {hasPermission('projects.create') ? <li className={this.state.current_page === '/projects' ? "current_page" : ''}>
                                            <Link onClick={() => this.toggle_mobile_nav(false)} to="/projects">{t('projects')}</Link>
                                        </li> : null}


                                        {hasPermission('customers.create') ? <li className={this.state.current_page === '/customers' ? "current_page" : ''}>
                                            <Link onClick={() => this.toggle_mobile_nav(false)} to="/customers">{t('customers')}</Link>
                                        </li> : null}


                                        {hasPermission('user.create') ? <li className={this.state.current_page === '/users' ? "current_page" : ''}>
                                            <Link onClick={() => this.toggle_mobile_nav(false)} to="/users">{t('team')}</Link>
                                        </li> : null}

                                        {hasPermission('invoices.list') ? <li className={this.state.current_page === '/invoices' ? "current_page" : ''}>
                                            <Link onClick={() => this.toggle_mobile_nav(false)} to="/invoices">{t('invoices')}</Link>
                                        </li> : null}

                                        {hasPermission('vacations.create') ? <li className={this.state.current_page === '/vacations' ? "current_page" : ''}>
                                            <Link onClick={() => this.toggle_mobile_nav(false)} to="/vacations">{t('vacations')}</Link>
                                        </li> : null}

                                        {hasPermission('passwords.list') || hasPermission('passwords.create') ? <li className={this.state.current_page === '/passwords' ? "current_page" : ''}>
                                            <Link onClick={() => this.toggle_mobile_nav(false)} to="/passwords">{t('passwords')}</Link>
                                        </li> : null}

                                        {hasPermission('websites.list') ? <li className={this.state.current_page === '/websites' ? "current_page" : ''}>
                                            <Link onClick={() => this.toggle_mobile_nav(false)} to="/websites">{t('websites')}</Link>
                                        </li> : null}

                                        {hasPermission('websites.list') ? <li className={this.state.current_page === '/resource-planner' ? "current_page" : ''}>
                                            <Link onClick={() => this.toggle_mobile_nav(false)} to="/resource-planner">{t('resource_planner')}</Link>
                                        </li> : null}

                                        <li style={{ marginTop: 50 }} >
                                            <div style={{ cursor: 'pointer', fontWeight: 300 }} onClick={() => {
                                                this.toggle_mobile_nav(false)
                                                this.logout();
                                            }}>{t('logout')}</div>
                                        </li>
                                    </ul>
                                </nav>
                            </div>
                            <div className="content_wrapper">
                                <Switch>

                                    {
                                        hasPermission('timetracking') ?
                                            <Route exact path='/' render={(props) => {
                                                return (<Timemachine {...props} set_current_page={this.set_current_page} />)
                                            }} />
                                            : null
                                    }

                                    {
                                        hasPermission('timetracking') ?
                                            <Route exact path='/timetracking/:id' render={(props) => {
                                                return (<Timemachine {...props} set_current_page={this.set_current_page} />)
                                            }} />
                                            : null
                                    }
                                    {
                                        hasPermission('projects.create') ?
                                            <Route exact path='/projects' render={(props) => {
                                                return (<Projects {...props} set_current_page={this.set_current_page} />)
                                            }} />
                                            : null
                                    }
                                    {
                                        hasPermission('projects.create') ?
                                            <Route exact path='/projects/:id' render={(props) => {
                                                return (<Project {...props} set_current_page={this.set_current_page} />)
                                            }} />
                                            : null
                                    }
                                    {
                                        hasPermission('reports.all') ?
                                            <Route exact path='/reports' render={(props) => {
                                                return (<Reports {...props} set_current_page={this.set_current_page} />)
                                            }} />
                                            : null
                                    }
                                    {
                                        hasPermission('customers.create') ?
                                            <Route exact path='/customers' render={(props) => {
                                                return (<Customers {...props} set_current_page={this.set_current_page} />)
                                            }} />
                                            : null
                                    }
                                    {
                                        hasPermission('customers.create') ?
                                            <Route exact path='/customers/:id' render={(props) => {
                                                return (<Customer {...props} set_current_page={this.set_current_page} />)
                                            }} />

                                            : null
                                    }
                                    {
                                        hasPermission('user.create') ?
                                            <Route exact path='/users' render={(props) => {
                                                return (<Users {...props} set_current_page={this.set_current_page} />)
                                            }} />
                                            : null
                                    }
                                    {
                                        hasPermission('user.create') ?
                                            <Route exact path='/users/:id' render={(props) => {
                                                return (<UserDetail {...props} set_current_page={this.set_current_page} />)
                                            }} />
                                            : null
                                    }
                                    {
                                        hasPermission('passwords.list') || hasPermission('passwords.create') ?
                                            <Route exact path='/passwords' render={(props) => {
                                                return (<Passwords {...props} set_current_page={this.set_current_page} />)
                                            }} />
                                            : null
                                    }
                                    {
                                        hasPermission('passwords.create') ?
                                            <Route exact path='/passwords/:id' render={(props) => {
                                                return (<Password {...props} set_current_page={this.set_current_page} />)
                                            }} />
                                            : null
                                    }

                                    {
                                        hasPermission('invoices.list') ?
                                            <Route exact path='/invoices' render={(props) => {
                                                return (<Invoices {...props} set_current_page={this.set_current_page} />)
                                            }} />
                                            : null
                                    }

                                    {
                                        hasPermission('invoices.list') ?
                                            <Route exact path='/invoices/:id' render={(props) => {
                                                return (<Invoice {...props} set_current_page={this.set_current_page} />)
                                            }} />
                                            : null
                                    }

                                    {
                                        hasPermission('vacations.create') ?
                                            <Route exact path='/vacations' render={(props) => {
                                                return (<Vacations {...props} set_current_page={this.set_current_page} />)
                                            }} />
                                            : null
                                    }
                                    {
                                        hasPermission('vacations.admin') ?
                                            <Route exact path='/vacations/admin' render={(props) => {
                                                return (<VacationsAdmin {...props} set_current_page={this.set_current_page} />)
                                            }} />
                                            : null
                                    }

                                    {
                                        hasPermission('websites.list') ?
                                            <Route exact path='/websites' render={(props) => {
                                                return (<Websites {...props} set_current_page={this.set_current_page} />)
                                            }} />
                                            : null
                                    }
                                    {
                                        hasPermission('websites.create') || hasPermission('websites.update') ?
                                            <Route exact path='/websites/:id' render={(props) => {
                                                return (<WebsiteDetail {...props} set_current_page={this.set_current_page} />)
                                            }} />
                                            : null
                                    }
                                    {
                                        hasPermission('websites.create') || hasPermission('websites.update') ?
                                            <Route exact path='/resource-planner' render={(props) => {
                                                return (<ResourcePlanner {...props} set_current_page={this.set_current_page} />)
                                            }} />
                                            : null
                                    }
                                    <Route component={NotFound} />

                                </Switch>
                            </div>
                        </div> : null}
                </Router>
            );

        } else {

            return <Login />

        }
    }
}

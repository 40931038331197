import { FormControlLabel, Table, TableBody, TableCell, TableRow, TextField } from '@material-ui/core';
import React from 'react';
import { Container, Col, Row } from 'react-bootstrap';
import Loading from '../../../helper/screens/Loading';
import Notification from '../../../helper/screens/Notification';
import { validation } from '../../../helper/validation';

import TextInput from '../../../helper/screens/TextInput';

import ProjectUnit from '../../../communication/http/project'
import { Link } from 'react-router-dom';
import ProjectCache from '../../../communication/locally/project';
import { wrlog } from '../../../helper/misc';
import Button from '../../../helper/screens/Button';
import PredictionInput from '../../../helper/screens/PredictionInput';
import CustomerUnit from '../../../communication/http/customer';
import CustomerCache from '../../../communication/locally/customer';
import CustomCheckbox from '../../../helper/screens/CustomCheckbox';
import { t } from '../../../helper/multilingual/translate';


export default class Project extends React.Component {

    constructor() {

        super();

        this.state = {
            show_errors: false,
            project: {
                tasks: []
            },
            customers: [],
            selected_task: null,
            selected_subtask: null,
            projectId: null,
            buttonStatus: null,
            form: {
                state: {
                    valid: true,
                    value: 'active',
                    rules: {
                    }
                },
                title: {
                    valid: true,
                    value: '',
                    rules: {
                    }
                },
                deadline: {
                    valid: true,
                    value: new Date(),
                    rules: {
                    }
                },
                customerId: {
                    valid: true,
                    value: null,
                    rules: {
                    }
                },
                projectNumber: {
                    valid: true,
                    value: '',
                    rules: {
                    }
                },
                tasks: {
                    valid: true,
                    value: [],
                    rules: {
                    }
                },
            }
        }

        this.projectUnit = new ProjectUnit();
        this.projectCache = new ProjectCache();

        this.customerUnit = new CustomerUnit();
        this.customerCache = new CustomerCache();

    }

    componentDidMount() {

        this.props.set_current_page('/projects');

        this.setState({
            projectId: this.props.match.params.id
        })

        if (this.props.match.params.id === 'new') {

            this.setState({
                loading: false
            })

        } else {
            let project = this.projectCache.get(this.props.match.params.id)

            if (project !== null) {

                this.setProject(project)

            } else {

                this.setState({
                    loading: true
                })
                this.loadProjects();

            }
        }

        this.customerUnit.list().then(res => {

            let customers = [];

            res.data.map((customer) => {
                customers.push({
                    id: customer.id,
                    label: customer.company,
                })
            })

            this.setState({ customers: customers })

        }).catch(err => {
            console.log(err)
        })




    }

    loadProjects = () => {
        this.projectUnit.list().then(res => {

            this.projectCache.set(res.data);

            let project = this.projectCache.get(this.props.match.params.id)
            this.setState({
                loading: false
            })
            this.setProject(project)


        }).catch(err => {
            console.log(err)
        })
    }

    setProject = (project) => {

        let formCopy = this.state.form;

        formCopy.title.value = project.title;
        formCopy.customerId.value = project.customer_id;
        formCopy.state.value = project.state === 'active';
        formCopy.projectNumber.value = project.project_number;

        this.setState({
            project: project,
            form: formCopy
        })
    }

    changeValue = (key, value) => {

        let formCopy = this.state.form;
        if (typeof value === 'object' && value.target !== undefined) {
            value = value.target.value;
        }

        formCopy[key].value = value;
        formCopy[key].valid = validation(value, formCopy[key].rules);

        this.setState({ form: formCopy })

    }

    changeTaskValue = (key, value) => {

        let projectCopy = this.state.project;


        if (this.state.selected_subtask !== null) {
            projectCopy.tasks[this.state.selected_task].tasks[this.state.selected_subtask][key] = value;
        } else {
            projectCopy.tasks[this.state.selected_task][key] = value;
        }


        this.setState({ project: projectCopy })

    }

    save = () => {

        this.setState({
            loading: true
        })

        this.saveButton.statusLoading();

        if (this.state.projectId === 'new') {

            this.projectUnit.create(this.state.form.title.value, this.state.form.customerId.value, (this.state.form.state.value ? 'active' : 'inactive'), 1, -1, this.state.project.tasks, this.state.form.projectNumber.value).then(res => {

                if (res.success) {
                    this.loadProjects();

                    const newId = res.project_id;

                    this.setState({
                        loading: false,
                        projectId: newId
                    })

                    this.saveButton.statusSuccess();
                } else {
                    this.saveButton.statusReset();

                }

            }).catch(err => {

                this.setState({
                    loading: false
                })

            })

        } else {

            this.projectUnit.update(this.state.projectId, this.state.form.title.value, this.state.form.customerId.value, (this.state.form.state.value ? 'active' : 'inactive'), 1, -1, this.state.project.tasks, this.state.form.projectNumber.value).then(res => {

                if (res.success) {

                    this.loadProjects();

                    this.setState({
                        loading: false
                    })

                    this.saveButton.statusSuccess();

                } else {
                    this.saveButton.statusReset();

                }

            }).catch(err => {

                this.setState({
                    loading: false
                })

            })

        }

    }

    render_task_sidebar = () => {

        if (this.state.selected_task !== null) {

            let task = this.state.project.tasks[this.state.selected_task]

            if (this.state.selected_subtask !== null) {
                task = this.state.project.tasks[this.state.selected_task].tasks[this.state.selected_subtask]
            }

            return <Col md={6} className="task_sidebar" >
                <TextInput
                    onChangeValue={(val) => this.changeTaskValue('title', val)}
                    value={task.title}
                    placeholder={t('title')}
                />
                <TextInput
                    onChangeValue={(val) => this.changeTaskValue('estimated_hours', val)}
                    value={task.estimated_hours}
                    placeholder={t('estimated_hours')}
                />
                <TextInput
                    onChangeValue={(val) => this.changeTaskValue('title', val)}
                    value={task.title}
                    placeholder={t('title')}
                />
                <div
                    className="button"
                    style={{ position: 'absolute', bottom: 20, right: 20 }}
                    onClick={
                        () => {

                            this.setState({
                                selected_task: null,
                                selected_subtask: null,
                            })

                        }
                    }
                >{t('apply')}</div>

            </Col>

        }


    }


    render() {
        return (
            <>
                <Container fluid>
                    <Row>
                        <Col md={12}>
                            <Link className="go_back_button" to="/projects">{t('back')}</Link>
                        </Col>
                    </Row>
                    <Row>
                        <Col md={12}>
                            <h1>{(this.state.form.title.value === '' && this.state.projectId === 'new' ? t('new_project') : this.state.form.title.value) + (this.state.projectId === 'new' ? ' ' + t('create') : ' ' + t('edit'))}</h1>
                        </Col>
                    </Row>
                    <Row>
                        <Col md={12}>
                            <FormControlLabel
                                value={this.state.form.state.value}
                                control={<CustomCheckbox style={{ fontWeight: 200 }} checked={this.state.form.state.value} />}
                                label={t('active')}
                                labelPlacement="end"
                                onChange={(event) => this.changeValue('state', !this.state.form.state.value)}
                                style={{ marginBottom: 10 }}
                            />

                        </Col>
                    </Row>
                    <Row>
                        <Col md={6}>
                            <TextInput
                                onChangeValue={(val) => this.changeValue('title', val)}
                                value={this.state.form.title.value}
                                placeholder={t('title')}
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col md={6}>
                            <PredictionInput
                                options={this.state.customers}
                                onChangeValue={(val) => this.changeValue('customerId', val)}
                                value={this.state.form.customerId.value}
                                placeholder={t('customer')}
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col md={6}>
                        <TextInput
                                onChangeValue={(val) => this.changeValue('projectNumber', val)}
                                value={this.state.form.projectNumber.value}
                                placeholder={t('projectNumber')}
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col md={6}>
                            <div style={{ marginBottom: 50 }}>
                                <strong>{t('tasks')}</strong>
                                <div className="add_button"
                                    onClick={() => {

                                        let projectCopy = this.state.project;

                                        console.log(projectCopy);

                                        const newId = projectCopy.tasks.length;

                                        projectCopy.tasks.push(
                                            {
                                                title: '',
                                                estimated_hours: null,
                                                sort_order: (projectCopy.tasks.length),
                                                state: 'active',
                                                level: 0,
                                                parent_id: null,
                                                tasks: []
                                            }
                                        )

                                        this.setState({
                                            project: projectCopy,
                                            selected_task: newId,
                                            selected_subtask: null,
                                        })


                                    }}
                                ></div>
                            </div>
                            {
                                this.state.project.tasks !== undefined ? this.state.project.tasks.map((task, taskkey) => {

                                    return <div className="task_wrapper" key={taskkey}>
                                        <div
                                            className="task_container"
                                        >
                                            <div className="edit_button"
                                                onClick={() => this.setState({
                                                    selected_task: taskkey,
                                                    selected_subtask: null,
                                                })}
                                            ></div>
                                            <div className="add_button"
                                                onClick={() => {

                                                    let projectCopy = this.state.project;

                                                    const newId = projectCopy.tasks[taskkey].tasks.length;

                                                    projectCopy.tasks[taskkey].tasks.push(
                                                        {
                                                            title: '',
                                                            estimated_hours: 0,
                                                            sort_order: (taskkey + 1),
                                                            state: 'active',
                                                            level: 1,
                                                            parent_id: taskkey,
                                                        }
                                                    )

                                                    this.setState({
                                                        project: projectCopy,
                                                        selected_task: taskkey,
                                                        selected_subtask: newId,
                                                    })


                                                }}
                                            ></div>
                                            <strong>{task.title}</strong>
                                        </div>
                                        <div className="task_container_subtask_wrapper">
                                            {
                                                task !== undefined ? task.tasks.map((subtask, subtaskkey) => {
                                                    return <div
                                                        key={subtask.id}
                                                        className={"task_container_subtask" + " " + subtask.state}
                                                    >
                                                        {subtask.title}

                                                        <div className="edit_button"
                                                            onClick={() => this.setState({
                                                                selected_task: taskkey,
                                                                selected_subtask: subtaskkey,
                                                            })}
                                                        ></div>
                                                    </div>

                                                }) : null
                                            }
                                        </div>
                                    </div>

                                })
                                    : null
                            }
                        </Col>


                        {
                            this.render_task_sidebar()
                        }

                    </Row>
                </Container>
                <Button
                    ref={ref => this.saveButton = ref}
                    onClick={this.save}
                    label={t('save')}
                    fixed
                />
                <Loading visible={this.state.loading} />
            </>
        );
    }
}
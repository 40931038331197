/*
    Established connection to API
*/

import Communicator from ".";
import { getUserToken } from "../../helper/functions/auth";
import { formatDatetimeForDbSimple } from "../../helper/misc";

export default class VacationUnit extends Communicator {

    /**
     * 
     * @param {String} endpoint
     */
    constructor() {

        super('');

    }

    list = async () => {

        return await this.post('list_my_vacation', {
            token: getUserToken()
        });

    }

    listAll = async () => {

        return await this.post('list_vacations', {
            token: getUserToken()
        });

    }

    create = async (from, to) => {

        return await this.post('vacation_add', {
            token: getUserToken(),
            from: formatDatetimeForDbSimple(from),
            to: formatDatetimeForDbSimple(to),
        });

    }

    accept = async (vacationId) => {

        return await this.post('vacation_accept', {
            token: getUserToken(),
            vacation_id: vacationId,
        });


    }

    reject = async (vacationId) => {

        return await this.post('vacation_reject', {
            token: getUserToken(),
            vacation_id: vacationId,
        });

    }

    update = async (vacationId, from, to) => {

        return await this.post('vacation_update', {
            token: getUserToken(),
            vacation_id: vacationId,
            from: formatDatetimeForDbSimple(from),
            to: formatDatetimeForDbSimple(to)
        });

    }

    delete = async (vacationId) => {

        return await this.post('vacation_remove', {
            token: getUserToken(),
            vacation_id: vacationId
        });

    }

}
import { FormControl, MenuItem, Select, Table, TableBody, TableCell, TableRow, TextField } from '@material-ui/core';
import React from 'react';
import { Container, Col, Row } from 'react-bootstrap';
import Loading from '../../../helper/screens/Loading';
import Notification from '../../../helper/screens/Notification';
import { validation } from '../../../helper/validation';

import TextInput from '../../../helper/screens/TextInput';

import customerUnit from '../../../communication/http/customer'
import { Link } from 'react-router-dom';
import customerCache from '../../../communication/locally/customer';
import { wrlog } from '../../../helper/misc';
import Button from '../../../helper/screens/Button';
import { t } from '../../../helper/multilingual/translate';


export default class Customer extends React.Component {

    constructor() {

        super();

        this.state = {
            show_errors: false,
            customer: {
                tasks: []
            },
            customer: {},
            customerId: null,
            buttonStatus: null,
            form: {
                company: {
                    valid: true,
                    value: '',
                    rules: {
                    }
                },
                address: {
                    valid: true,
                    value: '',
                    rules: {
                    }
                },
                addressAdditional: {
                    valid: true,
                    value: '',
                    rules: {
                    }
                },
                zip: {
                    valid: true,
                    value: '',
                    rules: {
                    }
                },
                city: {
                    valid: true,
                    value: '',
                    rules: {
                    }
                },
                country: {
                    valid: true,
                    value: '',
                    rules: {
                    }
                },
                uid: {
                    valid: true,
                    value: '',
                    rules: {
                    }
                },
                gender: {
                    valid: true,
                    value: 'male',
                    rules: {
                    }
                },
                firstname: {
                    valid: true,
                    value: '',
                    rules: {
                    }
                },
                lastname: {
                    valid: true,
                    value: '',
                    rules: {
                    }
                },
                email: {
                    valid: true,
                    value: '',
                    rules: {
                    }
                },
                password: {
                    valid: true,
                    value: '',
                    rules: {
                    }
                }
            }
        }

        this.customerUnit = new customerUnit();
        this.customerCache = new customerCache();

    }

    componentDidMount() {

        this.props.set_current_page('/customers');

        this.setState({
            customerId: this.props.match.params.id
        })

        if (this.props.match.params.id === 'new') {

            this.setState({
                loading: false
            })

        } else {
            let customer = this.customerCache.get(this.props.match.params.id)

            if (customer !== null) {

                this.setcustomer(customer)

            } else {

                this.setState({
                    loading: true
                })
                this.loadcustomers();

            }
        }



    }

    loadcustomers = () => {
        this.customerUnit.list().then(res => {

            this.customerCache.set(res.data);

            let customer = this.customerCache.get(this.props.match.params.id)
            this.setState({
                loading: false
            })
            this.setcustomer(customer)

        }).catch(err => {
            console.log(err)
        })
    }

    setcustomer = (customer) => {

        wrlog("customers", customer);
        let formCopy = this.state.form;

        formCopy.company.value = customer.company;
        formCopy.address.value = customer.address;
        formCopy.addressAdditional.value = customer.address_additional;
        formCopy.zip.value = customer.zip;
        formCopy.city.value = customer.city;
        formCopy.country.value = customer.country;
        formCopy.uid.value = customer.uid;
        formCopy.gender.value = customer.gender;
        formCopy.firstname.value = customer.firstname;
        formCopy.lastname.value = customer.lastname;
        formCopy.email.value = customer.email;
        formCopy.password.value = '';

        this.setState({
            customer: customer,
            form: formCopy
        });

    }

    changeValue = (key, value) => {

        let formCopy = this.state.form;
        if (typeof value === 'object' && value.target !== undefined) {
            value = value.target.value;
        }

        formCopy[key].value = value;
        formCopy[key].valid = validation(value, formCopy[key].rules);

        this.setState({ form: formCopy })

    }


    save = () => {

        this.setState({
            loading: true
        })

        this.saveButton.statusLoading();

        if (this.state.customerId === 'new') {

            this.customerUnit.create(
                this.state.form.company.value,
                this.state.form.address.value,
                this.state.form.addressAdditional.value,
                this.state.form.zip.value,
                this.state.form.city.value,
                this.state.form.country.value,
                this.state.form.uid.value,
                this.state.form.gender.value,
                this.state.form.firstname.value,
                this.state.form.lastname.value,
                this.state.form.email.value,
                this.state.form.password.value
            ).then(res => {



                if (res.success) {
                    this.loadcustomers();

                    const newId = res.customer_id;

                    this.setState({
                        loading: false,
                        customerId: newId
                    })

                    this.saveButton.statusSuccess();
                } else {
                    this.saveButton.statusReset();

                }


            }).catch(err => {

                this.setState({
                    loading: false
                })

            })

        } else {

            this.customerUnit.update(
                this.state.customerId,
                this.state.form.company.value,
                this.state.form.address.value,
                this.state.form.addressAdditional.value,
                this.state.form.zip.value,
                this.state.form.city.value,
                this.state.form.country.value,
                this.state.form.uid.value,
                this.state.form.gender.value,
                this.state.form.firstname.value,
                this.state.form.lastname.value,
                this.state.form.email.value,
                this.state.form.password.value
            ).then(res => {

                if (res.success) {

                    this.loadcustomers();

                    this.setState({
                        loading: false
                    })

                    this.saveButton.statusSuccess();

                } else {
                    this.saveButton.statusReset();

                }

            }).catch(err => {

                this.setState({
                    loading: false
                })

            })

        }

    }

    render() {
        return (
            <>
                <Container fluid>
                    <Row>
                        <Col md={12}>
                            <Link className="go_back_button" to="/customers">{t('back')}</Link>
                        </Col>
                    </Row>
                    <Row>
                        <Col md={12}>
                            <h1>{(this.state.form.company.value === '' && this.state.customerId === 'new' ? t('new_customer') : this.state.form.company.value) + (this.state.customerId === 'new' ? ' '+t('create') : ' '+t('edit'))}</h1>
                        </Col>
                    </Row>
                    <Row>
                        <Col md={12}>
                            <Container fluid>
                                <Row style={{ paddingTop: 50 }}>
                                    <Col md={6}>
                                        <TextInput
                                            onChangeValue={(val) => this.changeValue('company', val)}
                                            value={this.state.form.company.value}
                                            placeholder={t('company')}
                                        />
                                    </Col>
                                    <Col md={6}>
                                        <TextInput
                                            onChangeValue={(val) => this.changeValue('uid', val)}
                                            value={this.state.form.uid.value}
                                            placeholder={t('uid')}
                                        />
                                    </Col>
                                </Row>
                                <Row style={{ paddingTop: 50 }}>
                                    <Col md={6}>
                                        <TextInput
                                            onChangeValue={(val) => this.changeValue('address', val)}
                                            value={this.state.form.address.value}
                                            placeholder={t('address')}
                                        />
                                    </Col>
                                    <Col md={6}>
                                        <TextInput
                                            onChangeValue={(val) => this.changeValue('addressAdditional', val)}
                                            value={this.state.form.addressAdditional.value}
                                            placeholder={t('address_additional')}
                                        />
                                    </Col>
                                </Row>
                                <Row>
                                    <Col md={3}>
                                        <TextInput
                                            onChangeValue={(val) => this.changeValue('zip', val)}
                                            value={this.state.form.zip.value}
                                            placeholder={t('zip')}
                                        />
                                    </Col>
                                    <Col md={5}>
                                        <TextInput
                                            onChangeValue={(val) => this.changeValue('city', val)}
                                            value={this.state.form.city.value}
                                            placeholder={t('city')}
                                        />
                                    </Col>
                                    <Col md={4}>
                                        <TextInput
                                            onChangeValue={(val) => this.changeValue('country', val)}
                                            value={this.state.form.country.value}
                                            placeholder={t('country')}
                                        />
                                    </Col>
                                </Row>
                                <Row style={{ paddingTop: 50 }}>
                                    <Col>
                                        <h3>{t('contact_person')}</h3>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col md={6}>
                                        <Select
                                            onChange={(val) => this.changeValue('gender', val)}
                                            value={this.state.form.gender.value}
                                            placeholder={t('salutation')}
                                        >
                                            <MenuItem value="male">{t('male')}</MenuItem>
                                            <MenuItem value="female">{t('female')}</MenuItem>
                                        </Select>
                                    </Col>
                                    <Col md={6}>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col md={6}>

                                        <TextInput
                                            onChangeValue={(val) => this.changeValue('firstname', val)}
                                            value={this.state.form.firstname.value}
                                            placeholder={t('firstname')}
                                        />
                                    </Col>
                                    <Col md={6}>
                                        <TextInput
                                            onChangeValue={(val) => this.changeValue('lastname', val)}
                                            value={this.state.form.lastname.value}
                                            placeholder={t('lastname')}
                                        />
                                    </Col>
                                </Row>
                                <Row>
                                    <Col md={6}>
                                        <strong>{t('login_credentials_for_customer_dashboard')}</strong>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col md={6}>
                                        <TextInput
                                            onChangeValue={(val) => this.changeValue('email', val)}
                                            value={this.state.form.email.value}
                                            placeholder={t('email')}
                                        />
                                    </Col>
                                    <Col md={6}>
                                        <TextInput
                                            onChangeValue={(val) => this.changeValue('password', val)}
                                            value={this.state.form.password.value}
                                            placeholder={t('password')}
                                        />
                                    </Col>
                                </Row>
                            </Container>
                        </Col>
                        <Col md={6}></Col>
                    </Row>
                </Container>
                <Button
                    ref={ref => this.saveButton = ref}
                    onClick={this.save}
                    label={t('save')}
                    fixed
                />
                <Loading visible={this.state.loading} />
            </>
        );
    }
}
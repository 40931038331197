/*
    Established connection to API
*/

import Communicator from ".";
import { getUserToken } from "../../helper/functions/auth";

export default class PasswordUnit extends Communicator {

    /**
     * 
     * @param {String} endpoint
     */
    constructor() {

        super('');

    }

    list = async () => {

        return await this.post('list_passwords', {
            token: getUserToken()
        });

    }

    get = async (passwordId) => {

        return await this.post('password_get', {
            token: getUserToken(),
            id: passwordId
        });

    }

    create = async (customerId, projectId, title, site, password, user, comment) => {

        return await this.post('password_add', {
            token: getUserToken(),
            customer_id: customerId,
            project_id: projectId,
            title: title,
            site: site,
            password: password,
            user: user,
            comment: comment
        });

    }

    update = async (passwordId, customerId, projectId, title, site, password, user, comment) => {

        return await this.post('password_update', {
            token: getUserToken(),
            customer_id: customerId,
            project_id: projectId,
            title: title,
            site: site,
            password: password,
            user: user,
            comment: comment,
            id: passwordId
        });

    }

    createLink = async (passwordId, credentials) => {

        return await this.post('password_link_add', {
            token: getUserToken(),
            password_id: passwordId,
            credentials: credentials,
        });

    }

    getPasswordByLink = async (token) => {

        return await this.post('password_link_get', {
            token: token,
        });

    }

}
import i18n from "i18n-js";
import detectBrowserLanguage from 'detect-browser-language'
import { wrlog } from "../misc";





export const translationGetters = {
    de: () => require("./translations/de.json"),
    en: () => require("./translations/en.json"),
}

export const t = (k, a = {}) => {
    return i18n.t(k, a);
}

export const getLan = () => {
    return (i18n.locale);
}

export const setTranslationConfig = (languageTag) => {

    const fbLanguage = 'en';

    if (translationGetters[languageTag] !== undefined) {
        i18n.locale = languageTag;
        i18n.translations = { [languageTag]: translationGetters[languageTag]() };
    } else {
        i18n.locale = fbLanguage;
        i18n.translations = { [fbLanguage]: translationGetters[fbLanguage]() };
    }

}

export const setBrowserLanguage = () => {

    let lan = detectBrowserLanguage().split('-')[0];

    wrlog('SPREACHE: ', lan)

    setTranslationConfig(lan);

    return lan

}
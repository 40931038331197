/*
    Established connection to API
*/

import Communicator from ".";
import { getUserToken } from "../../helper/functions/auth";

export default class ReportUnit extends Communicator {

    /**
     * 
     * @param {String} endpoint
     */
    constructor() {

        super('');

    }

    list = async (filter) => {

        return await this.post('list_report', {
            token: getUserToken(),
            filter: filter
        });

    }

    getExportCustomer = async (body) => {

        return await this.post('report_export', {
            token: getUserToken(),
            ...body,
            type : 'customer'
        }); 

    }

    getExportMonthReport = async (body) => {

        return await this.post('report_export', {
            token: getUserToken(),
            ...body,
            type : 'month_report'
        });

    }

    getExportInternalTimesheet = async (from, to, projectId, userId, type, filter) => {

        return await this.post('report_export', {
            token: getUserToken(),
            datetime_from: from,
            datetime_to: to,
            project_id: projectId,
            user_id: userId,
            type: type,
            filter: filter
        });

    }

}
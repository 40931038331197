/*
    Cache
*/

export default class Cache {

    /**
     * 
     * @param {String} endpoint
     */
    constructor(cacheId, idKey) {

        this.cacheId = cacheId;
        this.idKey = idKey;
        this.data = null;
    }


    /**
     * Fires & Handles GET Request
     * 
     * @param {String} request 
     * 
     * @returns {*}
     */
    get = (id) => {

        this.list();

        id = parseInt(id);

        let output = null;

        this.data.forEach(element => {

            if (parseInt(element.id) === parseInt(id)) output = element;

        })

        return output;

    }

    list = () => {

        let data = sessionStorage.getItem(this.cacheId);

        if (data === null) {
            this.data = [];
            return [];
        }

        this.data = JSON.parse(data);

        return this.data;

    }

    /**
     * Set data to session storage
     * 
     * @param {Object} data 
     * 
     */
    set = (data) => {

        sessionStorage.setItem(this.cacheId, JSON.stringify(data));

    }

    /**
     * Updates single element in session storage
     * 
     * @param {Object} data
     * 
     */
    update = async (id, data) => {

        this.list();

        let key = null;

        this.data.forEach((element, key) => {

            if (element.id === id) key = key;

        })

        if (data === null) return;

        this.data[key] = data;

        this.set(this.data);

    }

}
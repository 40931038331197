import { Table, TableBody, TableCell, TableHead, TableRow, TextField } from '@material-ui/core';
import React from 'react';
import { Container, Col, Row } from 'react-bootstrap';
import Loading from '../../helper/screens/Loading';
import Notification from '../../helper/screens/Notification';
import { validation } from '../../helper/validation';
import { Link } from 'react-router-dom';

import timetrackingTypes, { getType } from '../../helper/data/timetrackingTypes'
import TextInput from '../../helper/screens/TextInput';
import ProjectUnit from '../../communication/http/project';
import ProjectCache from '../../communication/locally/project';
import UserCache from '../../communication/locally/user';

import { formatTimeslotTimeRange, getProjectData, wrlog, getCurrentTime, formatDateForDbSimple, formatTimeToUnix, calculateHoursMinutes, reverseSearchProject, formatDatetimeForDbSimple } from '../../helper/misc';
import Button from '../../helper/screens/Button';
import ReportUnit from '../../communication/http/report';
import PredictionInput from '../../helper/screens/PredictionInput';
import UserUnit from '../../communication/http/user';

import customerIcon from '../../assets/icons/suitcase.svg';
import timesheetIcon from '../../assets/icons/stopwatch.svg';
import { getLan, t } from '../../helper/multilingual/translate';
import PdfPopup from '../../helper/screens/PdfPopup';


export default class Reports extends React.Component {

    constructor() {

        super();

        this.state = {
            show_errors: false,
            projects: [],
            timeslots: [],
            customers: [],
            users: [],
            selectedProject: null,
            selectedTask: null,
            selectedSubtask: null,
            pdfBase64: null,
            form: {
                exportType: {
                    valid: false,
                    value: '',
                    rules: {
                        required: true
                    }
                },
                userId: {
                    valid: false,
                    value: -1,
                    rules: {
                        required: true
                    }
                },
                from: {
                    valid: false,
                    value: '',
                    rules: {
                        required: true
                    }
                },
                to: {
                    valid: false,
                    value: '',
                    rules: {
                        required: true
                    }
                },
                type: {
                    valid: true,
                    value: 'work',
                    rules: {
                        required: true
                    }
                },
                projectTask: {
                    valid: true,
                    value: null,
                    rules: {
                    }
                }
            }
        }

        this.projectUnit = new ProjectUnit();
        this.projectCache = new ProjectCache();

        this.reportUnit = new ReportUnit();

        this.userUnit = new UserUnit();
        this.userCache = new UserCache();

        this.notification = React.createRef()
    }

    componentDidMount() {
        this.props.set_current_page('/reports');

        if (this.projectCache.list() !== null) {
            this.setState({
                projects: this.projectCache.list()
            })
        }

        this.projectUnit.list().then(res => {
            console.log(res)

            this.projectCache.set(res.data);

            this.setState({
                projects: res.data
            })

        }).catch(err => {
            console.log(err)
        })

        // Get user data
        this.userUnit.list().then(res => {
            console.log(res)

            this.userCache.set(res.data);

            let users = [{
                label: t('all'),
                id: -1
            }];


            res.data.forEach(user => {

                users.push({
                    label: user.firstname + ' ' + user.lastname,
                    id: user.id
                })

            })

            this.setState({
                users: users
            })

        }).catch(err => {
            console.log(err)
        })

    }



    get = () => {

        let valid = true;

        this.saveButton.statusLoading();

        let filter = [];

        let from = null;
        if (this.state.form.from.value !== '') {

            from = formatDatetimeForDbSimple(this.state.form.from.value);

            filter.push('datetime_from >= ' + '"' + from + '"');

        }

        let to = null;
        if (this.state.form.to.value !== '') {

            to = formatDatetimeForDbSimple(this.state.form.to.value);
            filter.push('datetime_to <= ' + '"' + to + '"');

        }

        if (this.state.form.from.value !== '' && this.state.form.to.value !== '') {

            if (formatTimeToUnix(to) <= formatTimeToUnix(from)) {

                this.notification.current.open('error', 'Anfang muss vor Ende liegen.');
                valid = false;

            }

        }

        let taskId = null;

        if (this.state.selectedProject !== null) {
            filter.push('project_id = ' + this.state.projects[this.state.selectedProject].id);
        }

        if (this.state.selectedTask !== null) {
            taskId = this.state.projects[this.state.selectedProject].tasks[this.state.selectedTask].task_id
        }

        if (this.state.selectedSubtask !== null) {
            taskId = this.state.projects[this.state.selectedProject].tasks[this.state.selectedTask].tasks[this.state.selectedSubtask].task_id
        }

        if (this.state.form.userId.value >= 0) {

            filter.push('user_id = ' + this.state.form.userId.value);

        }

        if (taskId != null) {

            let taskIds = this.getTaskIds(taskId);

            taskIds.forEach((taskId, index) => {
                taskIds[index] = '"' + taskId + '"';
            })

            filter.push('task_id IN (' + taskIds.join(',') + ')');


        }

        if (!valid) {

            this.saveButton.statusReset();

            return;

        }

        this.reportUnit.list(filter).then(res => {

            res.data.sort((a, b) => {
                return formatTimeToUnix(a.datetime_from) - formatTimeToUnix(b.datetime_from);
            })

            this.saveButton.statusSuccess();

            this.setState({
                timeslots: res.data
            });

        }).catch(err => {
            console.log(err)
        })

    }

    getTaskIds = (id) => {

        let output = [];

        this.state.projects.forEach((project) => {

            project.tasks.forEach(task => {

                if (parseInt(task.task_id) === parseInt(id)) {

                    output.push(task.task_id);

                    task.tasks.forEach(subTask => {
                        output.push(subTask.task_id);
                    })

                }

                task.tasks.forEach(subTask => {

                    if (parseInt(subTask.task_id) === parseInt(id)) {

                        output.push(subTask.task_id);

                    }

                })

            })

        });

        return output;
    }


    loadPdfCustomer = () => {

        let formCopy = this.state.form;
        let body = {};
        let filter = [];

        if (formCopy.from.value !== '') {
            let from = formatDatetimeForDbSimple(formCopy.from.value);
            body.datetime_from = formCopy.from.value;
            filter.push('datetime_from >= ' + '"' + from + '"');
        }
        if (formCopy.to.value !== '') {
            let to = formatDatetimeForDbSimple(formCopy.to.value);
            body.datetime_to = to;
            filter.push('datetime_to <= ' + '"' + to + '"');
        }
        if (this.state.selectedProject !== null) {
            filter.push('project_id = ' + this.state.projects[this.state.selectedProject].id);
        } else {
            this.notification.current.open('error', t('please_choose_a_project'));
            return;
        }

        if (filter.length > 0) {
            body.filter = filter
        }

        this.setState({
            loading: true
        })

        this.reportUnit.getExportCustomer(body).then(res => {
            // let url = res.data.url;

            this.setState({
                loading: false,
                pdfBase64: res.data
            })

            this.pdfPopup.open();

            wrlog(res.data)

        })

    }

    loadPdfMonthlyReport = () => {
        let formCopy = this.state.form;
        let body = {};
        let filter = [];

        if (formCopy.from.value !== '') {
            let from = formatDatetimeForDbSimple(formCopy.from.value);
            body.from_date = from;
            filter.push('datetime_from >= ' + '"' + from + '"');
        } else {
            this.notification.current.open('error', t('please_choose_a_month'));
            return;
        }
        if (formCopy.userId.value >= 0) {
            body.user_id = formCopy.userId.value;
            filter.push('user_id = ' + formCopy.userId.value);
        } else {
            this.notification.current.open('error', t('please_choose_a_teammember'));
            return;
        }

        if (filter.length > 0) {
            body.filter = filter
        }

        this.setState({
            loading: true
        })

        this.reportUnit.getExportMonthReport(body).then(res => {
            // let url = res.data.url;

            this.setState({
                loading: false,
                pdfBase64: res.data
            })

            this.pdfPopup.open();

            wrlog(res.data)
            // window.open(url, "_blank")

        })

    }

    changeValue = (key, value) => {

        let formCopy = this.state.form;
        if (typeof value === 'object' && value.target !== undefined) {
            value = value.target.value;
        }

        formCopy[key].value = value;
        formCopy[key].valid = validation(value, formCopy[key].rules);

        this.setState({ form: formCopy });

    }

    renderTasks = () => {

        return <>
            <div className="timetracking_task_wrapper">
                {
                    this.state.projects !== undefined ? this.state.projects.map((project, projectKey) => {

                        return <div
                            className={"timetracking_task_container" + (this.state.selectedProject === projectKey ? ' selected' : '')}
                            onClick={() => {

                                this.setState({
                                    selectedProject: projectKey,
                                    selectedTask: null,
                                    selectedSubtask: null,
                                })
                            }}
                        >{project.title}</div>

                    })
                        : null

                }
            </div>
            <div className="timetracking_task_wrapper">
                {
                    this.state.selectedProject !== null ? this.state.projects[this.state.selectedProject].tasks.map((task, taskKey) => {

                        return <div
                            className={"timetracking_task_container" + (this.state.selectedTask === taskKey ? ' selected' : '')}
                            onClick={() => {

                                this.setState({
                                    selectedTask: taskKey,
                                    selectedSubtask: null,
                                })

                            }}
                        >{task.title}</div>

                    }) : null
                }
            </div>
            <div className="timetracking_task_wrapper">
                {
                    this.state.selectedProject !== null && this.state.selectedTask !== null ? this.state.projects[this.state.selectedProject].tasks[this.state.selectedTask].tasks.map((subTask, subTaskkey) => {

                        return <div
                            className={"timetracking_task_container" + (this.state.selectedSubtask === subTaskkey ? ' selected' : '')}
                            onClick={() => {

                                this.setState({
                                    selectedSubtask: subTaskkey,
                                })

                            }}
                        >{subTask.title}</div>

                    }) : null
                }
            </div>

        </>

    }

    renderTimeslots = () => {

        let workedSum = 0;

        return <Table>
            <TableHead>
                <TableRow>
                    <TableCell>
                        {t('type')}
                    </TableCell>
                    <TableCell>
                        {t('project_task')}
                    </TableCell>

                    <TableCell style={{ width: 100 }}>
                        {t('duration')}
                    </TableCell>
                    <TableCell style={{ width: 400 }}>
                        {t('time')}
                    </TableCell>
                    <TableCell style={{ width: 100 }}>

                    </TableCell>
                </TableRow>
            </TableHead>
            <TableBody>
                {
                    this.state.timeslots.map((timeslot, key) => {

                        let time = formatTimeToUnix(timeslot.datetime_to) - formatTimeToUnix(timeslot.datetime_from);
                        if (getType(timeslot.type).isWorktime) {
                            workedSum = workedSum + time;
                        }
                        let timeFormatted = calculateHoursMinutes(time);

                        return <TableRow key={key}>
                            <TableCell>
                                {t(timeslot.type)}
                            </TableCell>
                            <TableCell>
                                {timeslot.task_id > 0 ? reverseSearchProject(this.state.projects, timeslot.task_id).join(' > ') : getProjectData(this.state.projects, timeslot.project_id).title}
                            </TableCell>

                            <TableCell style={{ width: 100 }}>
                                {timeFormatted.hours}h {timeFormatted.minutes}min
                            </TableCell>
                            <TableCell style={{ width: 400 }}>
                                {formatTimeslotTimeRange(timeslot)}
                            </TableCell>
                            <TableCell style={{ width: 100 }}>
                                <Link className="link" to={'/timetracking/' + timeslot.id}>
                                    {t('edit')}
                                </Link>
                            </TableCell>
                        </TableRow>
                    })
                }

                <TableRow>
                    <TableCell colSpan={5}>
                        <strong>{t('day_workingtime')} {calculateHoursMinutes(workedSum).hours}h {calculateHoursMinutes(workedSum).minutes}min</strong>

                    </TableCell>
                </TableRow>


            </TableBody>
        </Table>
    }


    render() {
        return (
            <>
                <Container fluid>
                    <Row>
                        <Col md={5}>
                            <PredictionInput
                                options={this.state.users}
                                onChangeValue={(val) => this.changeValue('userId', val)}
                                value={this.state.form.userId.value}
                                placeholder={t('teammember')}
                            />
                        </Col>
                        <Col md={7}>
                            <Container fluid>
                                <Row>
                                    <Col md={6}>
                                        <TextInput
                                            onChangeValue={(val) => this.changeValue('from', val)}
                                            value={this.state.form.from.value}
                                            placeholder={t('from')}
                                            type="datetime"
                                        />
                                    </Col>
                                    <Col md={6}>
                                        <TextInput
                                            onChangeValue={(val) => this.changeValue('to', val)}
                                            value={this.state.form.to.value}
                                            placeholder={t('to')}
                                            type="datetime"
                                        />
                                    </Col>
                                </Row>
                            </Container>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <strong>{t('export_as_pdf')}</strong>
                            <div style={{ display: 'flex' }}>
                                {
                                    <>
                                        <div
                                            onClick={() => this.loadPdfCustomer()}
                                            className={"big_radio"}
                                            style={{ backgroundImage: `url(${customerIcon})`, maxWidth: 50 }}
                                        >
                                            <strong>{t('for_customers')}</strong>
                                        </div>

                                        <div
                                            onClick={() => this.loadPdfMonthlyReport()}
                                            className={"big_radio"}
                                            style={{ backgroundImage: `url(${timesheetIcon})`, maxWidth: 50 }}
                                        >
                                            <strong>{t('timesheet')}</strong>
                                        </div>
                                    </>
                                }
                            </div>
                        </Col>
                    </Row>
                    <Row style={{ marginTop: 50 }}>
                        <Col>
                            <div>{t('project_task')}</div>
                            {this.renderTasks()}
                        </Col>
                    </Row>
                    <Row style={{ marginTop: 100 }}>
                        <Col>
                            {this.renderTimeslots()}
                        </Col>
                    </Row>
                </Container>
                <Button
                    label={"Laden"}
                    ref={ref => this.saveButton = ref}
                    onClick={this.get}
                    fixed
                />
                <PdfPopup
                    ref={ref => this.pdfPopup = ref}
                    style={{
                        maxWidth: 900,
                        position: 'absolute',
                        top: 50
                    }}
                    pdf={this.state.pdfBase64}
                    filename={'spectory_rechnung_'}
                >

                </PdfPopup>
                <Loading visible={this.state.loading} />
                <Notification ref={this.notification} text="Invalid email or password" type="error" />
            </>
        );
    }
}
/*
    Established connection to API
*/
import Cache from ".";

export default class ProjectCache extends Cache {

    /**
     * 
     * @param {String} endpoint
     */
    constructor() {

        super('projects', 'id');

    }

}
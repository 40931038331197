import { Table, TableBody, TableCell, TableRow, TextField } from '@material-ui/core';
import React from 'react';
import { Container, Col, Row } from 'react-bootstrap';
import Loading from '../../helper/screens/Loading';
import Notification from '../../helper/screens/Notification';
import { validation } from '../../helper/validation';

import TextInput from '../../helper/screens/TextInput';

import { Link } from 'react-router-dom';
import UserUnit from '../../communication/http/user';
import VacationUnit from '../../communication/http/vacation';
import VacationCache from '../../communication/locally/vacation';
import Switch from '../../helper/screens/Switch';
import { t } from '../../helper/multilingual/translate';
import Sidebar from '../../helper/screens/Sidebar';
import Button from '../../helper/screens/Button';
import { hasPermission } from '../../helper/functions/auth';
import { clone, formatDatetime, formatTimeFromDb, formatTimeToUnix, getCurrentUnix, wrlog } from '../../helper/misc';
import ProjectUnit from '../../communication/http/project';
import ProjectCache from '../../communication/locally/project';
import WebsiteUnit from '../../communication/http/website';


export default class Websites extends React.Component {

    constructor() {

        super();

        this.state = {
            show_errors: false,
            websites: [],
            websitesCopy: {},
            userData: {},
            selectedVacation: null,
            loading: true,
            currentState: 'future',
        }

        this.websiteUnit = new WebsiteUnit();
        this.projectUnit = new ProjectUnit();
        this.projectCache = new ProjectCache();

    }

    componentDidMount() {

        if (this.props.location.state !== undefined && this.props.location.state.currentState !== undefined) {

            this.setState({
                currentState: this.props.location.state.currentState
            })

        }

        this.props.set_current_page('/websites');

        this.loadWebsites();

    }

    loadWebsites = () => {
        this.websiteUnit.list().then(res => {

            let websites = res.data

            wrlog("websites", websites)

            this.setState({
                websites: websites,
                loading: false
            })

            if (this.saveButton !== undefined) {
                this.saveButton.statusSuccess();
            }

        }).catch(err => {
            console.log(err)
        });


        // this.userUnit.getTime()
        //     .then(res => {

        //         this.setState({
        //             userData: res.data
        //         })

        //     })

    }

    render() {
        return (
            <>
                <Container fluid>
                    <Row>
                        <Col md={12}>
                            <h1>{t('websites')}</h1>
                        </Col>
                    </Row>
                    <Row>
                        <Col md={12} style={{ paddingBottom: 50 }}>
                            {/* <Switch
                                selected={this.state.currentState === 'all'}
                                title={t('all')}
                                onChange={() => this.setState({ currentState: 'all' })}
                            />
                            <Switch
                                selected={this.state.currentState === 'future'}
                                title={t('future')}
                                onChange={() => this.setState({ currentState: 'future' })}
                            />
                            <Switch
                                selected={this.state.currentState === 'history'}
                                title={t('history')}
                                onChange={() => this.setState({ currentState: 'history' })}
                            /> */}

                        </Col>
                    </Row>
                    <Row>
                        <Col md={12}>
                            <Table>
                                <TableBody>
                                    {
                                        this.state.websites.map((website, key) => {

                                            return <TableRow key={key}>
                                                <TableCell>{website.title}</TableCell>
                                                {/* <TableCell>{t(vacation.state)}</TableCell> */}
                                                <TableCell style={{ width: 100 }}>
                                                    <Link
                                                        className="link"
                                                        to={{
                                                            pathname: '/websites/' + website.id,
                                                            state: {
                                                                currentState: this.state.currentState
                                                            }
                                                        }}
                                                    >
                                                        {t('edit')}
                                                    </Link>
                                                </TableCell>
                                            </TableRow>
                                        })
                                    }
                                </TableBody>
                            </Table>
                        </Col>
                    </Row>
                </Container>

                <Link
                    className="button"
                    style={{ position: 'fixed', bottom: 20, right: 20 }}
                    to={'/websites/new'}
                >
                    {t('new')}
                </Link>

                <Loading visible={this.state.loading} />
            </>
        );
    }
}
import { Table, TableBody, TableCell, TableRow, TextField } from '@material-ui/core';
import React from 'react';
import { Container, Col, Row } from 'react-bootstrap';
import Loading from '../../../helper/screens/Loading';
import Notification from '../../../helper/screens/Notification';
import { validation } from '../../../helper/validation';

import TextInput from '../../../helper/screens/TextInput';

import { Link } from 'react-router-dom';
import UserCache from '../../../communication/locally/user';
import UserUnit from '../../../communication/http/user';
import VacationUnit from '../../../communication/http/vacation';
import VacationCache from '../../../communication/locally/vacation';
import Switch from '../../../helper/screens/Switch';
import { t } from '../../../helper/multilingual/translate';
import Sidebar from '../../../helper/screens/Sidebar';
import { formatTimeFromDb, formatTimeToUnix } from '../../../helper/misc';


export default class VacationsAdmin extends React.Component {

    constructor() {

        super();

        this.state = {
            show_errors: false,
            vacations: [],
            loading: true,
            currentState: 'open'
        }

        this.userCache = new UserCache();
        this.userUnit = new UserUnit();
        this.vacationUnit = new VacationUnit();
        this.vacationCache = new VacationCache();
    }

    componentDidMount() {

        if (this.props.location.state !== undefined && this.props.location.state.currentState !== undefined) {

            this.setState({
                currentState: this.props.location.state.currentState
            })

        }

        this.userUnit.list().then(res => {
            console.log(res)

            this.userCache.set(res.data);

        }).catch(err => {
            console.log(err)
        })


        this.props.set_current_page('/vacations');

        this.loadVacations();

    }

    loadVacations = () => {
        this.vacationUnit.list().then(res => {

            let vacations = res.data

            vacations.forEach((vacation, key) => {
                vacations[key].from = new Date(formatTimeToUnix(vacation.from));
                vacations[key].to = new Date(formatTimeToUnix(vacation.to));
            });

            this.vacationCache.set(vacations);

            this.setState({
                vacations: vacations,
                loading: false
            })

        }).catch(err => {
            console.log(err)
        })
    }


    accept = (vacationId) => {

        this.vacationUnit.accept(vacationId).then(res => {

            this.loadVacations();

            this.setState({
                currentState: 'accepted'
            })

        });
    }
    reject = (vacationId) => {

        this.vacationUnit.reject(vacationId).then(res => {

            this.loadVacations()

            this.setState({
                currentState: 'rejected'
            })

        });
    }
    delete = (vacationId) => {

        this.vacationUnit.delete(vacationId).then(res => {

            this.loadVacations()

        });
    }



    changeValue = (key, value) => {

        let formCopy = this.state.form;
        if (typeof value === 'object' && value.target !== undefined) {
            value = value.target.value;
        }

        formCopy[key].value = value;
        formCopy[key].valid = validation(value, formCopy[key].rules);

        this.setState({ form: formCopy })

    }


    render() {
        return (
            <>
                <Container fluid>
                    <Row>
                        <Col md={12}>
                            <Link className="go_back_button"
                                to={{
                                    pathname: '/vacations',
                                }}
                            >{t('back')}</Link>
                        </Col>
                    </Row>

                    <Row>
                        <Col md={12}>
                            <h1>{t('vacations')} {t('admin')}</h1>
                        </Col>
                    </Row>
                    <Row>
                        <Col md={12} style={{ paddingBottom: 50 }}>
                            <Switch
                                selected={this.state.currentState === 'all'}
                                title={t('all')}
                                onChange={() => this.setState({ currentState: 'all' })}
                            />
                            <Switch
                                selected={this.state.currentState === 'open'}
                                title={t('open')}
                                onChange={() => this.setState({ currentState: 'open' })}
                            />
                            <Switch
                                selected={this.state.currentState === 'accepted'}
                                title={t('accepted')}
                                onChange={() => this.setState({ currentState: 'accepted' })}
                            />
                            <Switch
                                selected={this.state.currentState === 'rejected'}
                                title={t('rejected')}
                                onChange={() => this.setState({ currentState: 'rejected' })}
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col md={12}>
                            <Table>
                                <TableBody>
                                    {
                                        this.state.vacations.map((vacation, key) => {

                                            if (this.state.currentState !== 'all' && this.state.currentState !== vacation.state) return;

                                            let user = this.userCache.get(vacation.user_id)

                                            return <TableRow key={key}>
                                                <TableCell>{user.firstname} {user.lastname}</TableCell>
                                                <TableCell>{t(vacation.state)}</TableCell>
                                                <TableCell>{formatTimeFromDb(vacation.from).date} - {formatTimeFromDb(vacation.to).date}</TableCell>

                                                {vacation.state !== "accepted" ?
                                                    <TableCell style={{ width: 100 }}>
                                                        <div
                                                            className="link"
                                                            onClick={() => {
                                                                this.accept(vacation.id)
                                                            }}
                                                        >
                                                            {t('accept')}
                                                        </div>
                                                    </TableCell>
                                                    :
                                                    null}
                                                {vacation.state !== "rejected" ?
                                                    <TableCell style={{ width: 100 }}>
                                                        <div
                                                            className="link"
                                                            onClick={() => {
                                                                this.reject(vacation.id)
                                                            }}
                                                        >
                                                            {t('reject')}
                                                        </div>
                                                    </TableCell>
                                                    :
                                                    null}

                                                <TableCell style={{ width: 100 }}>
                                                    <div
                                                        className="link"
                                                        onClick={() => {
                                                            this.delete(vacation.id)
                                                        }}
                                                    >
                                                        {t('delete')}
                                                    </div>
                                                </TableCell>

                                            </TableRow>

                                        })
                                    }
                                </TableBody>
                            </Table>
                        </Col>
                    </Row>
                </Container>

                <Loading visible={this.state.loading} />
            </>
        );
    }
}
import { TextField } from '@material-ui/core';
import React from 'react';
import Loading from '../../helper/screens/Loading';
import Notification from '../../helper/screens/Notification';
import { validation } from '../../helper/validation';
import UserUnit from '../../communication/http/user';


export default class Login extends React.Component {

    constructor() {

        super();

        this.state = {
            show_errors: false,
            form: {
                email: {
                    valid: false,
                    value: '',
                    rules: {
                        required: true
                    }
                },
                password: {
                    valid: false,
                    value: '',
                    rules: {
                        required: true
                    }
                }
            }
        }

        this.notification = React.createRef()
    }

    componentDidMount() {

    }

    do_login = (event) => {

        this.setState({
            show_errors: false
        })

        event.preventDefault();

        let isValid = true;

        let formCopy = this.state.form;

        Object.keys(formCopy).forEach(key => {

            if (formCopy[key].valid === false) {

                isValid = false;

            }

        });

        if (!isValid) {
            this.setState({
                show_errors: true
            })

            return false;

        };

        this.setState({
            loading: true
        })


        setTimeout(() => {

            this.notification.current.open();

        }, (30 * 1000))

        new UserUnit().login(formCopy.email.value.toLowerCase(), formCopy.password.value).then(res => {

            if (res.data.token === undefined || res.data.permissions === undefined) {

                this.setState({
                    loading: false,
                    show_errors: true
                });

                this.notification.current.open('error', JSON.stringify(res.data));
                return;
            }

            sessionStorage.setItem('token', res.data.token);
            sessionStorage.setItem('token_expire_time', new Date().getTime() + (13 * 60 * 1000));
            sessionStorage.setItem('permissions', JSON.stringify(res.data.permissions));

            window.location.reload();

            this.setState({
                loading: false
            });

        }).catch(err => {

            this.setState({
                loading: false,
                show_errors: true
            });

            this.notification.current.open('error', JSON.stringify(err));

        })

    }

    changeValue = (key, value) => {

        let formCopy = this.state.form;

        if (typeof value === 'object' && value.target !== undefined) {
            value = value.target.value;
        }

        formCopy[key].value = value;
        formCopy[key].valid = validation(value, formCopy[key].rules);

        this.setState({ form: formCopy })

    }


    render() {
        return (
            <>
                <div className="login_wrapper">
                    <div className="login_container">
                        <h1>Login</h1>
                        <form onSubmit={this.do_login}>
                            <TextField id="standard-basicasd" value={this.state.form.email.value} error={!this.state.form.email.valid && this.state.show_errors} label="Email" type="email" onChange={value => this.changeValue('email', value)} /><br />
                            <TextField id="standard-basic" value={this.state.form.password.value} label="Password" type="password" error={!this.state.form.password.valid && this.state.show_errors} onChange={event => this.changeValue('password', event)} /><br />
                            <input type="submit" className="button" value="Login" />
                        </form>
                        {/* <div className="">I forgot my password</div> */}
                    </div>

                </div>
                <Loading visible={this.state.loading} />
                <Notification ref={this.notification} text="Invalid email or password" type="error" />
            </>
        );
    }
}
import React from 'react'
import "react-datetime/css/react-datetime.css";
import { formatDatetime, formatTimeFromDb, getCurrentTime } from '../misc';
import moment from 'moment';
import de from 'moment/locale/de-at'
import Datetime from 'react-datetime';
import InputMask from 'react-input-mask';

export default class TextInput extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            focused: props.value !== ''
        }

        this.textarea = React.createRef();

    }


    componentDidMount() {

    }

    onFocus = () => {

        if (this.props.disabled !== undefined && this.props.disabled !== false) return;

        this.setState({
            focused: true
        });
    }
    onBlur = () => {
        if (this.props.value === '') {
            this.setState({
                focused: false
            });
        }
    }

    auto_grow = () => {
        this.textarea.style.height = "24px";
        this.textarea.style.height = (this.textarea.scrollHeight) + "px";
    }

    render() {

        if (this.props.type === 'datetime') {

            return <div className={"input_container" + (this.state.focused ? ' focused' : '')}>
                <div
                    className="datetime_picker_button"
                    onClick={
                        () => this.props.onChangeValue(new Date())
                    }
                ></div><label>
                    <div className="input_container_label">{this.props.placeholder}</div>

                    <Datetime
                        dateFormat="DD.MM.YYYY"
                        locale={moment.locale()}
                        value={this.props.value}
                        onChange={(value) => {
                            this.props.onChangeValue(value);
                        }}
                        renderInput={(props, openCalendar, closeCalendar) =>
                            <input
                                onClick={openCalendar}
                                className={"textfield date_picker"}
                                style={this.props.style || {}}
                                type="text"
                                value={this.props.value !== '' && this.props.value !== null ? formatTimeFromDb(this.props.value).date : ''}
                                onChange={(value) => {
                                    this.props.onChangeValue(value.target.value)
                                }}
                            />
                        }

                    />


                </label>
            </div>


        } else if (this.props.type === 'time') {

            return <div className={"input_container" + (this.state.focused ? ' focused' : '') + (this.props.error === true ? ' error' : '')}>
                <label>
                    <div className="input_container_label">{this.props.placeholder}</div>
                    <div
                        className="time_picker_button"
                        onClick={
                            () => {
                                this.props.onChangeValue(getCurrentTime());
                            }
                        }
                    ></div>

                    <InputMask
                        mask="99:99"
                        value={this.props.value}
                        onChange={(value) => this.props.onChangeValue(value.target.value)}
                        maskChar=""
                    >
                        {(inputProps) => <input
                            {...inputProps}
                            className={"textfield date_picker "+(this.props.error === true ? ' error' : null)}
                            style={this.props.style || {}}
                            type="text"
                            value={this.props.value}
                        />}
                    </InputMask>




                </label>
            </div>


        } else {

            return <div
                className={"input_container " + (this.state.focused ? ' focused' : '') + (this.props.error === true ? ' error' : '')}
                style={this.props.style || {}}
            >
                <label>
                    <div className={"input_container_label " + (this.props.error === true ? ' error' : '')}>{this.props.placeholder}</div>
                    {
                        this.props.multiline !== undefined ?
                            <textarea
                                className={"textfield "+(this.props.error === true ? ' error' : '')}
                                type="text"
                                onFocus={this.onFocus}
                                onBlur={this.onBlur}
                                value={this.props.value}

                                ref={ref => this.textarea = ref}
                                onChange={(value) => {
                                    this.auto_grow()
                                    this.props.onChangeValue(value.target.value)
                                }}
                            ></textarea>
                            :
                            <input
                                className={"textfield "+(this.props.error === true ? ' error' : null)}
                                type="text"
                                disabled={this.props.disabled !== undefined && this.props.disabled !== false}
                                onFocus={this.onFocus}
                                onBlur={this.onBlur}
                                value={this.props.value}
                                onChange={(value) => {
                                    this.props.onChangeValue(value.target.value)
                                }}
                            />
                    }
                </label>
            </div>

        }
    }

}
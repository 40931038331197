import { Table, TableBody, TableCell, TableHead, TableRow, TextField } from '@material-ui/core';
import React from 'react';
import { Container, Col, Row } from 'react-bootstrap';
import Loading from '../../helper/screens/Loading';
import Notification from '../../helper/screens/Notification';
import { validation } from '../../helper/validation';

import TextInput from '../../helper/screens/TextInput';

import ProjectUnit from '../../communication/http/project'
import { Link } from 'react-router-dom';
import ProjectCache from '../../communication/locally/project';
import Switch from '../../helper/screens/Switch';
import CustomerUnit from '../../communication/http/customer';
import CustomerCache from '../../communication/locally/customer';
import InvoiceCache from '../../communication/locally/invoice';
import InvoiceUnit from '../../communication/http/invoice';
import { t } from '../../helper/multilingual/translate';


export default class Invoices extends React.Component {

    constructor() {

        super();

        this.state = {
            show_errors: false,
            projects: [],
            customers: [],
            invoices: [],
            currentState: 'open',
            loading: true,
            form: {
                comment: {
                    valid: true,
                    value: '',
                    rules: {
                    }
                },
                project_task: {
                    valid: true,
                    value: '',
                    rules: {
                    }
                },
                search: {
                    valid: true,
                    value: '',
                    rules: {
                    }
                },
            }
        }

        this.projectUnit = new ProjectUnit();
        this.projectCache = new ProjectCache();

        this.customerUnit = new CustomerUnit();
        this.customerCache = new CustomerCache();

        this.invoiceUnit = new InvoiceUnit();
        this.invoiceCache = new InvoiceCache();
    }

    componentDidMount() {

        this.props.set_current_page('/invoices');

        this.projectUnit.list().then(res => {

            this.projectCache.set(res.data);

            this.setState({
                projects: res.data,
                loading: false,
            })

        }).catch(err => {
            console.log(err)
        })

        let cached = this.customerCache.list();

        if (cached === null) {

            this.setState({
                customers: cached,
                loading: false
            })

        }


        this.customerUnit.list().then(res => {

            this.customerCache.set(res.data);

            this.setState({
                customers: res.data,
                loading: false
            })

        }).catch(err => {
            console.log(err)
        })

        let cachedInvoices = this.invoiceCache.list();

        if (cachedInvoices === null) {

            this.setState({
                customers: cached,
                loading: false
            })

        }


        this.invoiceUnit.list().then(res => {

            this.invoiceCache.set(res.data);

            this.setState({
                invoices: res.data,
                loading: false
            })

        }).catch(err => {
            console.log(err)
        })

    }

    changeValue = (key, value) => {

        let formCopy = this.state.form;
        if (typeof value === 'object' && value.target !== undefined) {
            value = value.target.value;
        }

        formCopy[key].value = value;
        formCopy[key].valid = validation(value, formCopy[key].rules);

        this.setState({ form: formCopy })

    }


    render() {
        return (
            <>
                <Container fluid>
                    <Row>
                        <Col md={12}>
                            <h1>{t('invoices')}</h1>
                        </Col>
                    </Row>
                    <Row>
                        <Col md={9} style={{ paddingBottom: 50 }}>
                            <Switch
                                selected={this.state.currentState === 'all'}
                                title={t('all')}
                                onChange={() => this.setState({ currentState: 'all' })}
                            />
                            <Switch
                                selected={this.state.currentState === 'open'}
                                title={t('drafts')}
                                onChange={() => this.setState({ currentState: 'open' })}
                            />
                            <Switch
                                selected={this.state.currentState === 'sent'}
                                title={t('sents')}
                                onChange={() => this.setState({ currentState: 'sent' })}
                            />
                            <Switch
                                selected={this.state.currentState === 'paid'}
                                title={t('paids')}
                                onChange={() => this.setState({ currentState: 'paid' })}
                            />
                            <Switch
                                selected={this.state.currentState === 'archived'}
                                title={t('archiveds')}
                                onChange={() => this.setState({ currentState: 'archived' })}
                            />
                        </Col>
                        <Col md={3}>
                            <TextInput
                                onChangeValue={(val) => this.changeValue('search', val)}
                                value={this.state.form.search.value}
                                placeholder={t('search')}
                            />

                        </Col>
                    </Row>
                    <Row>
                        <Col md={12}>
                            <Table>
                                <TableHead>
                                    <TableRow>
                                        <TableCell></TableCell>
                                        <TableCell>{t('customer')}</TableCell>
                                        <TableCell>{t('title')}</TableCell>
                                        <TableCell>{t('net')}</TableCell>
                                        <TableCell>{t('date')}</TableCell>
                                        <TableCell style={{ width: 100 }}>
                                        </TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>



                                    {
                                        this.state.invoices.map((invoice, key) => {

                                            if (this.state.currentState !== 'all' && this.state.currentState !== invoice.state) return;

                                            if (this.state.form.search.value !== '' && !invoice.title.toLowerCase().includes(this.state.form.search.value.toLowerCase())) return;

                                            return <TableRow key={key}>
                                                <TableCell>#{invoice.invoice_number}</TableCell>
                                                <TableCell>{this.customerCache.get(invoice.customer_id).company}</TableCell>
                                                <TableCell>{invoice.title}</TableCell>
                                                <TableCell>EUR {invoice.sum_net}</TableCell>
                                                <TableCell>{invoice.date}</TableCell>
                                                <TableCell style={{ width: 100 }}>
                                                    <Link className="link" to={'/invoices/' + invoice.id}>
                                                        {t('edit')}
                                                    </Link>
                                                </TableCell>
                                            </TableRow>

                                        })
                                    }
                                </TableBody>
                            </Table>
                        </Col>
                    </Row>
                </Container>
                <Link
                    className="button"
                    style={{ position: 'fixed', bottom: 20, right: 20 }}
                    to={'/invoices/new'}
                >
                    {t('new')}
                </Link>
                <Loading visible={this.state.loading} />
            </>
        );
    }
}
import { Table, TableBody, TableCell, TableRow, TextField } from '@material-ui/core';
import React from 'react';
import { Container, Col, Row } from 'react-bootstrap';
import Loading from '../../helper/screens/Loading';
import Notification from '../../helper/screens/Notification';
import { validation } from '../../helper/validation';

import TextInput from '../../helper/screens/TextInput';

import ProjectUnit from '../../communication/http/project'
import { Link } from 'react-router-dom';
import ProjectCache from '../../communication/locally/project';
import Switch from '../../helper/screens/Switch';
import { t } from '../../helper/multilingual/translate';


export default class Projects extends React.Component {

    constructor() {

        super();

        this.state = {
            show_errors: false,
            projects: [],
            currentState: 'active',
            loading: true,
            form: {
                comment: {
                    valid: true,
                    value: '',
                    rules: {
                    }
                },
                project_task: {
                    valid: true,
                    value: '',
                    rules: {
                    }
                },
                search: {
                    valid: true,
                    value: '',
                    rules: {
                    }
                },
            }
        }

        this.projectUnit = new ProjectUnit();
        this.projectCache = new ProjectCache();
    }

    componentDidMount() {

        this.props.set_current_page('/projects');

        this.projectUnit.list().then(res => {

            this.projectCache.set(res.data);

            this.setState({
                projects: res.data,
                loading: false,
            })

        }).catch(err => {
            console.log(err)
        })

    }

    changeValue = (key, value) => {

        let formCopy = this.state.form;
        if (typeof value === 'object' && value.target !== undefined) {
            value = value.target.value;
        }

        formCopy[key].value = value;
        formCopy[key].valid = validation(value, formCopy[key].rules);

        this.setState({ form: formCopy })

    }


    render() {
        return (
            <>
                <Container fluid>
                    <Row>
                        <Col md={12}>
                            <h1>Projekte</h1>
                        </Col>
                    </Row>
                    <Row>
                        <Col md={7} style={{ paddingBottom: 50 }}>
                            <Switch
                                selected={this.state.currentState === 'all'}
                                title={t('all')}
                                onChange={() => this.setState({ currentState: 'all' })}
                            />
                            <Switch
                                selected={this.state.currentState === 'active'}
                                title={t('active')}
                                onChange={() => this.setState({ currentState: 'active' })}
                            />
                            <Switch
                                selected={this.state.currentState === 'inactive'}
                                title={t('inactive')}
                                onChange={() => this.setState({ currentState: 'inactive' })}
                            />
                        </Col>
                        <Col md={5}>
                            <TextInput
                                onChangeValue={(val) => this.changeValue('search', val)}
                                value={this.state.form.search.value}
                                placeholder={t('search')}
                            />

                        </Col>
                    </Row>
                    <Row>
                        <Col md={12}>
                            <Table>
                                <TableBody>
                                    {
                                        this.state.projects.map((project, key) => {

                                            if (this.state.currentState === 'inactive' && project.state === 'active') return;
                                            if (this.state.currentState === 'active' && project.state !== 'active') return;

                                            if (this.state.form.search.value !== '' && !project.title.toLowerCase().includes(this.state.form.search.value.toLowerCase())) return;

                                            return <TableRow key={key}>
                                                <TableCell>{project.title}</TableCell>
                                                <TableCell style={{ width: 100 }}>
                                                    <Link className="link" to={'/projects/' + project.id}>
                                                        {t('edit')}
                                                    </Link>
                                                </TableCell>
                                            </TableRow>

                                        })
                                    }
                                </TableBody>
                            </Table>
                        </Col>
                    </Row>
                </Container>
                <Link
                    className="button"
                    style={{ position: 'fixed', bottom: 20, right: 20 }}
                    to={'/projects/new'}
                >
                    {t('new')}
                </Link>
                <Loading visible={this.state.loading} />
            </>
        );
    }
}